import React,{ FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Deal from "../../../../components/Menu/SideMenu/Deal";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { getDealsApi, getHistroyApi } from "../../../../services/sideMenus";
import { RootState } from "../../../../store";
import {
  getSlidesWatcher,
  setFooterType,
} from "../../../../store/application/action";
import "./history.scss";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { COUNTRY } from "../../../../constants/app";

interface IFaqProps {}

const History: FC<IFaqProps> = () => {
  const { countryId, slides, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
    slides: state.applicationReducer.slides,
  }));

  const dispatch = useDispatch();
  const [content, setContent] = useState();

  useEffect(() => {
    if (countryId && currentCinema) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.HISTORY,
        })
      );

      const getDeals = async () => {
        const response = await getHistroyApi({
         countryId,
          pageName: API_REQUEST_TYPE.HISTORY
        });
        
        setContent(response.data.content);
      };
      getDeals();
    }
  }, [countryId, currentCinema]);

  return (
    <>
    <section className="container-fluid">
      <Slider data={slides} />
      <div className="split_section_header">
        <div className="label">History</div>
      </div>
    </section>
    <section className="real_content_wrap min_screen_height">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
            <HtmlRender
          {...{ className: "description" }}
          content={content} />
          </div>
        </div>
      </div>
  </section>
  </>
  );
};

export default History;
