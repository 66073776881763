import React, { FC, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { RootState } from "../../../../store";
import { getSlidesWatcher, setDateObject } from "../../../../store/application/action";
import "./luxuryCinemasSession.scss";
import { ROUTES } from "../../../../constants/path";
import { COUNTRY, SCREEN_CATEGORY } from "../../../../constants/app";
import NowShowing from "../NowShowing";

const LINK = {
    nowShowing: `/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`,
    kidsNowShowing: `/${ROUTES.MOVIES}/${ROUTES.KIDS_NOW_SHOWING}`,
    comingSoon: `/${ROUTES.MOVIES}/${ROUTES.COMING_SOON}`,
    kidsComingSoon: `/${ROUTES.MOVIES}/${ROUTES.KIDS_COMING_SOON}`,
    kidsActivities: `/${ROUTES.MOVIES}/${ROUTES.KIDS_ACTIVITIES}`,
    specialEvents: `/${ROUTES.MOVIES}/${ROUTES.SPECIAL_EVENTS}`,
    foodAndBeverage: `/${ROUTES.FOOD_AND_BEVERAGE}`,
};
interface IMoviesProps {
    children: FC<any>;
    to: string;
}

const LuxuryCinemasSession: FC<IMoviesProps> = ({ to }) => {

    const { category, cinema } = useParams();
    const navigate = useNavigate();

    const { countryId, slides, cinemas, currentCinema } = useSelector(
        (state: RootState) => ({
            countryId: state.applicationReducer.countryId,
            slides: state.applicationReducer.slides,
            cinemas: state.applicationReducer.cinemas,
            currentCinema: state.applicationReducer.currentCinema,
        })
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (countryId && currentCinema) {
            dispatch(
                getSlidesWatcher({
                    countryId,
                    pageName: API_REQUEST_TYPE.MOVIES,
                })
            );
        }
    }, [countryId, currentCinema]);

    const backToAbout = () => {
        if(category === 'all-sessions'){
            navigate(`/cinemas`);
        }else{
            navigate(`/luxury-cinemas/${category}`);
        }
    }

    const cinemaCategory = useMemo(()=>{
        let screenTypes = 'all';
        switch (category) {
            case SCREEN_CATEGORY.GOLD_LOUNGE:
                screenTypes = 'Gold';
              break;
            case SCREEN_CATEGORY.PREMIUM:
              screenTypes = 'Premium';
              break;
            case SCREEN_CATEGORY.TITAN_LUXE:
              screenTypes = 'TitanLuxe';
              break;
            case SCREEN_CATEGORY.TITAN_XC:
              screenTypes = 'TitanXC';
              break;
            case SCREEN_CATEGORY.ALL:
              screenTypes = 'all';
              break;
          }
          return screenTypes;
    },[category])

    return (
        <div className="luxury-cinemas-session">
            <Slider data={slides} />
            <div className="header_bg stickyheadmenu">
                <div className="title">
                    <a className="back_to_all_movies" onClick={backToAbout} >{cinema} SESSIONS</a>
                </div>
            </div>
                <div>
                    <NowShowing page={'luxuryCinemas'} data={{cinema: cinema, category: cinemaCategory }}/>
                </div>
        </div >
    );
};

export default LuxuryCinemasSession;
