export const ENDPOINT = {
    SETTINGS: 'settings',
    GET_CINEMAS: 'getcinemas',
    SLIDES: 'slides',
    PROMOAD: 'getpromoad',
    FILMS: "films",
    FAQ: 'brands/faq',
    DEALS: 'deals',
    ACTIVITIES: 'funstuff',
    TICKET_TYPES: 'ticketing/tickettypes',
    ADD_TICKETS: 'ticketing/addtickets',
    CANCEL_TICKET: 'ticketing/cancelorder',
    GET_LICIENSE: 'concessions/getLicense',
    CONCESSION_ORDERS: "concessions/order",
    SIGN_UP: 'accounts/signup',
    SIGN_IN: 'accounts/login',
    MEMEBER_VALIDATE: 'accounts/membervalidation',
    MEMBER_UPDATE: 'accounts/memberupdate',
    MEMBER_UPDATE_ATTRIBUTE: 'accounts/updateattributes',
    FORGOT_PASSWORD: 'accounts/forgotpassword',
    LOGOUT: "accounts/logout",
    UPCOMING_TICKETS: "ticketing/getupcomingtickets",
    TRANSACTION_HISTORY: "accounts/gettransactionhistory",
    GENERATE_PASS: "applewallet/generate-pass",
    SAVED_CARDS: "accounts/getsavedcards",
    ACCOUNT_MEMBER_ITEMS: "accounts/memberitems",
    CHANGE_PASSWORD: 'accounts/changepassword',
    MEMBER_CARDLESS: 'accounts/getmembercardless',
    LOST_CARD: 'accounts/lostcardupdate',
    ACCOUNTS_REMOVE_SAVE_CARD: "accounts/removesavedcard",
    ACCOUNT_MAKE_DEFAULT: "accounts/makedefault",
    ACCOUNT_UPDATE_CARD: "accounts/updatecard",
    PAYMENT_CARDS_UPDATE: "payment/cards/update",
    PAYMENT_CARDS: "payment/cards/save",
    CONCESSION_ITEMS: "concessions/items",
    CONCESSION_GET_CARD_DESIGN: "concessions/getCardDesign",
    CONCESSION_GET_CARD_CATEGORIES: "concessions/getCardCategories",
    GIFT_CARD_BALANCE_CHECK: "giftcard/balancecheck",
    GET_OTP: "accounts/getOTP",
    VERIFY_OTP: "accounts/verifyOTP",
    GET_BOOKED_TICKETS: "ticketing/getBookedTickets",
    FATZEBRA_PAYMENT: "payment/fatzebra/makepayment",
    STRIPE_PAYMENT: "payment/stripe/stripe-make-payment",
    STRIPE_PAYMENT_INTENT: "payment/apple/paymentIntent",
    FATZEBRA_APPLE_SESSION: "payment/fatzebra/applePaySession",
    ORDER_CONTINUE: "ticketing/ordercontinue",
    COMPLETE_ORDER: "ticketing/completeorder",
    MAKE_REFUND: "payment/nab/makerefund",
    FATZEBRA_REFUND: "payment/fatzebra/makerefund",
    STRIPE_REFUND: "payment/stripe/makerefund",
    GIFTCARD_BALANCE_CHECK: "giftcard/balancecheck",
    VOUCHER_BALANCE_CHECK: "payment/voucher/balanceCheck",
    MAKE_GIFTCARD_PAYMENT: "giftcard/makepayment",
    MAKE_GIFTCARD_REFUND: "giftcard/makerefund",
    MAKE_VOUCHER_PAYMENT: "payment/voucher/makePayment",
    MAKE_VOUCHER_REFUND: "payment/voucher/makeRefund",
    PURCHASE_HISTORY: "accounts/gettransactionhistory",
    GIFT_CARD_ITEMS: "concessions/items",
    GIFTCARD_CATEGORIES: "concessions/getCardCategories",
    GIFTCARD_DESIGN: "concessions/getCardDesign",
    GIFTCARD_ORDER: "concessions/giftcards/order",
    GIFTCARD_ORDER_CONTINUE: "concessions/giftcards/ordercontinue",
    GIFTCARD_COMPLETE_ORDER: "concessions/giftcards/completeorder",
    MAIL_LIBRARY: "maillibrary",
    GET_ENCRYPT_KEY: "payment/processData",
    VERIFY_FATZEBRA_TOKEN: "payment/fatzebra/verifyWalletToken",
    NEWS_LETTER_SIGNUP: "accounts/newslettersignup",
    EAT_AND_DRINK: "concessions/getEatDrinkData",
    SPECIAL_VALUE: "deals/getSpecialValues",
    ORDER_HISTORY: 'ticketing/getOrderHistory',
    CHECK_REFUND_STATUS: 'payment/checkRefundStatus',
    PAYEEZY_PAYMENT: "payment/payeezy/makepayment",
    PAYEEZY_REFUND: "payment/payeezy/makerefund",
    VERIFY_PAYEEZY_TOKEN: "payment/payeezy/verifyWalletToken",
    BRANDS: 'brands',
    SUBMIT_EMPLOYMENTFORM: 'employment',
    CONTACT_US: 'contactus',
    RATING_INDEX: 'ratings',
    PAGE_CONTENT:'pagecontent',
    GROUP_BOOKING: 'groupbooking',
    CINEMA_HIRE:'cinemahire',
    VIP_CINEMAS:"vipexperience/vipcinemascreen",
    VIP_CINEMA_TYPE:"vipexperience/vipcinematype",
    RESET_CONFIRMATION: "accounts/resetpasswordconfirmation",
    RESET_PASSWORD: "accounts/resetpassword",
    USER_CONFIRMATION:"accounts/signupconfirmation",
    VIP_CINEMA_FORM:"vipexperience/vipcinemaform",
    SEARCH:"search",
    TICKET_SALES:"ticketing/getSaleData",
    GAMING_HIRE:"gaminghire/gaminghirescreen",
    GAMING_HIRE_TYPE:"gaminghire/gaminghiretype",
    GAMING_HIRE_FORM:"gaminghire/gaminghireform",
    SCREENCATEGORIES:"screencategories",
    COMPETITION:'competition',
    GET_MEMBERSHIP_PACKAGE:'accounts/getMembershipPackages',
    GET_MEMBER_ITEMS: 'accounts/memberitems',
    GET_MEMBER_ITEMS_LIST: 'accounts/memberitemsList',
    GIFT_PROMO:'concessions/getGiftCardPromoData',
    CONTACT_LIST: 'brands/contactpages',
    GIFTSHOP_SHIPPING: 'concessions/getGiftCardShippingData',
    FOOD_COLLECT: 'concessions/orderSummary',
    CONCESSION_SEND_NOTIFICATION: 'concessions/sendNotification',
    GET_SAVED_GIFTCARD: 'giftcard/getSavedGiftcard',
    SAVED_GIFTCARD: 'giftcard/savegiftcard',
    REMOVE_SAVED_GIFTCARD: 'giftcard/deleteSavedGiftcard',
    GET_LOYALTY_POINTS: 'accounts/getLoyaltyPoints',
    LOYALTY_POINTS_PAYMENTS:'payment/loyaltyPointsPayment',
    GENERATE_LOYALTY_CARDS: 'accounts/generateLoyaltyCard',
    GAME_HIRE_TYPE:'gaminghire/gaminghiretype',
    GAME_HIRE_BOOKINNG: 'gaminghire/gaminghirebookingln',
    UPDATE_RENEWAL_TYPE: 'accounts/updateRenewalType',
    GET_RENEWAL_INFO: "accounts/getRenewalInfo",
}