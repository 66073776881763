import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { RootState } from "../../../../store";
import { BRANDS } from "../../../../constants/app";

interface IPrivacyPolicyProps {}

const PrivacyPolicy: FC<IPrivacyPolicyProps> = () => {
  const { brandId } = useSelector((state:RootState)=>({
    brandId: state.applicationReducer.brandId
  }))
  const { type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setContentModal({
        type: type ? type : ROUTES.PRIVACY_POLICY,
      })
    );
    dispatch(toggleContentModal(true));
    if(brandId === BRANDS.AU){
      navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    }
  }, [type]);

  return null;
};

export default PrivacyPolicy;
