import React,{ FC, useMemo } from "react";

interface IPointScale {
  points: number;
  type: string;
}

const PointScale: FC<IPointScale> = ({ points, type }) => {
  const multiples = useMemo(() => {
    switch (type) {
      case "scale1":
        return new Array(10).fill(1).map((v, k) => (k + 1) * 50);
      case "scale2":
        return new Array(8).fill(1).map((v, k) => (k + 1) * 1);
      default:
        return [];
    }
  }, [points]);

  const Scale = useMemo(() => {
    switch (type) {
      case "scale1":
        return (
          <ul className="scale_value">
            {multiples.map((pts, index) => (
              <li
                key={index}
                className={`${
                  pts == 50 ||
                  pts == 100 ||
                  pts == 200 ||
                  pts == 300 ||
                  pts == 400 ||
                  pts == 500
                    ? "ticket"
                    : pts == 150 || pts == 450
                    ? "drink"
                    : pts == 250
                    ? "popcorn"
                    : pts == 350
                    ? "icecream"
                    : pts <= points
                    ? "active"
                    : ""
                }`}
              >
                {pts}
              </li>
            ))}
          </ul>
        );
      case "scale2":
        return (
          <ul className="scale_value">
            {multiples.map((pts, index) => (
              <>
                <li
                  key={index}
                  className={`${pts <= points ? "active state_width" : ""}`}
                >
                  {pts}
                </li>
                <div
                  className={`${
                    pts <= points
                      ? "past"
                      : pts > points && pts != 8
                      ? "future"
                      : pts == 8
                      ? "eighth"
                      : points == 8 && points == pts
                      ? "eighth_active"
                      : ""
                  }  `}
                >
                  <span>{points}</span>
                </div>
              </>
            ))}
          </ul>
        );
      default:
        return null;
    }
  }, [points, type]);

  return <>{Scale}</>;
};

export default PointScale;
