import React,{ useEffect, useState } from "react";
import AppRoutes from "./appRoutes";
import { useDispatch, useSelector } from "react-redux";
import { BRANDS, BRAND_ID, COUNTRY, DOMAINS } from "../../constants/app";
import {
  getPromoAdWatcher,
  getSettingsWatcher,
  setBrandId,
  setReinitializeScroll,
} from "../../store/application/action";
import Loader from "../../components/UI/Loader";
import { RootState } from "../../store";
import PromoAd from "../../components/PromoAd";
import Storage from "../../utils/storage";
import $ from "jquery";
import "malihu-custom-scrollbar-plugin";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
import TicketBooking from "../Common/Modals";
import "react-toastify/dist/ReactToastify.css";
import InfoPopup from "../../components/InfoPopup";
import Auth from "../Common/Modals/Auth";
import {
  memberValidationSuccess,
  memberValidationWatcher,
  setUserDetails,
} from "../../store/auth/action";
import ScrollToTop from "../../components/Routes/ScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import ContentModal from "../Common/ContentModal";
import { useNavigate } from "react-router";
import { ROUTES } from "../../constants/path";
import HelmetTitle from "../../components/Helmet";
require("jquery-mousewheel");

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { countryId, reinitializeScroll, brandId,settings,currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      reinitializeScroll: state.applicationReducer.reinitializeScroll,
      brandId: state.applicationReducer.brandId,
      settings: state.applicationReducer.settings,
      currentCinema: state.applicationReducer.currentCinema
    })
  );

  const [showPromo, setShowPromo] = useState<boolean>(false);

  const loadAppleJs = () => {
    const script = document.createElement("script");
    script.src = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js";
    document.body.appendChild(script);
  };

  useEffect(() => {
    const url = window.location.href;
    let countryId = COUNTRY.AUS;
    let favIcon = '/favicon.ico';
    if (url.includes(DOMAINS.AUS)) {
      countryId = COUNTRY.AUS;
      loadAppleJs();
    } else if (url.includes(DOMAINS.NZ)) {
      countryId = COUNTRY.NZ;
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/";
      document.body.appendChild(script);
    } else if (url.includes(DOMAINS.ANG) || url.includes(DOMAINS.AFC)) {
      countryId = COUNTRY.ANG;
      loadAppleJs();
      favIcon = '/angelika.ico';
    } else if (url.includes(DOMAINS.STA)) {
      favIcon = '/state_favicon.ico';
      countryId = COUNTRY.STA;
      loadAppleJs();
    }

    const linkElement = document.querySelector('link[rel=icon]');
    if(linkElement){
      linkElement.setAttribute('href',favIcon);
    }
    dispatch(setBrandId(BRAND_ID[countryId]));
    dispatch(getSettingsWatcher({ countryId }));
  }, []);

  useEffect(()=>{
    if(settings && currentCinema && settings.enableUndermaintenance === 1){
      navigate(`/${ROUTES.PAGE_NOT_FOUND}`)
    }
  },[settings, currentCinema])
  useEffect(() => {
    if (reinitializeScroll) {
      ($(".mCustomScrollbar") as any).mCustomScrollbar({
        theme: "dark",
        axis: "x",
        scrollInertia: 500,
        autoHideScrollbar: true,
      });
      dispatch(setReinitializeScroll(false));
    }
  }, [reinitializeScroll]);

  useEffect(() => {
    if (countryId) {
      //checking promoAdd
      const checkStatus = Storage.getItem("LOCAL", "promoad");
      if (
        (!checkStatus || checkStatus != new Date().toDateString()) &&
        brandId === BRANDS.AU
      ) {
        dispatch(getPromoAdWatcher({ countryId: countryId }));
        setShowPromo(true);
        Storage.setItem("LOCAL", "promoad", new Date().toDateString());
      }

      //Checking User info
      const userDeatils = Storage.getItem("LOCAL", "userDetails");
      if (userDeatils && Object.values(userDeatils).length > 0) {
        dispatch(setUserDetails(userDeatils));

        const memberDetail = Storage.getItem("LOCAL", "memberDetail");
        if (memberDetail && Object.values(memberDetail).length > 0) {
          dispatch(memberValidationSuccess(memberDetail));
        } else {
          const payload = {
            MemberRefId: userDeatils?.vista_memberid,
            UserSessionId: userDeatils?.result?.cardNumber,
            cardNumber: userDeatils?.result?.cardNumber,
            countryId: countryId,
          };
          dispatch(memberValidationWatcher(payload));
        }
      }
    }
  }, [countryId]);

  return (
    <>
      <HelmetTitle title="Movie Information | Tickets | Showtimes" description="Movie Information | Tickets | Showtimes"/>
      <AppRoutes />
      <ScrollToTop />
      <Loader />
      {showPromo ? <PromoAd /> : null}
      <TicketBooking />
      <InfoPopup />
      <Auth />
      <ContentModal/>
      <ToastContainer
        position={brandId === BRANDS.US?"top-center":"top-right"}
        toastClassName={brandId === BRANDS.US?"custom-toast-us":"custom-toast"}
        hideProgressBar={true}
        limit={1}
        newestOnTop={true}
        closeButton={brandId === BRANDS.US?false:true}
      />
    </>
  );
};

export default App;
