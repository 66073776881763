import { sortBy } from "lodash";
import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { BRANDS, COUNTRY } from "../../../../constants/app";
import { ROUTES } from "../../../../constants/path";
import { URLS } from "../../../../constants/url";
import { IFaqs } from "../../../../models/sidemenu";
import { getFaqApi } from "../../../../services/sideMenus";
import { RootState } from "../../../../store";
import { setContentModal, setFooterType, toggleContentModal } from "../../../../store/application/action";
import "./faq.scss";
import HelmetTitle from "../../../../components/Helmet";

interface IFaqProps {}

const Faq: FC<IFaqProps> = () => {
  const { countryId, brandId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    brandId: state.applicationReducer.brandId,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [faqs, setFaq] = useState<IFaqs[]>([]);
  const [faqDataList, setfaqDataList] = useState<IFaqs[]>([]);
  const [expand, setExpand] = useState<{
    parentId: number;
    childId: number;
  } | null>(null);
  const [searchData, setsearchData] = useState<string>();
  const [facebookUrl, setFacebookUrl] = useState<string>("");
  const [instagramUrl, setInstagramUrl] = useState<string>("");

  useEffect(() => {
    if (countryId) {
      const getFaqs = async () => {
        const response = await getFaqApi({ countryId: countryId });
        if (response && Array.isArray(response)) {
          const faq = sortBy(response, (row) => parseInt(row["sectionOrder"]));
          setFaq(faq);
          setfaqDataList(faq);
        }
      };
      getFaqs();
      dispatch(setFooterType(10));
    }
  }, [countryId]);

  useEffect(() => {
    switch (countryId) {
      case COUNTRY.AUS:
        setFacebookUrl(URLS.FACEBOOK_AU);
        setInstagramUrl(URLS.INSTAGRAM_AU);
        break;
      case COUNTRY.NZ:
        setFacebookUrl(URLS.FACEBOOK_NZ);
        setInstagramUrl(URLS.INSTAGRAM_NZ);
        break;
      case COUNTRY.ANG:
        setFacebookUrl(URLS.FACEBOOK_ANG);
        setInstagramUrl(URLS.INSTAGRAM_ANG);
        break;
      case COUNTRY.STA:
        setFacebookUrl(URLS.FACEBOOK_STA);
        setInstagramUrl(URLS.INSTAGRAM_STA);
        break;
    }
  }, [countryId]);

  const handleExpand = (parentIndex: number, childIndex: number) => {
    if (
      expand &&
      expand.parentId === parentIndex &&
      expand.childId === childIndex
    ) {
      setExpand(null);
    } else {
      setExpand({
        parentId: parentIndex,
        childId: childIndex,
      });
    }
  };

  const filterFaq = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value;
    if (value) {
      filterData(value);
      setsearchData(value);
    } else {
      setFaq(faqDataList);
    }
  };

  const getFilterFaq = () => {
    filterData(searchData);
  };

  const filterData = (value: any) => {
    value = value.toLowerCase();
    let faq = faqDataList;
    setFaq(
      faq.filter((obj: any) => {
        return (
          JSON.stringify(obj.faqs).toLowerCase().includes(value) ||
          obj.sectionName.toLowerCase().includes(value)
        );
      })
    );
  };

  const openModal = (type: string)=>{
    dispatch(setContentModal({ type }));
    dispatch(toggleContentModal(true));
  }  

  const Footer = () => {

    return (
      <div className="footer-top">
        <div className="footer-top-inner">
          <ul>
            <li>
              <a onClick={() => openModal('Gift Shop Terms')}>TERMS</a>
            </li>
            {/* <li>
              <a  href="javascript:void(0)" onClick={()=>navigate(`/${ROUTES.FAQ}`)}>FAQs</a>
            </li> */}
            <li>
              <a onClick={() => openModal(ROUTES.CONTACT_US)}>CONTACT US</a>
            </li>
            <li>
              <Link to={`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`}>
                CINEMA HOMEPAGE
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <section className="faq-sec">
      <HelmetTitle title="Faqs" description="Faqs"/>
      <div className="banner-top">
        <div className="banner-top-inner banner-content">
          <h3>How can we help you?</h3>
          <p className="mb-40">Search our FAQs or browse by category below.</p>
          <div className="search_faq">
            <input
              type="text"
              className="form-control"
              placeholder="I need help with..."
              onKeyUp={($event) => filterFaq($event)}
            />
            <button type="button" onClick={getFilterFaq}>
              <img alt="arrow" className="search-icon" src={URLS.ICON_GO} />
            </button>
          </div>
        </div>
      </div>
      <div className="faq-answer">
        <div className="faq-answer-inner">
          {faqs && faqs.length > 0 ? (
            faqs.map((data: any, pIndex) => (
              <div className="col-md-12 faqSection">
                <h4>{data.sectionName}</h4>
                {data.faqs.map((fs: any, index: any) => (
                  <div className="faq-answer-item">
                    <div
                      className={`${
                        expand &&
                        expand.parentId === pIndex &&
                        expand.childId === index
                          ? "accordion acct-content bottom-no-radius"
                          : "accordion acct-content"
                      }`}
                      onClick={() => {
                        handleExpand(pIndex, index);
                      }}
                    >
                      <h2 className="accordion_text">{fs.Title}</h2>
                      <span
                        className={`${
                          expand &&
                          expand.parentId === pIndex &&
                          expand.childId === index
                            ? "chevron_up"
                            : "chevron_down"
                        }`}
                      >
                        &nbsp;
                      </span>
                    </div>
                    {expand &&
                    expand.parentId === pIndex &&
                    expand.childId === index ? (
                      <div className="panel">
                        <div className="order_item">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-12 field">
                                <HtmlRender content={fs.Description} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-center">
              <p style={{fontSize: 18,color: 'black'}}>Couldn't find any result</p>
            </div>
          )}
        </div>
      </div>
      {countryId !== COUNTRY.ANG ? (
        <>
          <Footer />
          <div className="sm">
            <Link to={facebookUrl} target="_blank">
              <img src={URLS.FACEBOOK_ICON_WHITE} />
            </Link>
            <Link to={instagramUrl} target="_blank">
              <img src={URLS.INSTAGRAM_ICON_WHITE} />
            </Link>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default Faq;
