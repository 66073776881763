import { FC, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../../../../constants/app";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import TextInput from "../../../../components/UI/TextInput";
import "./emailupdate.scss";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { newsLetterApi } from "../../../../services/auth";

interface IEmailUpdateProps {
  onCloseModal: any;
}

const EmailUpdate: FC<IEmailUpdateProps> = ({ onCloseModal }) => {

  const { countryId } =
  useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId
  }));

  const [errorMessage, setErrorMessage] = useState("");

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
  };

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("emailUpdate"),
      initialValues: {
        givenName: "",
        surName: "",
        email: "",
        captcha: "",
      },
      onSubmit: async (values) => {
        const registerDetails = {
          firstName: values.givenName,
          lastName: values.surName,
          email: values.email,
          countryId: countryId
        };
        const { data: {statusCode}} = await newsLetterApi(registerDetails);
        if (statusCode && statusCode === 200) {
          toast.success(" You have successfully registered. Please check your mail");
          onCloseModal();
        } else {
          toast.error(
            "Could not connect to services at the moment. Please try again. Thanks."
          );
        }
      },
    });

  return (
    <div className="email_update_wrapper">
      <div className="modal-header d-block p-0">
        <div className="title title_append">Email Updates</div>
      </div>
      <div className="modal-body">
        <div className="row">
          <div>
            <div className="login-form form_page">
              <div className="row">
                <div className="col-12 col-md-12">
                  <p>
                    <strong>
                      Signup to receive updates about movies and promotions.
                    </strong>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12">
                  <label className="form-label">First Name*</label>
                  <div className="form-group pass_show">
                    <TextInput
                      field={"givenName"}
                      handleChange={handleChange}
                      touched={touched.givenName!}
                      error={errors.givenName}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <label className="form-label">Last Name</label>
                  <div className="form-group pass_show">
                    <TextInput
                      field={"surName"}
                      handleChange={handleChange}
                      touched={touched.surName!}
                      error={errors.surName}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <label className="form-label">Email*</label>
                  <div className="form-group pass_show">
                    <TextInput
                      field={"email"}
                      handleChange={handleChange}
                      touched={touched.email!}
                      error={errors.email}
                    />
                  </div>
                  <div className="col-12  col-md-12">
                    <label className="form-label">Verification Code* </label>
                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey={`${CAPTCHA_SITE_KEY}`}
                        onChange={onChangeCaptcha}
                      />
                      {touched.captcha && errors.captcha ? (
                        <div className="error_message">{`${errors?.captcha}`}</div>
                      ) : null}
                    </div>
                  </div>
                  {errorMessage ? (
                    <div className="err-message">{errorMessage} </div>
                  ) : null}
                  <div className="movie_button_wrap justify-content-center">
                    <button
                      type="button" onClick={() => handleSubmit()}
                      className="btn black_btn m-r-20"
                    >SUBSCRIBE</button>
                    <button type="button" className="btn gray_btn" onClick={onCloseModal}>
                      CANCEL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailUpdate;
