import { AnyAction } from "@reduxjs/toolkit";
import { formatBookedTicket } from "../../utils/formatter";
import { ACTIONS, IFoodBeverageState } from './type';

const initialState: IFoodBeverageState = {
  foodItemsError: null,
  foodItemsLoader: false,
  foodItemsData: [],
  FoodOrderPostError: null,
  FoodOrderPostLoader: false,
  FoodOrderPostData: [],
  concessions: [],
  modalHeaderText: "",
  ticketLessFb: false,
  orderCartData: [],
  isFutureFb: false,
  alcoholDob: "",
  license: null,
  bookedTickets: null,
  prePurchasedTicket: null,
  isConcessionDiscount: false
};

export default function foodBeverageReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case ACTIONS.GET_FOOD_BEVERAGE_WATCHER:
      return {
        ...state,
        foodItemsData:[],
        foodItemsLoader: true,
      };
    case ACTIONS.SET_CONCESSION_DISCOUNT:
        return {
          ...state,
          isConcessionDiscount: action.payload,
        };
    case ACTIONS.GET_FOOD_BEVERAGE_SUCCESS:
      return {
        ...state,
        foodItemsError: null,
        foodItemsData: action.payload,
        foodItemsLoader: false,
        alcoholDob: ""
      };
    case ACTIONS.GET_FOOD_BEVERAGE_FAILURE:
      return {
        ...state,
        foodItemsError: action,
        foodItemsLoader: false,
      };
    case ACTIONS.FOOD_ORDER_POST_WATCHER:
      return {
        ...state,
        concessions: action.payload.concessions,
        FoodOrderPostLoader: true,
      };
    case ACTIONS.FOOD_ORDER_POST_SUCCESS:
      return {
        ...state,
        FoodOrderPostError: null,
        FoodOrderPostData: action.payload,
        FoodOrderPostLoader: false,
      };
    case ACTIONS.FOOD_ORDER_POST_FAILURE:
      return {
        ...state,
        FoodOrderPostError: action,
        FoodOrderPostLoader: false,
      };
    case ACTIONS.SETUP_MODAL_HEADER_TEXT:
      return {
        ...state,
        modalHeaderText: action.payload,
      };
    case ACTIONS.CLEAR_FOOD_ITEMS:
      return {
        ...state,
        orderCartData: [],
        isFutureFb: false,
        alcoholDob: "",
        license: null,
        bookedTickets: null,
        prePurchasedTicket: null
      };
    case ACTIONS.SET_TICKETLESS_FB:
      return {
        ...state,
        ticketLessFb: action.payload
      };
    case ACTIONS.ADD_ORDER_FOOD_ITEMS:
      return {
        ...state,
        orderCartData: [...state.orderCartData, action.payload],
      };
    case ACTIONS.SET_ALCOHOL_DOB:
      return {
        ...state,
        alcoholDob: action.payload
      }
    case ACTIONS.GET_LICENSE_SUCCESS:
      return {
        ...state,
        license: action.payload,
        alcoholDob: ""
      }
    case ACTIONS.GET_LICENSE_ERROR:
      return {
        ...state,
        license: null
      }
    case ACTIONS.UPDATE_ORDER_FOOD_ITEMS:
      return {
        ...state,
        orderCartData: [...action.payload],
      };
      case ACTIONS.GET_BOOKED_TICKETS:
        return {
          ...state,
          bookedTickets: null
        }
      case ACTIONS.GET_BOOKED_TICKETS_SUCCESS:
        const data = formatBookedTicket(action.payload);
        return {
          ...state,
          bookedTickets: data
        }
      case ACTIONS.GET_BOOKED_TICKETS_ERROR:
        return {
          ...state,
          bookedTickets: null
        }  
      case ACTIONS.PRE_PURCHASED_TICKET:
          return {
            ...state,
            prePurchasedTicket: action.payload,
          };
    default:
      return state;
  }
}
