import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { COUNTRY } from "../../../constants/app";
import { Link, useNavigate } from "react-router-dom";
import { URLS } from "../../../constants/url";
import { setContentModal, toggleContentModal } from "../../../store/application/action";
import { ROUTES } from "../../../constants/path";
import './footer.scss';


interface footerInterface { }

const Footer: FC<footerInterface> = () => {

    const {
        countryId,
    } = useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId as COUNTRY,
    }));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [facebookUrl, setFacebookUrl] = useState<string>("");
    const [instagramUrl, setInstagramUrl] = useState<string>("");


  useEffect(() => {
    switch (countryId) {
      case COUNTRY.AUS:
        setFacebookUrl(URLS.FACEBOOK_AU);
        setInstagramUrl(URLS.INSTAGRAM_AU);
        break;
      case COUNTRY.NZ:
        setFacebookUrl(URLS.FACEBOOK_NZ);
        setInstagramUrl(URLS.INSTAGRAM_NZ);
        break;
      case COUNTRY.ANG:
        setFacebookUrl(URLS.FACEBOOK_ANG);
        setInstagramUrl(URLS.INSTAGRAM_ANG);
        break;
      case COUNTRY.STA:
        setFacebookUrl(URLS.FACEBOOK_STA);
        setInstagramUrl(URLS.INSTAGRAM_STA);
        break;
    }
  }, [countryId]);
 
    const openModal = (type: string) => {
        dispatch(setContentModal({ type }));
        dispatch(toggleContentModal(true));
      };
    


    return  <div className="member-footer-top">
    <div className="footer-top">
      <div className="footer-top-inner">
        <ul>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => openModal(ROUTES.REELCLUB_TERMS)}
            >
              TERMS
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => navigate(`/${ROUTES.FAQ}`)}
            >
              FAQs
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => openModal(ROUTES.CONTACT_US)}
            >
              CONTACT US
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() =>
                navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`)
              }
            >
              CINEMA HOMEPAGE
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="sm">
      <Link to={facebookUrl} target="_blank">
        <img src={URLS.FACEBOOK_ICON_WHITE} />
      </Link>
      <Link to={instagramUrl} target="_blank">
        <img src={URLS.INSTAGRAM_ICON_WHITE} />
      </Link>
    </div>
  </div>
}

export default Footer;