import React, { FC, useEffect, useMemo } from "react";
import "./confirmation.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import Barcode from "react-barcode";
import { COUNTRY } from "../../../../../constants/app";
import { generateMailApi } from "../../../../../services/payment";
import { toast } from "react-toastify";
import { memberValidationWatcher } from "../../../../../store/auth/action";
import { IMAGE_URL_CONFIG } from "../../../../../constants/url";
import { formatMembershipExpireDate } from "../../../../../utils/rewards";
import moment from "moment";
import { setContentModal, toggleContentModal } from "../../../../../store/application/action";
import { ROUTES } from "../../../../../constants/path";
import { toggleTicketModal } from "../../../../../store/ticketBooking/action";

interface IMilitaryConfirmationProps {
  onCloseModal: () => void;
}

const MembershipConfirmation: FC<IMilitaryConfirmationProps> = ({
  onCloseModal,
}) => {
  const {
    data,
    countryId,
    membershipPurchase,
    userDetails,
    ticketDetails,
    memberDetail,
    isLoggedIn
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    data: state.ticketBookingReducer.modal.data,
    membershipPurchase: state.authReducer.membershipPurchase,
    userDetails: state.authReducer.userDetails,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    memberDetail: state.authReducer.memberDetail,
    isLoggedIn: state.authReducer.isLoggedIn
  }));

  const dispatch = useDispatch();

  const sendTicketConfirmation = async () => {
    const payload = {
      id: userDetails?.UserSessionId! || membershipPurchase?.userSessionId!,
      countryId: countryId,
      templateName: membershipPurchase?.membershipType === 'renewal' ? "Loyalty Membership Renewal Confirmation" : "Loyalty Membership Confirmation",
      memberPackageType: membershipPurchase?.membershipType
    };
    const { status } = (await generateMailApi(payload)) as any;
    if (status) {
      toast.success("Confirmation sent to your e-mail successfully.");
    } else {
      toast.error(
        "Could not connect to services at the moment. Confirmation mail will be sent to your e-mail shortly."
      );
    }
  };

  useEffect(() => {
    sendTicketConfirmation();
    const payload = {
      MemberRefId: userDetails?.vista_memberid,
      UserSessionId: userDetails?.result?.cardNumber,
      cardNumber: userDetails?.result?.cardNumber,
      countryId: countryId
    };
    if (membershipPurchase?.membershipType !== 'new') {
      dispatch(
        memberValidationWatcher(
          payload,
          (res: any) => { },
          (err: any) => { }
        )
      );
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      const payload = {
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: userDetails?.result?.cardNumber,
        cardNumber: userDetails?.result?.cardNumber,
        countryId: countryId,
      };
      dispatch(memberValidationWatcher(payload));
    }
  }, [userDetails]);

  const total = useMemo(() => {
    return membershipPurchase &&
      membershipPurchase.loyaltyPackage &&
      membershipPurchase.loyaltyPackage.priceInCents
      ? membershipPurchase.loyaltyPackage.priceInCents / 100
      : 0;
  }, [membershipPurchase]);

  const onClickAutomatic = ()=>{
    if(!isLoggedIn && membershipPurchase?.membershipType === 'new' ){
      dispatch(setContentModal({ type: ROUTES.MEMBERSHIP_ACTIVATE_INFO }));
      dispatch(toggleContentModal(true));
      dispatch(toggleTicketModal(false));
    }else{
      dispatch(setContentModal({ type: ROUTES.EDIT_RENEWAL }));
      dispatch(toggleContentModal(true));
      dispatch(toggleTicketModal(false));
    }
  }

  const memberType = useMemo(()=>{
    switch(countryId){
      case COUNTRY.STA:
        return 'Boost';
      case COUNTRY.ANG:
        return 'Gold';
      default:
        return 'Gold' 
    }
  },[countryId])

  return (
    <div className="member-confirmation-wrapper">
      <div className="col-12">
        <div className="px-4">
          {membershipPurchase?.membershipType === 'renewal' ? <p className="main-header">
            Thank you for choosing to be an Angelika {memberType} Member
          </p>
            : membershipPurchase?.membershipType === 'upgrade' ? <p className="main-header">
              Thank you for choosing to be an Angelika {memberType} Member
            </p> : <p className="main-header">
              Thank you for becoming an Angelika {memberType} Member!
            </p>}
          {membershipPurchase?.membershipType === 'new' ? <div className={`activation_link_banner brand_${countryId}`}>
            <div className="party_logo">
              <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + (countryId === COUNTRY.STA?'party_blue.svg':'party.svg')} />
            </div>
            <div className="activation_content">
              <p>
                <span>An activation link has been sent to your registered email address.</span>
                <br />Please click on the activation link in your email<span>
                  {" "} to start using your exclusive Angelika Rewards benefits.</span>
              </p>
            </div>
          </div> : null}
          <div>
            <div className="ticket_detail_wrap">
              <div className="ticket_detail">
                <div className="ticket_number mt-3">
                  <p className="label">PURCHASE REFERENCE</p>
                  <p className="number">{ticketDetails?.bookingId}</p>
                </div>
              </div>

              <div className="bar_code">
                {/* <Barcode
                  width={2}
                  height={80}
                  displayValue={false}
                  value={
                    ticketDetails?.bookingId ? ticketDetails?.bookingId : ""
                  }
                /> */}
                {/* <div className="d-flex justify-content-center f-14">
                  {userDetails?.cardNumber ||
                    memberDetail?.LoyaltyMember?.CardNumber}
                </div> */}
              </div>
              <div className="item-wrapper">
                <h5>{countryId === COUNTRY.STA?"Angelika Rewards Boost - 1 year":"Angelika Rewards Gold - 1 year"}</h5>
                <div className="content_wrapper">
                  <div className="label">Member #:</div>
                  <div className="value">{userDetails?.cardNumber ||
                    memberDetail?.LoyaltyMember?.CardNumber}</div>
                </div>
                <div className="content_wrapper">
                  <div className="label">Item Total:</div>
                  <div className="value">${total.toFixed(2)}</div>
                </div>
                <div className="content_wrapper">
                  <div className="label">Expires On:</div>
                  <div className="value">{membershipPurchase?.membershipType !== 'new' ?formatMembershipExpireDate(memberDetail!): moment().add(1, 'y').format("DD MMM YYYY")}</div>
                </div>
              </div>
            </div>
            <div className="user-details">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="total">TOTAL ORDER</div>
                  <div className="total-amount">${total.toFixed(2)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {membershipPurchase?.membershipType === 'new' ? <div className="content">
          <p className="header">
          <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}success-Selected.png`} alt="" /> Receipt Sent!
          </p>
          <div className="mail_wrapper">
            <p className="body">
              Emailed To: <span>{userDetails?.email}</span>
            </p>
            <div className="resend_btn">
              <button className="btn btn_grey" onClick={sendTicketConfirmation}>RESEND</button>
            </div>
          </div>
        </div> : null}

        <div className={`movie_footer_btn_el col-md-12 ml-auto px-5`}>
          <div className="movie_button_wrap justify-content-end">
            <button
              type="button"
              className="btn btn_green"
              onClick={onClickAutomatic}
            >
              SET UP AUTOMATIC RENEWAL
            </button>
            <button
              type="button"
              className="btn black_btn"
              onClick={onCloseModal}
            >
              RETURN TO HOME PAGE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipConfirmation;
