import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { COUNTRY, SIDE_MENU } from "../../../constants/app";
import { URLS } from "../../../constants/url";
import { RootState } from "../../../store";
import { openSliderAction, setContentModal, toggleContentModal } from "../../../store/application/action";
import { socialMediaUrl } from "../../../utils/helper";

interface ISideMenuProps {}

const SideMenu: FC<ISideMenuProps> = () => {
  const { countryId, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const dispatch = useDispatch();

  const [facebookUrl, setFacebookUrl] = useState<string>("");
  const [instagramUrl, setInstagramUrl] = useState<string>("");

  useEffect(() => {
    if(countryId){
      const {facebook, instagram} = socialMediaUrl(countryId);
      setFacebookUrl(facebook);
      setInstagramUrl(instagram);
    }
  }, [countryId]);

  
  const closeSideMenu = ()=>{
    dispatch(openSliderAction({open:false, type: ""}));
  }

  const openModal = (path: any) => {
    dispatch(setContentModal({ type: path}));
    dispatch(toggleContentModal(true));
  }

  const GenerateMenu = ({ children }: { children: ReactNode }) => (
    <ul className="cinemas_list">
      {SIDE_MENU.map((value, index) => (
        <Fragment key={`main_${index}`}>
          <li className="heading">{value.header?.[countryId]}</li>
          {index === 2 ? children : null}
          {value.menus.map((v, i) => {
            return v.enable.includes(countryId) ? (
              <li key={`sub_${i}`} onClick={closeSideMenu}>
                {v.type === "page" ? (
                  <Link to={(v.path).replace(':cinemaId',currentCinema?.slug!)}>{v.label}</Link>
                ) : v.type === "modal" ? (
                  <a  href="javascript:void(0)" onClick={() => openModal(v.path)}> {v.label}</a>
                ) : v.type === "link" ? (
                  <Link to={v.path} target="_blank">{v.label}</Link>
                ) : null}
              </li>
            ) : null;
          })}
        </Fragment>
      ))}
    </ul>
  );

  return (
    <div className="side-area-sec">
      <GenerateMenu>
        <ul className="contact_list">
          <li className="social_icon">
            <a href={facebookUrl} className="facebook_icon" target="_blank">
              <img src={URLS.FACEBOOK_ICON} alt="Facebook" title="Facebook" />
            </a>
          </li>
          <li className="social_icon">
            <a href={instagramUrl} className="instagram_icon" target="_blank">
              <img
                src={URLS.INSTAGRAM_ICON}
                alt="Instagram"
                title="Instagram"
              />
            </a>
          </li>
        </ul>
      </GenerateMenu>
    </div>
  );
};

export default SideMenu;
