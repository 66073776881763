import React,{ FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { getComingSoonWatcher } from "../../../../store/films/action";
import { sortBy } from "lodash";
import { IFormatMovie } from "../../../../models/cinema";
import { IMOVIE_TYPES } from "../../../../models/common";
import { MOVIE_TYPES } from "../../../../constants/app";
import Session from "../../../../components/Session/AU/ComingSoon";
import { setFooterType } from "../../../../store/application/action";
import { useLocation } from "react-router";

interface IComingSoonProps {}

const ComingSoon: FC<IComingSoonProps> = () => {
  const { countryId, cinemaId, comingSoon } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      cinemaId: state.applicationReducer.currentCinema?.cinemaId,
      comingSoon: state.filmsReducer.comingSoon,
    })
  );

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [comingSoonData, setComingSoonData] = useState<IFormatMovie[]>([]);
  const [sort, setSort] = useState<string>("date");
  const [movieTypes, setMovieTypes] = useState<IMOVIE_TYPES>(MOVIE_TYPES);

  useEffect(() => {
    if (cinemaId) {
      const kids = pathname.includes('kids-corner');
      const payload = {
        countryId: countryId,
        cinemaId: cinemaId,
        flag: kids?"kids":"",
        requestType: kids?"kids":"",
      };
      dispatch(getComingSoonWatcher(payload));
    }
  }, [cinemaId, pathname]);

  useEffect(() => {
    if (comingSoon) {
      const sortedData = sortBy(comingSoon, (row) => {
        return row.hasOwnProperty("movieData")
          ? row["movieData"]["release_date"]
          : row["release_date"];
      });
      dispatch(setFooterType(sortedData.length));
      setComingSoonData(sortedData);
      setFlags(sortedData);
    }
  }, [comingSoon]);

  const setFlags = (data: IFormatMovie[]) => {
    const tempMoviesTypes = movieTypes;
    Object.keys(tempMoviesTypes).forEach(v=>{
      tempMoviesTypes[v].active = false;
    })
    data?.forEach((movies: any) => {
      if (movies?.shows) {
        Object.values(movies.shows).forEach((sessions: any) => {
          sessions.forEach((session: any) => {
            const sessionAttributes = session?.session_attributes;
            if (
              sessionAttributes &&
              Object.keys(tempMoviesTypes).includes(
                sessionAttributes.toLowerCase()
              )
            ) {
              tempMoviesTypes[sessionAttributes.toLowerCase()].active = true;
            }
          });
        });
      }
    });
    setMovieTypes(tempMoviesTypes);
  };

  const sortData = (data: any, type: string) => {
    switch (type) {
      case "date":
        return sortBy(comingSoon, (row) => {
          return row.hasOwnProperty("movieData")
            ? row["movieData"]["release_date"]
            : row["release_date"];
        });
      case "movie":
        return sortBy(comingSoon, (row) => {
          return row.hasOwnProperty("movieData")
            ? row["movieData"]["name"]
            : row["movieName"];
        });
    }
  };

  const onSort = (type: string) => {
    setSort(type);
    const sortedData = sortData(comingSoon, type);
    if (sortedData) {
      setComingSoonData(sortedData);
    }
  };

  return (
    <div>
      {comingSoon.length > 0 ? (
        <>
          <div className="bg stickysecmenu">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="sort_wrap">
                    <div className="sort_title">Sort by:</div>
                    <ul className="sort_type">
                      <li
                        onClick={() => onSort("date")}
                        className={`${sort === "date" ? "active" : ""}`}
                      >
                        Release Date
                      </li>
                      <li
                        onClick={() => onSort("movie")}
                        className={`${sort === "movie" ? "active" : ""}`}
                      >
                        Movie Name
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="legend_text">
                    <ul>
                      {Object.values(movieTypes).map((v) => {
                        return v.active ? (
                          <li className={`${v.class} text-nowrap`}>
                            {v.title}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Session sessionData={comingSoonData} />
        </>
      ) : (
        <div className="err-noMovie">No sessions available</div>
      )}
    </div>
  );
};

export default ComingSoon;
