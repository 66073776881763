import React, { FC, useEffect, useMemo, useState } from "react";
import GooglePayButton from "@google-pay/button-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { COUNTRY, COUNTRY_CODE, CURRENCY } from "../../../../constants/app";
import { concessionPriceCalculation } from "../../../../utils/tickets";
import { URLS } from "../../../../constants/url";
import {
  fetchPaymentIntentClientSecret,
  verifyFatzebraPaymentTokenApi,
} from "../../../../services/payment";
import { PAYMENT_TYPE } from "../../../../models/payment";
import { calTotal } from "../../../../utils/giftshop";
declare function Stripe(key: any, object: any): any;

interface IGPayProps {
  processPayment: any;
  isValid: any;
  onError: any;
  checkValid: () => void;
  page: string;
}

const GPay: FC<IGPayProps> = ({
  isValid,
  processPayment,
  onError,
  checkValid,
  page,
}) => {
  const {
    countryId,
    orderCartData,
    ticketDetails,
    userDetails,
    cart,
    orderResponse,
    memberDetail,
    membershipPurchase,
    currentCinema
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId as COUNTRY,
    orderCartData: state.foodBeverageReducer.orderCartData,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    userDetails: state.authReducer.userDetails,
    cart: state.giftCardReducer.cart,
    orderResponse: state.giftCardReducer.orderResponse,
    memberDetail: state.authReducer.memberDetail,
    membershipPurchase: state.authReducer.membershipPurchase,
    currentCinema: state.applicationReducer.currentCinema
  }));

  const [env, setEnv] = useState<any>(process.env.REACT_APP_PAYMENT_ENV!);
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [provider, setProvider] = useState<any>(null);
  const [showButton, setShowButton] = useState(false);

  const concessionTotal = useMemo(() => {
    return concessionPriceCalculation(orderCartData);
  }, [orderCartData]);

  const total = useMemo(() => {
    if (page === "ticketing") {
      return (
        (ticketDetails.total ? ticketDetails.total : 0) + concessionTotal
      ).toFixed(2);
    } else if (page === "giftcard") {
      return calTotal(cart, countryId).total.toFixed(2);
    } else if (
      membershipPurchase &&
      membershipPurchase.loyaltyPackage &&
      page === "membership"
    ) {
      return (membershipPurchase.loyaltyPackage.priceInCents / 100).toFixed(2);
    } else {
      return 0;
    }
  }, [countryId, ticketDetails, concessionTotal, cart, membershipPurchase]);

  useEffect(() => {
    if (currentCinema) {
      switch (countryId) {
        case COUNTRY.STA:
        case COUNTRY.ANG:
        case COUNTRY.AUS:
          setProvider("fatzebra");
          setPaymentRequest({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: "CARD",
                parameters: {
                  allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                  allowedCardNetworks: ["VISA", "MASTERCARD"],
                },
                tokenizationSpecification: {
                  type: "PAYMENT_GATEWAY",
                  parameters: {
                    gateway: "fatzebra",
                    gatewayMerchantId: ['TOWNSVILLE', 'dubbo'].includes(currentCinema.slug) ? process.env.REACT_APP_FATZEBRA_COUNTRY_CINEMAS_MERCHANT_ID : process.env.REACT_APP_FATZEBRA_MERCHANT_ID,
                  },
                },
              },
            ],
            merchantInfo: {
              merchantId: process.env.REACT_APP_READINGS_GOOGLE_MERCHANT_ID,
              merchantName:
                countryId === COUNTRY.STA
                  ? "State Cinema"
                  : countryId === COUNTRY.ANG
                    ? "Angelika Cinemas"
                    : "Reading Cinemas",
            },
            transactionInfo: {
              totalPriceStatus: "FINAL",
              totalPriceLabel: "Total Price",
              totalPrice: total,
              currencyCode: CURRENCY[countryId],
              countryCode: COUNTRY_CODE[countryId],
            },
          });
          break;
      }
    }
  }, [countryId, total, currentCinema]);


  useEffect(() => {
    setShowButton(isValid);
  }, [isValid]);

  const onLoadPaymentData = async (event: any) => {
    if (
      event &&
      event.paymentMethodData &&
      event.paymentMethodData.tokenizationData &&
      event.paymentMethodData.tokenizationData.token
    ) {
      const payload = {
        countryId: countryId,
        amount: +total * 100,
        reference:
          page === "ticketing"
            ? `invoice${ticketDetails?.userSessionId!}`
            : page === "giftcard"
            ? `invoice${orderResponse?.userSessionId!}`
            : page === "membership"
            ? (userDetails?.UserSessionId?`invoice${userDetails?.UserSessionId}`:`invoice${membershipPurchase?.userSessionId}`)
            : "",
        wallet: {
          type: "GOOGLE",
          token: JSON.parse(event.paymentMethodData.tokenizationData.token),
        },
        email: userDetails?.email!,
        cinemaId: currentCinema?.slug
      };

      await verifyFatzebraPaymentTokenApi(payload)
        .then((res) => {
          if (res.data.status === true) {
            let [Year, Month, Date] = res.data.data.card_expiry.split("-");
            let cardDetails = {
              expirymonth: Month + "",
              expiryyear: Year + "",
              cardNumber: res.data.data.card_number.substr(
                res.data.data.card_number.length - 4
              ),
              expiryDate: Month + "/" + Year.substring(2, Year.length),
              cardHolderName: "",
              cvv: "",
            };
            processPayment(
              {
                cardDetails: cardDetails,
                paymentIntentId: res.data.data.transaction_id,
              },
              PAYMENT_TYPE.GOOGLE_PAY
            );
          } else {
            onError();
          }
        })
        .catch(() => {
          onError();
        });
    }
  };

  return !showButton ? (
    <button
      type="button"
      className="btn black_btn"
      style={{ borderRadius: 5 }}
      onClick={checkValid}
    >
      <img style={{ width: 67, marginTop: 2 }} src={URLS.GPAY_ICON} alt="" />
    </button>
  ) : provider === "fatzebra" ? (
    <GooglePayButton
      style={{ width: 132, height: 50 }}
      environment={env}
      paymentRequest={paymentRequest}
      buttonColor="black"
      buttonType="plain"
      buttonSizeMode="fill"
      onLoadPaymentData={onLoadPaymentData}
    />
  ) : null;
};

export default GPay;
