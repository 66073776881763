import { FC, useState } from "react";
import { QUESTIONS } from "../../../constants/membership";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { COUNTRY } from "../../../constants/app";
import HtmlRender from "../../UI/HtmlRender";
import './faq.scss';


interface faqInterface { }

const Faq: FC<faqInterface> = () => {

    const {
        countryId,
    } = useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId as COUNTRY,
    }));

    const [expand, setExpand] = useState<{
        parentId: number;
        childId: number;
    } | null>(null);


    const handleExpand = (parentIndex: number, childIndex: number) => {
        if (
            expand &&
            expand.parentId === parentIndex &&
            expand.childId === childIndex
        ) {
            setExpand(null);
        } else {
            setExpand({
                parentId: parentIndex,
                childId: childIndex,
            });
        }
    };


    return QUESTIONS[countryId]?.length > 0 ? <div className="membership-question pb-5">
        <div className="header">Questions?</div>
        <div className="container">
            <div className="faq-answer">
                <div className="faq-answer-inner">

                    {QUESTIONS[countryId]?.map((data: any, pIndex: number) => (
                        <div className="col-md-12 faqSection">
                            {data.sectionName ? <h4>{data.sectionName}</h4> : null}
                            {data.faqs.map((fs: any, index: any) => (
                                <div className="faq-answer-item">
                                    <div
                                        className={`${expand &&
                                            expand.parentId === pIndex &&
                                            expand.childId === index
                                            ? "accordion acct-content bottom-no-radius"
                                            : "accordion acct-content"
                                            }`}
                                        onClick={() => {
                                            handleExpand(pIndex, index);
                                        }}
                                    >
                                        <h2 className="accordion_text">{fs.Title}</h2>
                                        <span
                                            className={`${expand &&
                                                expand.parentId === pIndex &&
                                                expand.childId === index
                                                ? "chevron_up"
                                                : "chevron_down"
                                                }`}
                                        >
                                            &nbsp;
                                        </span>
                                    </div>
                                    {expand &&
                                        expand.parentId === pIndex &&
                                        expand.childId === index ? (
                                        <div className="panel">
                                            <div className="order_item">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-12 field">
                                                            <HtmlRender content={fs.Description} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

        </div>
    </div> : null
}

export default Faq;