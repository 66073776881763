import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { COUNTRY } from "../../../../constants/app";
import { upgrademembership } from "../../../../services/rewards";

interface IUpgradeGoldBtnProps {
  fromPage: string;
  upgradeData?: any;
}

const UpgradeGoldBtn: FC<IUpgradeGoldBtnProps> = ({
  fromPage,
  upgradeData,
}) => {
  const {
    isLoggedIn,
    countryId,
    settings,
    brandId,
    currentCinema,
    userDetails,
    memberDetail,
  } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId,
    isLoggedIn: state.authReducer.isLoggedIn,
    settings: state.applicationReducer.settings,
    countryId: state.applicationReducer.countryId as COUNTRY,
    currentCinema: state.applicationReducer.currentCinema,
    userDetails: state.authReducer.userDetails,
    memberDetail: state.authReducer.memberDetail,
  }));

  const dispatch = useDispatch();
  
  const purchase = async() => {
    await upgrademembership(countryId, currentCinema, userDetails, dispatch);
  };

  return (
    <button className="membership-button px-4" onClick={purchase}>
      {fromPage === "membership" ? "UPGRADE" : "UPGRADE TO GOLD"}
    </button>
  );
};

export default UpgradeGoldBtn;
