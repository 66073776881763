import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COUNTRY, MOVIE_TYPES } from "../../../../constants/app";
import { RootState } from "../../../../store";
import { getNowShowingWatcher } from "../../../../store/films/action";
import { downloadFile } from "../../../../utils/helper";
import _, { sortBy, orderBy } from "lodash";
import "./nowShowing.scss";
import moment from "moment";
import DateSlider from "../../../../components/UI/DateSlider";
import { IFormatMovie } from "../../../../models/cinema";
import { formatMovieData } from "../../../../utils/formatter";
import { IMOVIE_TYPES } from "../../../../models/common";
import Session from "../../../../components/Session/AU/NowShowing";
import {
  setDateObject,
  setFooterType,
  setReinitializeScroll,
} from "../../../../store/application/action";
import { setCanBookFood } from "../../../../store/ticketBooking/action";
import { useLocation } from "react-router";

interface INowShowingProps {
  page?: string;
  data?: any
}

const NowShowing: FC<INowShowingProps> = ({ page, data }) => {
  const {
    countryId,
    cinemaId,
    nowShowing,
    sessionPdf,
    currentCinema,
    brandId,
    dateObject
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    brandId: state.applicationReducer.brandId,
    cinemaId: state.applicationReducer.currentCinema?.cinemaId,
    nowShowing: state.filmsReducer.nowShowing,
    sessionPdf: state.applicationReducer.currentCinema?.sessionPdf,
    currentCinema: state.applicationReducer.currentCinema,
    dateObject: state.applicationReducer.dateObject
  }));

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [showPrintSession, setShowPrintSession] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [dateArray, setDateArray] = useState<
    Array<{ disp_date: string; value: string }>
  >([]);
  const [sort, setSort] = useState<string>("showtimes");
  const [nowShowingData, setNowShowingData] = useState<IFormatMovie[]>([]);
  const [movieTypes, setMovieTypes] = useState<IMOVIE_TYPES>(MOVIE_TYPES);
  const [selectedDate, setSelectedDate] = useState<string>("");

  useEffect(() => {
    if (countryId) {
      switch (countryId) {
        case COUNTRY.STA:
          setShowPrintSession(false);
          setShowPdf(true);
          break;
        default:
          setShowPrintSession(true);
          setShowPdf(false);
          break;
      }
    }
  }, [countryId]);

  useEffect(() => {
    if (cinemaId) {
      const kids = pathname.includes('kids-corner');
      let flag = '';
      if (page === "soholongue") {
        flag = "Soho";
      } else if (page === "luxuryCinemas") {
        flag = data.category; 
      } else if (kids) {
        flag = "kids";
      }

      let cinId = cinemaId;
      if (page === "luxuryCinemas" || page === "cinemas") {
        cinId = data.cinema;
      }

      const payload = {
        countryId: countryId,
        cinemaId: cinId,
        status: "nowShowing",
        flag: flag,
      };
      dispatch(getNowShowingWatcher(payload));
    }
  }, [cinemaId, pathname]);

  useEffect(() => {
    if (Object.keys(nowShowing).length > 0) {
      let resDate = _.keys(nowShowing);
      resDate = _.sortBy(resDate, function (date: string) {
        return new Date(date);
      });
      const dates = resDate.map((v) => {
        return {
          disp_date:
            v == moment().format("YYYY-MM-DD")
              ? "TODAY"
              : moment(v).format("DD/MM ddd"),
          value: v,
        };
      });
      setDateArray(dates);
      const preselectedDate = dateObject && dateObject.value ? dateObject.value : dates[0].value;
      setSelectedDate(preselectedDate);
      const sortedData = sortData(
        formatMovieData(
          Object.values(nowShowing[preselectedDate]?nowShowing[preselectedDate]: nowShowing[dates[0].value]?nowShowing[dates[0].value] : [])
        ),
        sort
      );

      let last_show_time: any;
      let canBookFood = 0;

      sortedData!.forEach((item: any) => {
        if (item.shows && item.shows.length > 0) {
          let show = moment(
            item.shows[item.shows.length - 1].showtime,
            "hh:mmA"
          );
          if (last_show_time == undefined) {
            last_show_time = show;
          } else if (last_show_time.isBefore(show)) {
            last_show_time = show;
          }
        }
      });

      let end_time = moment(last_show_time).add(1, "hours");
      let now = moment().utcOffset(currentCinema?.timeZone);
      let ct_time = moment(now, "hh:mmA");

      if (
        sortedData!.length > 0 &&
        last_show_time &&
        ct_time.isBefore(end_time)
      ) {
        canBookFood = 1;
      }
      dispatch(setCanBookFood(canBookFood));

      if (sortedData) {
        setNowShowingData(sortedData);
        setFlags(sortedData);
        dispatch(setFooterType((page === 'soholongue' || countryId === COUNTRY.ANG) ? 5 : sortedData.length));
      }
    } else {
      setNowShowingData([]);
      dispatch(setFooterType(0));
    }
  }, [nowShowing]);

  const setFlags = (data: IFormatMovie[]) => {
    const tempMoviesTypes = { ...movieTypes };
    Object.keys(tempMoviesTypes).forEach(v => {
      tempMoviesTypes[v].active = false;
    })
    data?.forEach((movies: any) => {
      movies?.shows?.forEach((session: any) => {
        const sessionAttributes = session?.session_attributes;

        if (
          sessionAttributes &&
          Object.keys(tempMoviesTypes).includes(sessionAttributes.toLowerCase())
        ) {
          tempMoviesTypes[sessionAttributes.toLowerCase()].active = true;
        }
      });
    });
    setMovieTypes(tempMoviesTypes);
  };

  const downloadPdf = () => {
    downloadFile(sessionPdf);
  };

  const sortData = (data: any, type: string) => {
    switch (type) {
      case "showtimes":
        return [...orderBy(data, (row) => row["shows"].length, ["desc"])];
      case "movieName":
        return [...sortBy(data, (row) => row["movieData"]["name"])];
    }
  };

  const onSort = (type: string) => {
    setSort(type);
    const sortedData = sortData(nowShowingData, type);
    if (sortedData) {
      setNowShowingData(sortedData);
    }
  };

  const onChangeDate = (dateObject: { disp_date: string; value: string }) => {
    dispatch(setDateObject(dateObject));
    setSelectedDate(dateObject.value);
    dispatch(setReinitializeScroll(true));
    const sortedData = sortData(
      formatMovieData(Object.values(nowShowing[dateObject.value])),
      sort
    );
    if (sortedData) {
      setNowShowingData(sortedData);
      setFlags(sortedData);
      dispatch(setFooterType(page === 'soholongue' || countryId === COUNTRY.ANG ? 5 : sortedData.length));
    }
  };

  return (
    <div>
      {nowShowingData.length > 0 ? (
        <>
          <div className="bg stickysecmenu">
            {page !== 'cinemas'?<div className="container-width">
              <div className={`row align-items-center justify-content-center`}>
                <div className="col-12 col-lg-6">
                  {dateArray.length > 0 ? (
                    <DateSlider
                      data={dateArray}
                      onChange={onChangeDate}
                      selected={selectedDate}
                    />
                  ) : null}
                </div>
                {page !== "soholongue" ? (
                  <div className={`col-12 col-lg-4 pl-lg-0 pr-lg-0  ${Object.values(movieTypes).filter(v=>v.active).length > 4?'col-lg-5':''}`}>
                    <div className="print_session_wrap">
                      <div className="d-flex">
                        <div className="selected_location">
                          {currentCinema?.name}
                        </div>
                        <div className="print_session">
                          {showPrintSession ? (
                            <a
                              href="/printsession"
                              target="_blank"
                              className="text-nowrap"
                            >
                              Print Session Times
                            </a>
                          ) : null}
                          {showPdf && sessionPdf ? (
                            <a
                              href="javascript:void(0)"
                              onClick={downloadPdf}
                              className="text-nowrap"
                            >
                              Print Programme
                            </a>
                          ) : null}
                        </div>
                      </div>
                      <div className="legend_text">
                        <ul>
                          {Object.values(movieTypes).map((v) => {
                            return v.active ? (
                              <li className={`${v.class} text-nowrap`}>
                                {v.title}
                              </li>
                            ) : null;
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>:null}
            {page !== 'luxuryCinemas' && page !== 'cinemas' ? <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="sort_wrap">
                    <div className="sort_title">Sort by:</div>
                    <ul className="sort_type">
                      <li
                        onClick={() => onSort("showtimes")}
                        className={`${sort === "showtimes" ? "active" : ""}`}
                      >
                        Popularity
                      </li>
                      <li
                        onClick={() => onSort("movieName")}
                        className={`${sort === "movieName" ? "active" : ""}`}
                      >
                        Movie Name
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> : null}
          </div>
          <Session sessionData={nowShowingData} />
        </>
      ) : (
        <div className="err-noMovie">No sessions available</div>
      )}
    </div>
  );
};

export default NowShowing;
