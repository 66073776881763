import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { FieldArray, Formik } from "formik";
import { createValidationSchema } from "../../../../../utils/validatorSchema";
import { CAPTCHA_SITE_KEY, COUNTRY } from "../../../../../constants/app";
import TextInput from "../../../../UI/TextInput";
import SelectDropdown from "../../../../UI/DropDown/SelectDropDown";
import ReCAPTCHA from "react-google-recaptcha";
import "./competitionForm.scss";
import { ICinemas } from "../../../../../models/cinema";
import { IDropDown } from "../../../../../models/common";
import { toggleContentModal } from "../../../../../store/application/action";
import { saveCompetitionApi } from "../../../../../services/sideMenus";
import { toast } from "react-toastify";
import { sortBy } from "lodash";

interface ICompetitionForm {
  data: any;
}

const CompetitionForm: FC<ICompetitionForm> = ({ data }) => {
  const { userDetails, cinemas, countryId, currentCinema } = useSelector(
    (state: RootState) => ({
      userDetails: state.authReducer.userDetails,
      countryId: state.applicationReducer.countryId,
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
    })
  );

  const dispatch = useDispatch();
  const [cinemaList, setCinemaList] = useState<IDropDown[]>([]);
  const [initialValues, setInitialValue] = useState<any>({
    firstName: userDetails?.firstName ? userDetails?.firstName : "",
    lastName: userDetails?.lastName ? userDetails?.lastName : "",
    email: userDetails?.email ? userDetails?.email : "",
    phoneNumber: userDetails?.phoneNumber ? userDetails?.phoneNumber : "",
    reelCardNumber: "",
    receiptNumber: "",
    ticketNumber: data?.requireTicketNumber == 1
      ? new Array(data?.requireTicketNumber).fill(1).map((v) => "")
      : [],
    cinemaLocation: "",
    giftCardNumber: data?.requireGiftcard == 1
      ? new Array(data?.requireGiftcard).fill(1).map((v) => "")
      : [],
    weeklyCodeWord: "",
    answer: "",
    hasCinemaLocation: data?.cinemaLocation || 0,
    hasTicketNumber: data?.requireTicketNumber || 0,
    hasGiftCard: data?.requireGiftcard || 0,
    hasWeeklyCode: data?.requireWeeklyCode || "",
    hasReceiptNumber: data?.requireRecieptNumber || false,
    agreeNewsletter: false,
    ageSpecific: false,
    captcha: false,
  });

  useEffect(() => {
    if (cinemas) {
      const cinema = Object.values(cinemas)
        .flatMap((v) => v)
        .map((v: any) => ({
          label: `${v.name}, ${v.stateCode}`,
          value: `${v.slug}`,
        }));
      setCinemaList(sortBy(cinema, (row) => row.label));
    }
  }, [cinemas]);

  const onCloseModal = () => {
    dispatch(toggleContentModal(false));
  };

  const onSubmit = async (values: any) => {
    const payload: any = {
      ageSpecific: values.ageSpecific,
      agreeNewsletter: values.agreeNewsletter,
      cinemaId: currentCinema?.slug,
      competitionId: data.competitionId,
      content: values.answer,
      customerEmail: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      location: values.cinemaLocation,
      phoneNumber: values.phoneNumber,
      recieptNumber: values.receiptNumber,
      reelclubCard: values.reelCardNumber,
      weeklyCodeWord: values.weeklyCodeWord,
      ticketList: values.ticketNumber.map((v: string) => ({ ticketNumber: v })),
      giftList: values.giftCardNumber.map((v: string) => ({
        giftCardNumber: v,
      })),
    };

    const { data: response } = await saveCompetitionApi(payload);
    if (response) {
      toast.success("Competition submitted successfully.");
      onCloseModal();
    } else {
      toast.error(
        "Could not connect to services at the moment. Please try again. Thanks."
      );
    }

  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createValidationSchema("competitionModal")}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleSubmit,
        touched,
        handleChange,
        setFieldValue,
      }) => {
        return (
          <>
            <div className="group_booking_form form_page pad-top-20">
              <div className="row">
                <div className="col-12 col-md-4">
                  <label className="form-label">First Name*</label>
                  <div className="form-group">
                    <TextInput
                      value={values.firstName}
                      field={"firstName"}
                      handleChange={handleChange}
                      touched={touched.firstName!}
                      error={errors.firstName}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <label className="form-label">Last Name*</label>
                  <div className="form-group">
                    <TextInput
                      value={values.lastName}
                      field={"lastName"}
                      handleChange={handleChange}
                      touched={touched.lastName!}
                      error={errors.lastName}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Email*</label>
                  <div className=" form-group">
                    <TextInput
                      type="email"
                      value={values.email}
                      field={"email"}
                      handleChange={handleChange}
                      touched={touched.email!}
                      error={errors.email}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Phone* </label>
                  <div className="form-group">
                    <TextInput
                      value={values.phoneNumber}
                      field={"phoneNumber"}
                      handleChange={handleChange}
                      touched={touched.phoneNumber!}
                      error={errors.phoneNumber}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 ">
                  <label className="form-label">
                    {countryId === COUNTRY.STA
                      ? "State Members Card Number"
                      : countryId === COUNTRY.ANG
                      ? "Angelika Rewards Card Number"
                      : "Reel Club Card Number"}
                  </label>

                  <div className="form-group">
                    <TextInput
                      value={values.reelCardNumber}
                      field={"reelCardNumber"}
                      handleChange={handleChange}
                      touched={touched.reelCardNumber!}
                      error={errors.reelCardNumber}
                    />
                  </div>
                </div>
                {data?.requireRecieptNumber != false ? (
                  <div className="col-12 col-md-4">
                    <label className="form-label">Reciept Number* </label>
                    <div className="form-group">
                      <TextInput
                        value={values.receiptNumber}
                        field={"receiptNumber"}
                        handleChange={handleChange}
                        touched={touched.receiptNumber!}
                        error={errors.receiptNumber}
                      />
                    </div>
                  </div>
                ) : null}
                <FieldArray
                  name="ticketNumber"
                  render={({
                    form: {
                      handleChange,
                      setFieldValue,
                      touched,
                      values: value,
                      errors,
                    },
                  }: any) => {
                    return new Array(data.requireTicketNumber)
                      .fill(1)
                      .map((v: any, i: number) => (
                        <div className="col-12 col-md-4">
                          <label className="form-label">Ticket Number*</label>
                          <div className=" form-group">
                            <TextInput
                              //   value={values.ticketNumber[i]}
                              field={"ticketNumber"}
                              onValueChange={(val: any) => {
                                values.ticketNumber[i] = val;
                                setFieldValue("ticketNumber", [
                                  ...values.ticketNumber,
                                ]);
                              }}
                              touched={
                                touched?.ticketNumber?.[i]
                                  ? touched.ticketNumber[i]!
                                  : false
                              }
                              error={`${
                                errors?.ticketNumber?.[i]
                                  ? errors.ticketNumber[i]
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                      ));
                  }}
                />

                {data.cinemaLocation != 0 ? (
                  <div className="col-12 col-md-4">
                    <label className="form-label">Cinema location*</label>
                    <div className=" form-group">
                      <SelectDropdown
                        field={"cinemaLocation"}
                        options={cinemaList}
                        setFieldValue={setFieldValue}
                        touched={touched.cinemaLocation!}
                        error={errors.cinemaLocation}
                        value={values.cinemaLocation}
                      />
                    </div>
                  </div>
                ) : null}
                <FieldArray
                  name="giftCardNumber"
                  render={({
                    form: {
                      handleChange,
                      setFieldValue,
                      touched,
                      values,
                      errors,
                    },
                  }: any) => {
                    return new Array(data.requireGiftcard)
                      .fill(1)
                      .map((v: any, i: number) => (
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Gift Card Number*
                          </label>
                          <div className=" form-group">
                            <TextInput
                              field={"giftCardNumber"}
                              onChange={({ target: { value } }: any) => {
                                values.giftCardNumber[i] = value;
                                setFieldValue("giftCardNumber", [
                                  ...values.giftCardNumber,
                                ]);
                              }}
                              touched={
                                touched?.giftCardNumber?.[i]
                                  ? touched?.giftCardNumber[i]!
                                  : false
                              }
                              error={`${
                                errors?.giftCardNumber?.[i]
                                  ? errors.giftCardNumber[i]
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                      ));
                  }}
                />
                {data.requireWeeklyCode != false ? (
                  <div className="col-12 col-md-4">
                    <label className="form-label">Weekly Code Word* </label>
                    <div className="form-group">
                      <TextInput
                        value={values.weeklyCodeWord}
                        field={"weeklyCodeWord"}
                        handleChange={handleChange}
                        touched={touched.weeklyCodeWord!}
                        error={errors.weeklyCodeWord}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {data?.ageLimit != 0 ? (
              <div className="col-12">
                <div className="custom_checkbox">
                  <input
                    type="checkbox"
                    onClick={({ target: { checked } }: any) => {
                      setFieldValue("ageSpecific", checked);
                    }}
                  />
                  <div className="state p-primary">
                    <label className="form-label">
                      Please tick here if you are {data.ageLimit} years and
                      above
                    </label>
                  </div>
                </div>
              </div>
            ) : null}
            {data?.subscribeToNewsletter != false ? (
              <div className="col-12">
                <div className="custom_checkbox">
                  <input
                    type="checkbox"
                    onClick={({ target: { checked } }: any) => {
                      setFieldValue("agreeNewsletter", checked);
                    }}
                  />
                  <div className="state p-primary">
                    <label className="form-label">{data?.newsLetterText}</label>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="col-12 col-md-12">
              <label className="form-label">Answer*</label>
              <div className=" form-group">
                <textarea
                  className={
                    touched.answer && errors?.answer
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={({ target: { value } }) =>
                    setFieldValue("answer", value)
                  }
                />
                {touched.answer && errors?.answer ? (
                  <div className="error_message">
                    <span>Answer is required</span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-12  col-md-12">
              <label className="form-label">Verification Code* </label>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey={`${CAPTCHA_SITE_KEY}`}
                  onChange={(event) => {
                    if (event) {
                      setFieldValue("captcha", true);
                    } else {
                      setFieldValue("captcha", false);
                    }
                  }}
                />
                {touched.captcha && errors.captcha ? (
                  <div className="error_message">{`${errors?.captcha}`}</div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="button_wrap text-center div">
                <button
                  type="button"
                  className="btn gray_btn back-btn mx-2"
                  onClick={onCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn black_btn mx-1"
                  onClick={() => handleSubmit()}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default CompetitionForm;
