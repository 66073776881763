import { FC } from "react";

interface IGiftshopConfirmationHeaderProps {}

const GiftshopConfirmationHeader: FC<IGiftshopConfirmationHeaderProps> = () => {
  return (
    <h4 className="modal-title py-3 px-3">
      Thank you for purchasing a gift with us!
    </h4>
  );
};

export default GiftshopConfirmationHeader;
