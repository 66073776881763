import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReinitializeScroll } from "../../../../../store/application/action";
import { RootState } from "../../../../../store";
import { FoodBeverageItems } from "../../../../../store/foodAndBeverage/type";
import { IMAGE_URL_CONFIG, URLS } from "../../../../../constants/url";
import "./orderSummary.scss";
import FoodItemSummary from "../../../../../components/TicketBooking/FoodItemSummary";
import {
  IFormatedTicketType,
  MODAL_TYPE,
  TICKET_FLOW_TYPES,
} from "../../../../../models/tickets";
import { TICKET_FLOW } from "../../../../../constants/ticket";
import { cancelOnlyTicketAction, setModal, updateSession } from "../../../../../store/ticketBooking/action";
import moment from "moment";
import {
  concessionPriceCalculation,
  generateUsersessionId,
} from "../../../../../utils/tickets";
import { addTicketApi } from "../../../../../services/films";
import { toast } from "react-toastify";
import { ordersApi } from "../../../../../services/concessions";
import MovieDescription from "../../../../../components/TicketBooking/MovieDescription";
import { useLocation } from "react-router";
import HelmetTitle from "../../../../../components/Helmet";
import { memberValidationApi } from "../../../../../services/auth";
import { memberValidationSuccess } from "../../../../../store/auth/action";

interface IOrderSummaryProps {}

const OrderSummary: FC<IOrderSummaryProps> = () => {
  const {
    countryId,
    brandId,
    modalType,
    prevModal,
    nextModal,
    ticketDetails,
    orderCartData,
    hasGL,
    ticketLessFb,
    isFutureFb,
    preOrderFb,
    currentCinema,
    films,
    isLoggedIn,
    userDetails,
    seatSwapDetails,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    brandId: state.applicationReducer.brandId,
    modalType: state.ticketBookingReducer.modal.type,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    orderCartData: state.foodBeverageReducer.orderCartData,
    currentCinema: state.applicationReducer.currentCinema,
    hasGL: state.ticketBookingReducer.hasGL,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    isFutureFb: state.ticketBookingReducer.isFutureFb,
    preOrderFb: state.ticketBookingReducer.preOrderFb,
    films: state.ticketBookingReducer.films,
    isLoggedIn: state.authReducer.isLoggedIn,
    userDetails: state.authReducer.userDetails,
    seatSwapDetails: state.ticketBookingReducer.seatSwapDetails,
  }));

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [showPreOrders, setShowPreOrders] = useState(false);

  useEffect(() => {
    dispatch(setReinitializeScroll(true));
  }, []);

  const onClickShowOrders = () => {
    setShowPreOrders(!showPreOrders);
  };

  const updateMemberdetails = async (userSessionId: string) => {
    if (
      isLoggedIn &&
      !ticketLessFb &&
      userDetails?.cardNumber
    ) {
      const memberResponse: any = await memberValidationApi({
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: userSessionId,
        cardNumber: userDetails?.cardNumber,
        countryId: countryId,
      });

      if (memberResponse.status && memberResponse.response.data && memberResponse.response.data.data) {
        dispatch(memberValidationSuccess(memberResponse.response.data.data));
      }
    }
  }

  const onEditMovie = () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const key = Object.keys(TICKET_FLOW[ticketType]).shift()! as MODAL_TYPE;
    const modelType = TICKET_FLOW[ticketType][key]?.modal as MODAL_TYPE;
    const prev: MODAL_TYPE = modelType ? modelType : prevModal;
    const modal = TICKET_FLOW[ticketType][prev];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
    if (ticketDetails && ticketDetails.userSessionId) {
      dispatch(
        cancelOnlyTicketAction({
          countryId: countryId,
          requestType: "cancelOrder",
          userSessionId: ticketDetails.userSessionId,
        })
      );
      const userSessionId = generateUsersessionId(currentCinema?.slug!);
      dispatch(updateSession(userSessionId));
      updateMemberdetails(userSessionId);
    }
  };

  const backToEdit = () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const prev: MODAL_TYPE = prevModal;
    const modal = TICKET_FLOW[ticketType][prev];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
  };

  useEffect(() => {
    if (orderCartData.length === 0 && ticketLessFb) {
      backToEdit();
    }
  }, [orderCartData])

  const total = useMemo(() => {
    const concession = concessionPriceCalculation(orderCartData);
    return ((ticketLessFb ? 0 : ticketDetails.total) + concession).toFixed(2);
  }, [ticketDetails, orderCartData]);

  const callOrderApi = async (state: string) => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][next];
    if (modal) {
      modal.data = state;
    }

    if (orderCartData.length > 0) {
      const {
        data: { success },
      } = await ordersApi({
        cinemaId: currentCinema.slug,
        concessions: orderCartData,
        userSessionId: ticketDetails?.userSessionId,
        countryId: countryId,
        ticketlessFB: ticketLessFb,
        hasGL: hasGL,
      });
      if (success) {
        dispatch(
          setModal({
            ...modal,
            type: modalType,
          })
        );
      } else {
        toast.error("Failed! Issue in F&B");
      }
    } else {
      dispatch(
        setModal({
          ...modal,
          type: modalType,
        })
      );
    }
  };

  const addTicketActionApi = async (state: string) => {
    const ticketResponse = await addTicketApi({
      cinemaId: currentCinema.slug,
      cinemaName: currentCinema.cinemaName,
      userSessionId: ticketDetails?.userSessionId,
      countryId: countryId,
      requestType: "addFoodOrder",
      transactionAmount: total,
      isMobile: 0,
      userAgent: navigator.userAgent,
    });
    const {
      data: { data, status },
    } = ticketResponse;
    if (status === 200) {
      callOrderApi(state);
    }
  };

  const onNext = async (state: string) => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal =
      TICKET_FLOW[ticketType][isLoggedIn ? MODAL_TYPE.PAYMENT : next];
    if (modal) {
      modal.data = state;
    }

    if (orderCartData && orderCartData.length >= 0) {
      !ticketLessFb ? callOrderApi(state) : addTicketActionApi(state);
    } else {
      dispatch(
        setModal({
          ...modal,
          type: modalType,
        })
      );
    }
  };

  const onPrev = () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const prev: MODAL_TYPE = prevModal;
    const modal = TICKET_FLOW[ticketType][prev];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
  };

  const Footer = () => {
    return (
      <div
        className={`movie_footer_btn_el col-md-12 ml-auto ${
          isLoggedIn ? "single_btn" : ""
        }`}
      >
        <div className="movie_button_wrap">
        {isLoggedIn?<button type="button" className="btn gray_btn" onClick={backToEdit}>BACK</button>:null}
          <button
            type="button"
            className="btn black_btn"
            onClick={() => onNext("guest")}
          >
            {isLoggedIn ? "CONTINUE TO PAY" : "CONTINUE AS GUEST"}
          </button>
          {!isLoggedIn ? (
            <button
              type="button"
              className="btn red_btn"
              onClick={() => onNext("login")}
            >
              LOGIN & PAY
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  const isSeatSwap = useMemo(() => {
    return pathname.includes("/seat-swap/") && seatSwapDetails ? true : false;
  }, [pathname, seatSwapDetails]);

  const Banner = () => {
    return (
      <div className="col-12 col-md-12 pl-lg-0 ">
        <div className="swap-banner">
          <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}bell_icon.svg`} />
          <p>
            Refund for your previous purchase will be actioned once payment for
            your new order has been completed.
          </p>
        </div>
      </div>
    );
  };

  const totalTicketQty = useMemo(() => {
    if (ticketDetails?.ticketTypes) {
      return ticketDetails?.ticketTypes.reduce((acc: number, v: IFormatedTicketType) => {
        acc = acc + v.qty
        return acc;
      }, 0)
    } else {
      return 0
    }
  }, [ticketDetails])

  return orderCartData.length > 0 || ticketLessFb ? (
    <>
      <HelmetTitle title="Order Summary" description="Order Summary"/>
      <div className="row food_order_summary_wrap mobile_modal_pad">
        <div className="col-12 col-md-12 pl-lg-0">
          <a
            onClick={backToEdit}
            className="chevron_left_black"
          >
            Back
          </a>
        </div>
        {isSeatSwap ? <Banner /> : null}
        <div className="col-12 col-md-12 pl-lg-0">
          <div className="custom_scroll_content vscroll_600">
            {ticketLessFb && isFutureFb && preOrderFb.length > 0 ? (
              <div className="mb-2 preorder-concession-wrap">
                <div className="purchase-dropdown" onClick={onClickShowOrders}>
                  <span>PRE-PURCHASED ORDERS</span>
                  <span
                    className={`caret ${showPreOrders ? "caret-rotate" : ""}`}
                  >
                    &nbsp;
                  </span>
                </div>
                {showPreOrders ? (
                  <div className="mb-4">
                    {/* <app-pre-purchased-list fromPage="orderSummary" [concessions]="prePurchasedConcessions"></app-pre-purchased-list> */}
                  </div>
                ) : null}
                <div className="new-orders">New Orders</div>
              </div>
            ) : null}
            <ul className="list_wrap">
              {!ticketLessFb ? (
                <li className="movie_list">
                  <div className="media_wrap">
                    <div className="media_content">
                      <div className="thumb_img">
                        <img
                          src={
                            films?.image != null && films?.image != ""
                              ? `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${films?.image}${IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT}`
                              : URLS.ERROR_IMAGE
                          }
                          alt=""
                        />
                      </div>
                      <div className="list_details">
                        <p className="title">{films?.movieName}</p>
                        <p className="item_list">
                          {" "}
                          {films
                            ? moment
                                .parseZone(films?.showDateTime)
                                .format("hh:mm A")
                            : ""}{" "}
                          -{" "}
                          {films
                            ? moment
                                .parseZone(films?.showDateTime)
                                .format("dddd DD MMMM")
                            : ""}{" "}
                        </p>
                        <p className="item_list">
                          {films.cinemaName} -{" "}
                          {modalType === TICKET_FLOW_TYPES.NO_OF_SEAT ||
                          TICKET_FLOW_TYPES.NO_OF_SEAT_FB
                            ? ticketDetails?.ticketTypes?.reduce(
                                (acc: number, v: IFormatedTicketType) =>
                                  acc + v.qty!,
                                0
                              )
                            : ticketDetails?.selectedSeats?.length}{" "}
                          x Seats
                        </p>
                        <p className="item_list mobile_show">
                          ${Number(ticketDetails?.total!).toFixed(2)}
                        </p>
                        {/* <p className="desc-label mobile_show">Price includes a booking fee of ${(ticketDetails?.bookingFees/totalTicketQty).toFixed(2)} per ticket</p> */}
                      </div>
                    </div>
                    <div className="edit_btn" onClick={onEditMovie}>
                      &nbsp;
                    </div>
                  </div>
                  <div className="action_wrap mobile_hide">
                    <div className="w-50"></div>
                    <div className="price_wrap">
                      <p className="item_list">
                        ${ticketDetails.total.toFixed(2)}
                      </p>
                      {/* <p className="desc-label">Price includes a booking fee of ${(ticketDetails?.bookingFees/totalTicketQty).toFixed(2)} per ticket</p> */}
                    </div>
                  </div>
                </li>
              ) : null}
              {orderCartData.length > 0 ? (
                <div className="scroll_ordersummary">
                  {orderCartData.map(
                    (foodItems: FoodBeverageItems, i: number) => (
                      <FoodItemSummary foodItems={foodItems} index={i} />
                    )
                  )}
                </div>
              ) : null}
            </ul>
          </div>
        </div>

        <div className="col-md-12 pl-lg-0 mt-4">
          <div className="row">
            <div className="col-12 user_coloum col-md-6">
              <div>
                <div className="user-details">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="total">
                        {isSeatSwap ? "NEW TOTAL" : "TOTAL ORDER"}
                      </div>
                      <div className="total-amount">${total}</div>
                      {ticketDetails?.ticketTypes?.map((v: any) =>
                        v.qty > 0 ? (
                          <div>
                            <div className="total-det">
                              {v.qty} x {v.Description} SEATS: $
                              {(v.qty * v.price).toFixed(2)}
                            </div>
                          </div>
                        ) : null
                      )}
                      {!ticketLessFb ? (
                        <div className="total-det">
                          <span>
                            {totalTicketQty} x BOOKING FEE : $
                            {ticketDetails?.bookingFees.toFixed(2)}
                          </span>
                        </div>
                      ) : null}
                      {orderCartData?.map((foodItems: any) => (
                        <div>
                          <div className="total-det">
                            {foodItems.quantity} x {foodItems.description} :{" $"}
                            {foodItems.totalPrice}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="row order_summary_wrap mobile_modal_pad">
      <MovieDescription />
      <div className="col-lg-8">
        <div className="form_page">
          <a
            onClick={backToEdit}
            className="chevron_left_black"
          >
            Back
          </a>
          {isSeatSwap ? <Banner /> : null}
          <div>
            <table className="movie_details">
              <tbody>
                <tr>
                  <th scope="row">Movie</th>
                  <td>{films.movieName}</td>
                </tr>
                <tr>
                  <th scope="row">Date</th>
                  <td>
                    {films
                      ? moment
                        .parseZone(films?.showDateTime)
                        .format("dddd, MMMM DD, YYYY")
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Session</th>
                  <td>
                    {" "}
                    {films
                      ? moment.parseZone(films?.showDateTime).format("hh:mm A")
                      : ""}{" "}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Location</th>
                  <td>{films.cinemaName}</td>
                </tr>
                {ticketDetails?.selectedSeats?.length > 0 ? (
                  <tr>
                    <th scope="row">Seats</th>
                    <td>
                      {ticketDetails?.selectedSeats
                        .map((v: any) => v.SeatId)
                        .join(",")}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="col-12 user_coloum col-md-6">
            <div>
              {isLoggedIn ? (
                <>
                  <div className="welcome">Welcome back</div>
                  <div className="my-account text-capti font-size-16">
                    {userDetails?.firstName}
                  </div>
                </>
              ) : null}
              <div className="user-details">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="total">
                      {isSeatSwap ? "NEW TOTAL" : "TOTAL ORDER"}
                    </div>
                    <div className="total-amount">${total}</div>
                    {ticketDetails?.ticketTypes?.map((v: any) =>
                      v.qty > 0 ? (
                        <div>
                          <div className="total-det">
                            {v.qty} x {v.Description} SEATS: $
                            {(v.qty * v.price).toFixed(2)}
                          </div>
                        </div>
                      ) : null
                    )}
                    <div className="total-det">
                      <span>
                      {totalTicketQty} x BOOKING FEE : ${ticketDetails?.bookingFees.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
