import { FC, useEffect, useState } from "react";
import './cancelMembership.scss'

interface ICancelMembershipProps {
    onCloseModal: any;
}

const CancelMembership: FC<ICancelMembershipProps> = ({ onCloseModal }) => {


    const downgrade = () => {

    }

    return (
        <div className="cancel-membership-wrapper">
            <div className="col-12">
                <div className="content">
                    <div className="header">
                        <h2>Are you sure you want to cancel your Angelika Rewards Gold Membership?</h2>
                    </div>
                    <div className="body">
                        <p>Your membership will downgrade to a regular Club Membership if you cancel your Gold Membership.</p>
                        <div className={`movie_footer_btn_el col-md-12 ml-auto px-5`}>
                            <div className="movie_button_wrap justify-content-end">
                                <button
                                    type="button"
                                    className="btn gray_btn"
                                    onClick={onCloseModal}
                                > KEEP MY GOLD MEMBERSHIP
                                </button>
                                <button
                                    type="button"
                                    className="btn black_btn"
                                    onClick={downgrade}
                                >
                                    DOWNGRADE TO REGULAR CLUB MEMBERSHIP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default CancelMembership;
