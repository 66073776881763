import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { API_REQUEST_TYPE, URLS } from "../../../../constants/url";
import { walletTokenApi } from "../../../../services/auth";
import { ISavedCards } from "../../../../models/auth";
import {
  getSavedCardWatcher,
  showManageCardModal,
  showUpdateProfileModal,
} from "../../../../store/auth/action";
import UpdateProfile from "../UpdateProfile";
import ManageCards from "../ManageCards";
import { toast } from "react-toastify";
import UpgradeGoldBtn from "../../../Auth/Membership/UpgradeGoldBtn";
import { COUNTRY, MEMBERSHIP_TYPE } from "../../../../constants/app";
import { formatLoyaltyPayload, formatMembershipType } from "../../../../utils/rewards";

interface IMyAccountProps {}

const MyAccount: FC<IMyAccountProps> = () => {
  const { countryId, userDetails, memberDetail, savedCards, isLoggedIn } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
      savedCards: state.authReducer.savedCards,
      isLoggedIn: state.authReducer.isLoggedIn,
    }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails) {
      const saveCardData = {
        countryId: `${countryId}`,
        emailId: userDetails?.email,
        memberRefId: userDetails?.result?.memberRefId,
      };
      dispatch(getSavedCardWatcher(saveCardData));
    }
  }, [userDetails]);

  const googleWalletClick = async () => {
    if (userDetails && userDetails?.cardNumber && userDetails.token) {
      window.open(URLS.GOOGLE_WALLET + userDetails.token, "_blank");
    } else {
      const {
        status,
        data: { data, statusCode },
      } = await walletTokenApi(formatLoyaltyPayload(userDetails!, countryId, memberDetail!, 'google'));
      if (status === 200 && statusCode === 200 && data?.token) {
        window.open(URLS.GOOGLE_WALLET + data?.token, "_blank");
      }
    }
  };

  const appleWalletClick = async () => {
    const {
      status,
      data: { data, statusCode },
    } = await walletTokenApi(formatLoyaltyPayload(userDetails!, countryId, memberDetail!, 'apple'));
      if (status === 200 && statusCode === 200 && data?.token) {
        window.open(URLS.APPLE_WALLET + data?.token, "_blank");
      }
    // }
  };

  const onClickEdit = () => {
    dispatch(showUpdateProfileModal(true));
  };

  const onClickManageCards = () => {
    dispatch(showManageCardModal(true));
  };

  const membershipType = useMemo(()=>{
    return formatMembershipType(memberDetail!);
  },[memberDetail])

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="account-details-card ">
            <div className="row">
              <div className="col-12 col-md-12 section_divided">
                <div className="title">
                  My Account
                  <span className="float-right edit mt-1" onClick={onClickEdit}>
                    EDIT <span className="chevron_right"></span>
                  </span>
                </div>
                <div className="user-name">
                  {userDetails?.firstName} {userDetails?.lastName}
                </div>
                <div className="user-email">{userDetails?.email}</div>
                <div className="user-email">
                  {userDetails?.phoneNumber}
                  <div>
                    <span className="float-right"></span>
                    {memberDetail?.LoyaltyMember?.City}&nbsp;
                    {memberDetail?.LoyaltyMember?.Suburb ? (
                      <span className="float-right">
                        {memberDetail?.LoyaltyMember?.Suburb},
                      </span>
                    ) : null}
                    {userDetails?.zipCode ? (
                      <span className="float-right">
                        {userDetails?.zipCode}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="member_wallet">
                  <div onClick={googleWalletClick} className="g_pay">
                    <img
                      src={URLS.GOOGLE_WALLET_SIDEBAR_IMAGE}
                      className="mCS_img_loaded"
                    />
                  </div>
                  <div onClick={appleWalletClick} className="g_pay">
                    <img
                      src={URLS.APPLE_WALLET_SIDEBAR_IMAGE}
                      className="mCS_img_loaded"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="payment-detail">
                  Default Payment Method
                  <span
                    className="float-right edit"
                    onClick={onClickManageCards}
                  >
                    EDIT <span className="chevron_right"></span>
                  </span>
                </div>
                {savedCards.map((cards: ISavedCards) => (
                  <div>
                    {cards?.defaultCard === 1 ? (
                      <div>
                        <div className="user-name">{cards?.cardName}</div>
                        <div className="user-name">
                          **** **** **** **** {(cards?.cardNumber).slice(-3)}
                        </div>
                        <div className="user-name">
                          {cards?.expiryDate.slice(0, 2)}/
                          {cards?.expiryDate.slice(-2)}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              {countryId === COUNTRY.ANG && isLoggedIn && membershipType === MEMBERSHIP_TYPE.CLUB? (
                <div>
                  <div className="d-flex justify-content-center mt-4">
                    <UpgradeGoldBtn fromPage="sideMenu" />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <UpdateProfile />
      <ManageCards />
    </>
  );
};

export default MyAccount;
