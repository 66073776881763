import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "../../../../../components/UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { IMAGE_URL_CONFIG, URLS } from "../../../../../constants/url";
import Member from "../../../../../components/Auth/Member";
import SignUp from "../../../../../components/Auth/SignUp";
import "./auth.scss";
import { toggleTicketModal } from "../../../../../store/ticketBooking/action";
import { showLogin } from "../../../../../store/auth/action";
import { BRANDS, COUNTRY } from "../../../../../constants/app";

interface IAuthProps {
  onCloseModal: () => void;
}

const MembershipAuth: FC<IAuthProps> = ({ onCloseModal }) => {
  const { countryId, openModal, isLoggedIn, brandId, modal, membershipPurchase } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      brandId: state.applicationReducer.brandId,
      openModal: state.authReducer.openModal,
      isLoggedIn: state.authReducer.isLoggedIn,
      modal: state.ticketBookingReducer.modal,
      membershipPurchase: state.authReducer.membershipPurchase,
    })
  );

  const dispatch = useDispatch();
  const [tab, setTab] = useState("sign-in");

  useEffect(() => {
    setTab("sign-in");
  }, [openModal]);

  const changeTab = (type: string) => {
    setTab(type);
  };

  const membershipTitle = useMemo(() => {
    if (countryId === COUNTRY.ANG) {
        return membershipPurchase ? 'Gold' : 'Club';
    } else if (countryId === COUNTRY.STA) {
        return membershipPurchase ? 'Boost' : 'Basic';
    } else {
        return '';
    }
}, [countryId, membershipPurchase])

const content = useMemo(() => {
    switch (membershipTitle) {
        case 'Gold':
            return {title:'Gold', header:'Gold', logo:'ang_gold_logo_head.svg'};
        case 'Club':
            return  {title:'Club', header:'Club', logo:'ang_club_logo_head.svg'};
        case 'Boost':
            return  {title:'Boost', header:'Boost', logo:'state_rewards_boost.svg'};
        case 'Basic':
            return  {title:'', header:'', logo:'state_rewards_base.svg'};
        default:
            return {title:'', header:'', logo:''};
    }
}, [membershipTitle])


  return (
    <div className="row membership-auth-section">
  
  <div className='card-section'>
                <div className={`card_box ${membershipTitle}`}>
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + content.logo} />
                </div>
                <div className="info">
                    <h4 className="title">{membershipPurchase && membershipPurchase.loyaltyPackage?membershipPurchase.loyaltyPackage.description:`Angelika Rewards ${content.title}`}</h4>
                    {membershipPurchase && membershipPurchase.loyaltyPackage?<p className="price-info">Annual membership subscription fee: <span className="price">${(membershipPurchase.loyaltyPackage.priceInCents/100).toFixed(2)}</span></p>:null}
                </div>
            </div>
      <div className="my-4">
        <SignUp fromPage={modal?.data ? modal.data : 'membership'} />
      </div>
    </div>

  );
};

export default MembershipAuth;
