import React, { FC, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../utils/validatorSchema";
import TextInput from "../../UI/TextInput";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";
import { TICKET_FLOW } from "../../../constants/ticket";
import { setModal, toggleTicketModal } from "../../../store/ticketBooking/action";
import {
  forgotPasswordWatcher,
  memberValidationWatcher,
  showLogin,
  signInWatcher,
} from "../../../store/auth/action";
import { openSliderAction } from "../../../store/application/action";
import { BRANDS, COUNTRY } from "../../../constants/app";
import HelmetTitle from "../../Helmet";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../constants/path";
import "./member.scss";

interface IMemberProps {
  fromPage: string;
  backHandler?: () => void;
}

const Member: FC<IMemberProps> = ({ fromPage, backHandler }) => {
  const {
    countryId,
    modalType,
    nextModal,
    ticketDetails,
    userDetails,
    prevModal,
    brandId,
    ticketLessFb
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    modalType: state.ticketBookingReducer.modal.type,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    userDetails: state.authReducer.userDetails,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    brandId: state.applicationReducer.brandId,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb
  }));

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formType, setFormType] = useState("login");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema:
      formType === "forgotPassword"
        ? createValidationSchema("forgotPassword")
        : createValidationSchema("login"),
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setErrorMessage("");
      const { email, password } = values;

      if (formType === "forgotPassword") {
        const payload = {
          countryId: countryId,
          email: email,
        };
        dispatch(
          forgotPasswordWatcher(
            payload,
            (res: any) => {
              resetForm();
              setSuccessMessage(res);
            },
            (err: any) => {
              setErrorMessage(err);
            }
          )
        );
      } else {
        const payload = {
          countryId: countryId,
          email: email,
          password: password,
          saleId: ticketDetails?.userSessionId,
          ticketlessFB: ticketLessFb
        };

        dispatch(
          signInWatcher(
            payload,
            (res: any) => {
              if (fromPage === "ticket" || fromPage === "membership" || fromPage === "futureFb") {
                const ticketType: TICKET_FLOW_TYPES = modalType;
                const next: MODAL_TYPE = nextModal;
                const modal = TICKET_FLOW[ticketType][next];

                dispatch(
                  setModal({
                    ...modal,
                    type: modalType,
                  })
                );
              }
              const payload = {
                MemberRefId: res?.vista_memberid,
                memberId: res?.Id,
                emailId: res?.Email,
                UserSessionId:
                  fromPage === "account" || fromPage === "membership"
                    ? res?.result?.cardNumber
                    : ticketDetails?.userSessionId,
                cardNumber: res?.result?.cardNumber,
                countryId: countryId,
              };

              dispatch(
                memberValidationWatcher(
                  payload,
                  (res: any) => {
                    if (fromPage === "ticket" || fromPage === "membership" || fromPage === "futureFb") {
                      const ticketType: TICKET_FLOW_TYPES = modalType;
                      const next: MODAL_TYPE = nextModal;
                      const modal = TICKET_FLOW[ticketType][next];

                      dispatch(
                        setModal({
                          ...modal,
                          type: modalType,
                        })
                      );
                    } else if(fromPage === 'membershipRenewal'){
                      navigate(`/${ROUTES.REWARDS}/renewal`);
                    }else if(fromPage === 'membershipSummary'){
                      navigate(`/${ROUTES.REWARDS}/${ROUTES.SUMMARY}`);
                    } else if(fromPage === 'membershipRenewalEdit'){
                      navigate(`/${ROUTES.REWARDS}/editRenewal`);
                    }else {
                      dispatch(showLogin(false));
                      dispatch(toggleTicketModal(false));
                        dispatch(
                          openSliderAction({ open: true, type: "account" })
                        );
                    }
                  },
                  (err: any) => {}
                )
              );
            },
            (err: any) => {
              setErrorMessage(err);
            }
          )
        );
      }
    },
  });

  const prev = async () => {
    if (fromPage === "futureFb") {
      if (backHandler) {
        backHandler();
      }
    } else {
      const ticketType: TICKET_FLOW_TYPES = modalType;
      const prev: MODAL_TYPE = prevModal;
      const modal = TICKET_FLOW[ticketType][prev];
      dispatch(
        setModal({
          ...modal,
          type: modalType,
        })
      );
    }
  };

  const onClickForgot = () => {
    setFormType(formType === "login" ? "forgotPassword" : "login");
    setSuccessMessage("");
    setErrorMessage("");
    resetForm();
  };

  const onCancel = () => {
    if (fromPage === "membership" || fromPage === "account") {
      if (backHandler) {
        backHandler();
      }
    } 
    dispatch(showLogin(false));
  };

  const classes = useMemo(() => {
    return fromPage === "ticket"
      ? "col-md-12"
      : "col-md-6";
  }, [fromPage, formType]);

  const showBackBtn = useMemo(() => {
    return fromPage === "ticket" || fromPage === "futureFb";
  }, [fromPage]);

  const showCancelBtn = useMemo(() => {
    return fromPage === "account" || fromPage === "membership";
  }, [fromPage]);

  const showForgot = useMemo(() => {
    return fromPage === "account" || fromPage === "membership";
  }, [fromPage]);

  return (
    <div className="login-form form_page">
      <HelmetTitle title="Sign-in" description="Sign-in"/>
      <div className="row">
        <div className={`col-12 is-invalid ${classes}`}>
          <label className="form-label">
            {formType === "login" ? "Email*" : "Enter Registered Email*"}
          </label>
          <div className="form-group pass_show">
            <TextInput
              value={values.email}
              field={"email"}
              handleChange={handleChange}
              touched={touched.email!}
              error={errors.email}
            />
          </div>
        </div>
        {formType === "login" ? (
          <div className={`col-12 is-invalid ${classes}`}>
            <label className="form-label">Password*</label>
            <div className="form-group pass_show">
              <TextInput
                type="password"
                value={values.password}
                field={"password"}
                handleChange={handleChange}
                touched={touched.password!}
                error={errors.password}
              />
            </div>
          </div>
        ) : null}
        {showForgot ? (
          <div className="col-md-12">
            <div className="text_link_wrap">
              <div className="custom_checkbox p-default form-group"></div>
              <div className="forget_pass" onClick={onClickForgot}>
                <a className="">
                  {formType === "login"
                    ? "Forgot Password?"
                    : "Back to sign-in?"}
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {errorMessage ? (
          <div className="err-message">{errorMessage}</div>
        ) : null}
        {successMessage ? (
          <div className="suc-message">{successMessage}</div>
        ) : null}
      </div>
      <div className="col-md-12 movie_footer_btn_el ml-auto">
        <div
          className={`movie_button_wrap ${
            fromPage === "futureFb" ? "justify-content-centr" : ""
          }`}
        >
          {showBackBtn ? (
            <button type="button" className="btn gray_btn" onClick={prev}>
              BACK
            </button>
          ) : null}
          {showCancelBtn ? (
            <button
              type="button"
              className="btn gray_btn mx-3"
              onClick={onCancel}
            >
              CANCEL
            </button>
          ) : null}
          <button
            type="button"
            className="btn black_btn"
            onClick={() => handleSubmit()}
          >
            {formType === "login" ? "LOGIN" : "SUBMIT"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Member;
