import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Slider from "../../../components/UI/Slider";
import { BRANDS, BRAND_ID, COUNTRY } from "../../../constants/app";
import { ROUTES } from "../../../constants/path";
import { API_REQUEST_TYPE, URLS } from "../../../constants/url";
import { RootState } from "../../../store";
import {
  getSlidesWatcher,
  setContentModal,
  setFooterType,
  toggleContentModal,
} from "../../../store/application/action";
import {
  getGiftCardWatcher,
  setGiftShopAction,
  setGiftShopTab,
} from "../../../store/giftshop/action";
import "./giftshop.scss";
import TextInput from "../../../components/UI/TextInput";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../utils/validatorSchema";
import moment from "moment";
import { checkGiftCardBalanceApi } from "../../../services/giftshop";
import { GIFT_CARD_ACTION, GIFT_CARD_TYPE } from "../../../models/giftcard";
import GiftShopFooter from "./Footer";
import HelmetTitle from "../../../components/Helmet";

interface IGiftShopProps {}

const GiftShop: FC<IGiftShopProps> = () => {
  const {
    slides,
    countryId,
    currentTab,
    brandId,
    currentCinema,
    currentAction,
  } = useSelector((state: RootState) => ({
    slides: state.applicationReducer.slides,
    countryId: state.applicationReducer.countryId,
    currentTab: state.giftCardReducer.currentTab,
    currentAction: state.giftCardReducer.currentAction,
    brandId: state.applicationReducer.brandId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  const [LINK, setLink] = useState<any>({
      eCards: `/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`,
      physicalCard: `/${ROUTES.GIFTSHOP}/${ROUTES.PHYSICAL_CARDS}`,
      experience: `/${ROUTES.GIFTSHOP}/${ROUTES.EXPERIENCE}`,
  });

  useEffect(() => {
    if (currentCinema) {
      setLink({
          eCards: `/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`,
          physicalCard: `/${ROUTES.GIFTSHOP}/${ROUTES.PHYSICAL_CARDS}`,
          experience: `/${ROUTES.GIFTSHOP}/${ROUTES.EXPERIENCE}`,
      });
    }
  }, [currentCinema]);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      dispatch(setGiftShopAction(GIFT_CARD_ACTION.LIST));
      switch (pathname) {
        case LINK.experience:
          dispatch(setGiftShopTab(GIFT_CARD_TYPE.EXPERIENCES_CARD));
          dispatch(setGiftShopAction(GIFT_CARD_ACTION.LIST));
          break;
        case LINK.physicalCard:
          dispatch(setGiftShopTab(GIFT_CARD_TYPE.PHYSICAL_CARD));
          dispatch(setGiftShopAction(GIFT_CARD_ACTION.LIST));
          break;
        default:
          dispatch(setGiftShopTab(GIFT_CARD_TYPE.E_GIFT_CARD));
      }
    }
  }, [pathname, brandId]);

  useEffect(() => {
    if (countryId && currentCinema) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.GIFT_SHOP,
          location: brandId === BRANDS.US? currentCinema?.slug:'',
        })
      );
      dispatch(setFooterType(10));
    }
  }, [countryId, currentCinema]);

  return (
    <section className={brandId === BRANDS.US ? "gift-us" : ""}>
      <HelmetTitle title="Gift cards" description="Gift cards"/>
      {/* Header */}
      {currentAction === GIFT_CARD_ACTION.LIST ? (
        <div>
          <div className={brandId === BRANDS.US ? "slider-filter" : "col"}>
            <Slider
              className={brandId === BRANDS.US ? "banner-slider-us" : ""}
              data={slides}
            />
          </div>
        </div>
      ) : null}
      {/* Body */}
      <div>
        <div className="banner-top">
          <div className="banner-top-inner">
            <h2>Choose an experience</h2>
            <p>
              <span>{countryId === COUNTRY.STA ? "A State Cinemas " : (countryId === COUNTRY.ANG ? "An Angelika Cinemas " : "A Reading Cinemas ")}</span>
              Gift Card is the perfect gift for anyone and any occasion.
              Gift cards can be used to book tickets and purchase snacks online
              or in person.
            </p>
            <p>
              For that last minute gift, e-Gift Cards are delivered to your
              inbox instantly!
            </p>
          </div>
        </div>

        <div className="gift-shop-tab">
          <div className="stickytabmenu">
            <div className="container">
              <ul className="nav" role="tablist">
                <li className="nav-item" role="tab">
                  <Link
                    to={LINK.eCards}
                    className={`nav-link ${
                      (pathname.includes(LINK.eCards) ||
                        pathname.includes(
                          `/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`
                        )) &&
                      !pathname.includes(LINK.physicalCard)
                        ? "active"
                        : ""
                    }`}
                  >
                    E-GIFT CARDS
                  </Link>
                </li>
                <li className="nav-item" role="tab">
                  <Link
                    to={LINK.physicalCard}
                    className={`nav-link ${
                      pathname.includes(LINK.physicalCard)
                        ? "active"
                        : ""
                    }`}
                  >
                    PHYSICAL GIFT CARDS
                  </Link>
                </li>
                {(countryId !== COUNTRY.ANG && countryId !== COUNTRY.US) ? (
                  <li className="nav-item" role="tab">
                    <Link
                      to={LINK.experience}
                      className={`nav-link ${
                        pathname.includes(LINK.experience) ||
                        pathname.includes(
                          `/${ROUTES.GIFTSHOP}/${ROUTES.EXPERIENCE}`
                        )
                          ? "active"
                          : ""
                      }`}
                    >
                      EXPERIENCES
                    </Link>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
      <GiftShopFooter page="giftshop"/>
    </section>
  );
};

export default GiftShop;
