import { FC, useState } from "react";
import { IMAGE_URL_CONFIG } from "../../../constants/url";
import './style.scss'

interface IState {
  freePurchase: () => void,
  paidPurchase: () => void,
}

const STATE_MEMBERSHIP_TABLE: FC<IState> = ({ freePurchase, paidPurchase }) => {

  const [selected, setSelected] = useState("basic")

  return <div className="membership-table">
    <div className="compare_section">
      <p className="header">Select a membership tier to see details.</p>
      <div className="state_card">
        <div className="card_wrapper">
          <div className={`basic ${selected === 'basic' ? 'active' : ''}`} onClick={() => setSelected('basic')}>
            <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'state_rewards_base.svg'} />
          </div>
          <div className={`divider ${selected === 'basic' ? 'active' : ''}`}></div>
        </div>
        <div className="card_wrapper">
          <div className={`boost ${selected === 'boost' ? 'active' : ''}`} onClick={() => setSelected('boost')}>
            <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'state_rewards_boost.svg'} />
          </div>
          <div className={`divider ${selected === 'boost' ? 'active' : ''}`}></div>
        </div>
      </div>
      <div className={`state_card_box`}>
        <div className={`card_box ${selected}`}>
          <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + (selected === 'boost' ? 'state_rewards_boost.svg' : 'state_rewards_base.svg')} />
        </div>
      </div>
      <p className="selected">Selected:</p>
      <p className="membership_type">{selected}</p>
    </div>
    <table className={`${selected}`}>
      <tr>
        <td className="black-tile"></td>
        <td className={`free-logo state`}><img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}state_rewards_base.svg`} /></td>
        <td className={`paid-logo state`}><img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}state_rewards_boost.svg`} /></td>
      </tr>
      <tr>
        <td className="desc">Pricing</td>
        <td className="content first">Free</td>
        <td className="content amount">$<span>20</span>yearly</td>
      </tr>
      <tr>
        <td className="desc">Points per dollar</td>
        <td className="content first">10</td>
        <td className="content first">20</td>
      </tr>
      <tr>
        <td className="desc">Weekly newsletter</td>
        <td className="content"> <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'success-Selected.png'} /></td>
        <td className="content"> <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'success-Selected.png'} /></td>
      </tr>
      <tr>
        <td className="desc">Exclusive competitions</td>
        <td className="content"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'success-Selected.png'} /></td>
        <td className="content"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'success-Selected.png'} /></td>
      </tr>
      <tr>
        <td className="desc">Special screenings</td>
        <td className="content"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'success-Selected.png'} /></td>
        <td className="content"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'success-Selected.png'} /></td>
      </tr>
      <tr>
        <td className="desc">Ticket discount</td>
        <td className="content"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'cross_circle.svg'} /></td>
        <td className="content offer"><span>15% off</span> <br />one ticket</td>
      </tr>
      <tr>
        <td className="desc">Food & beverage discount</td>
        <td className="content"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'cross_circle.svg'} /></td>
        <td className="content offer"><span>10% off</span></td>
      </tr>
      <tr>
        <td className="desc">How to attain</td>
        <td className="content last">Free online sign up</td>
        <td className="content last">$ 20 annual fee + sign up online</td>
      </tr>
      <tr className="footer-button">
        <td></td>
        <td className="join-btn">
          <button className={`btn basic-btn  state`} onClick={freePurchase}>JOIN NOW</button>
        </td>
        <td className="join-btn">
          <button className={`btn boost-btn state`} onClick={paidPurchase}>JOIN NOW</button>
        </td>
      </tr>
    </table>
    <div className="footer-button-mobile">
      <div className="footer-button-mobile-wrapper">
        {selected === 'basic' ? <button className={`btn basic-xl-btn  state`} onClick={freePurchase}>JOIN NOW</button> :
          <button className={`btn boost-xl-btn state`} onClick={paidPurchase}>JOIN NOW</button>}
      </div>
    </div>
  </div>
}

export default STATE_MEMBERSHIP_TABLE;