

import axios from 'axios';
import { ENDPOINT } from '../endpoint';

export const getFaqApi = (payload: { countryId: string }) => {
    return axios.post(ENDPOINT.FAQ, { countryId: payload.countryId }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const getDealsApi = (payload: { countryId: string }) => {
    return axios.get(ENDPOINT.DEALS, { params: { countryId: payload.countryId } }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const brandsApi = (payload: { countryId: string }) => {
    return axios.get(ENDPOINT.BRANDS, { params: { countryId: payload.countryId } });
}

export const employmentApi = (payload: { comments: string; contactNumber: string; countryId: any; cv: string; email: string; fileName: string; location: string; name: string; }) => {
    return axios.post(ENDPOINT.SUBMIT_EMPLOYMENTFORM, payload);
}

export const contactUsApi = (payload: any) => {
    return axios.post(ENDPOINT.CONTACT_US, payload);
}

export const ratingIndexApi = (countryId: string) => {
    return axios.get(ENDPOINT.RATING_INDEX, { params: { countryId } })
}

export const pageContentApi = (payload: any) => {
    return axios.get(ENDPOINT.PAGE_CONTENT, { params: payload });
}

export const groupBookingApi = (payload: any) => {
    return axios.post(ENDPOINT.GROUP_BOOKING, payload);
}

export const cinemaHireApi = (payload: any) => {
    return axios.post(ENDPOINT.CINEMA_HIRE, payload);
}

export const vipCinemasApi = (countryId: string) => {
    return axios.get(`${ENDPOINT.VIP_CINEMAS}/${countryId}`);
}

export const vipCinemaTypeApi = (location: string) => {
    return axios.get(`${ENDPOINT.VIP_CINEMA_TYPE}/${location}`);
}

export const vipCinemasFormApi = (payload: any) => {
    return axios.post(ENDPOINT.VIP_CINEMA_FORM, payload);
}

export const getActivitiesApi = (payload: { countryId: string }) => {
    return axios.get(ENDPOINT.ACTIVITIES, { params: { countryId: payload.countryId } }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const getHistroyApi = (payload: { countryId: string, pageName: string }) => {
    return axios.get(ENDPOINT.PAGE_CONTENT, { params: { countryId: payload.countryId, pageName: payload.pageName } }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const getReelClubApi = (payload: { countryId: string, pageName: string }) => {
    return axios.get(ENDPOINT.PAGE_CONTENT, { params: { countryId: payload.countryId, pageName: payload.pageName } }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const getAdvertisingApi = (payload: { countryId: string, pageName: string }) => {
    return axios.get(ENDPOINT.PAGE_CONTENT, { params: { countryId: payload.countryId, pageName: payload.pageName } }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const getMemberShipApi = (payload: { countryId: string, pageName: string }) => {
    return axios.get(ENDPOINT.PAGE_CONTENT, { params: { countryId: payload.countryId, pageName: payload.pageName } }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const getCafeAndBarApi = (payload: { countryId: string, isCafeBar: string }) => {
    return axios.get(ENDPOINT.SCREENCATEGORIES, { params: { countryId: payload.countryId, isCafeBar: payload.isCafeBar } }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const screenCategoryApi = (payload: { countryId: string, screenType: string}) => {
    return axios.get(ENDPOINT.SCREENCATEGORIES, { params: { countryId: payload.countryId, isCafeBar: '1', screenType: payload.screenType} }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const luxuryCinemasApi = (payload: { countryId: string, isCafeBar: number}) => {
    return axios.get(ENDPOINT.SCREENCATEGORIES, { params: payload })
}

export const getCompetitionsApi = (payload: { cinemaId: string}) => {
    return axios.get(ENDPOINT.COMPETITION, { params: { cinemaId: payload.cinemaId } }).then(response => response.data).catch(error => ({ status: false, error }))
}

export const saveCompetitionApi = (payload: any) => {
    return axios.post(ENDPOINT.COMPETITION, payload);
}

export const gamingHiresApi = (countryId: string) => {
    return axios.get(`${ENDPOINT.GAMING_HIRE}/${countryId}`);
}

export const gamingHireTypeApi = (location: string) => {
    return axios.get(`${ENDPOINT.GAMING_HIRE_TYPE}/${location}`);
}

export const gamingHireFormApi = (payload: any) => {
    return axios.post(ENDPOINT.GAMING_HIRE_FORM, payload);
}
