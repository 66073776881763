import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { getCompetitionsApi } from "../../../../services/sideMenus";
import { RootState } from "../../../../store";
import { getSlidesWatcher, setFooterType } from "../../../../store/application/action";
import "./competitions.scss";
import { current } from "@reduxjs/toolkit";
import CompetionQuestion from "../../../../components/Menu/SideMenu/Competition/competitionQuestion";
import HelmetTitle from "../../../../components/Helmet";

interface IFaqProps {}

const Competitions: FC<IFaqProps> = () => {
  const { countryId, slides, currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      slides: state.applicationReducer.slides,
      currentCinema: state.applicationReducer.currentCinema,
    })
  );

  const dispatch = useDispatch();
  const [competition, setCompetition] = useState([]);

  useEffect(() => {
    const getCompetition = async () => {
      const response = await getCompetitionsApi({
        cinemaId: currentCinema?.slug,
      });
      dispatch(setFooterType(response["data"]["competitions"].length));
      setCompetition(response["data"]["competitions"]);
    };
    if (currentCinema) {
      getCompetition();
    }
  }, [currentCinema]);

  useEffect(() => {
    if (countryId && currentCinema) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.COMPETITIONS,
        })
      );
    }
  }, [countryId,currentCinema]);

  return (
    <section className="mb-4 competition-wrapper">
      <HelmetTitle title="Competitions" description="Competitions"/>
      <Slider data={slides} />
      <div className="split_section_header">
        <div className="label">Competitions</div>
      </div>
      <div className="competition_result">
        {competition.length > 0 ? (
          <>
            {competition.map((v, i) => (
              <div className="list-content">
                <CompetionQuestion data={v} />
              </div>
            ))}
          </>
        ) : 
           <div className="err-noMovie">No competition available</div>
        }
      </div>
    </section>
  );
};

export default Competitions;
