import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Modal from "../../../UI/Modal";
import {
  logoutAction,
  showUpdateProfileModal,
} from "../../../../store/auth/action";
import { URLS } from "../../../../constants/url";
import "./updateProfile.scss";
import {
  COUNTRY,
  GENDER_LIST,
  GENDER_LIST_STA,
  GENRE_LIST,
  MONTH_LIST,
} from "../../../../constants/app";
import moment from "moment";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import TextInput from "../../../UI/TextInput";
import SelectDropdown from "../../../UI/DropDown/SelectDropDown";
import { dateArray, monthArray, yearArray } from "../../../../utils/helper";
import {
  changePasswordApi,
  getMemberCardlessApi,
  logOutApi,
  memberUpdateApi,
  memberValidationApi,
  updateMemberAttributeApi,
} from "../../../../services/auth";
import { memberValidationSuccess } from "../../../../store/auth/action";
import { setUserDetails } from "../../../../store/auth/action";
import { toast } from "react-toastify";
import { openSliderAction } from "../../../../store/application/action";

interface IUpdateProfileProps {}

const UpdateProfile: FC<IUpdateProfileProps> = () => {
  const { memberDetail, openUpdateProfileModal, userDetails, countryId } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      memberDetail: state.authReducer.memberDetail,
      userDetails: state.authReducer.userDetails,
      openUpdateProfileModal: state.authReducer.openUpdateProfileModal,
    }));

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [genderList, setGenderList] = useState<{ label: string; value: any }[]>(
    []
  );

  useEffect(() => {
    if ([COUNTRY.AUS, COUNTRY.NZ].includes(countryId)) {
      setGenderList(GENDER_LIST);
    } else if ([COUNTRY.STA, COUNTRY.ANG].includes(countryId)) {
      setGenderList(GENDER_LIST_STA);
    }
  }, [countryId]);

  const onCloseModal = () => {
    dispatch(showUpdateProfileModal(false));
  };

  const hasCard = useMemo(() => {
    return (userDetails?.cardNumber && userDetails?.vista_memberid) ? true : false;
  }, [userDetails]);

  const updateProfile = async (payload: any) => {
    const {
      response: {
        data: {
          data: { status, memberId },
          statusCode,
        },
      },
    } = (await updateMemberAttributeApi(payload)) as any;
    if (status === "true" && statusCode === 200) {
      let memberID = memberId;
      if (hasCard) {
        const memberFormValues = {
          MemberRefId: userDetails?.vista_memberid,
          countryId: countryId,
          firstName: values.firstName,
          lastName: values.lastName,
          email: userDetails?.email,
          updatedEmail: values.email,
          gender: values.gender,
          phoneNumber: values.phoneNumber,
          zipCode: values.postCode,
          dateOfBirth: `${values.year}-${values.month}-${values.day}`,

          suburb: values.suburb,
          cardNumber: userDetails?.cardNumber,
          memberId: memberId,
          preferredGenres: values.genres,
          weeklyNews: values.weeklyNews,
        };

        const {
          response: {
            data: {
              data: { ErrorDescription },
            },
          },
        } = (await memberUpdateApi(memberFormValues)) as any;
        if (ErrorDescription) {
          setErrorMessage(ErrorDescription);
        }

        const {
          response: {
            data: { data: memberdetails, statusCode: memberStatus },
          },
        } = (await memberValidationApi({
          countryId: countryId,
          MemberRefId: userDetails?.vista_memberid,
          cardNumber: userDetails?.cardNumber,
          UserSessionId: userDetails?.cardNumber,
        })) as any;

        dispatch(memberValidationSuccess(memberdetails));

        if (memberStatus === 200) {
          const {
            Email,
            FirstName,
            LastName,
            CardNumber,
            MemberId,
            ZipCode,
            Gender,
            MobilePhone,
            DateOfBirth,
            Weekly_news,
          } = memberdetails.LoyaltyMember;
          dispatch(
            setUserDetails({
              ...userDetails,
              countryId: countryId,
              email: Email,
              firstName: FirstName,
              id: memberID,
              lastName: LastName,
              phoneNumber: MobilePhone,
              cardNumber: CardNumber,
              weekly_news: Weekly_news,
              zipCode: ZipCode,
              dateOfBirth: DateOfBirth,
              gender: Gender,
              preferredGenres: values.genres.join(","),
            })
          );
        }
      } else {
        let {
          data: { statusCode, data },
        } = (await getMemberCardlessApi({
          memberRefId: memberID,
          emailId: values.email,
        })) as any;
        if (statusCode === 200) {
          const {
            Email,
            FirstName,
            LastName,
            CardNumber,
            Country_id,
            Theatre_id,
            Weekly_news,
            Online,
            vista_memberid,
            ZipCode,
            Gender,
            MobilePhone,
            DateOfBirth,
          } = data;
          dispatch(
            setUserDetails({
              ...userDetails,
              countryId: countryId,
              email: Email,
              firstName: FirstName,
              id: memberID,
              lastName: LastName,
              phoneNumber: MobilePhone,
              cardNumber: CardNumber,
              weekly_news: Weekly_news,
              theatre_id: Theatre_id,
              online: Online,
              vista_memberid: vista_memberid,
              zipCode: ZipCode,
              dateOfBirth: DateOfBirth,
              gender: Gender,
              preferredGenres: values.genres.join(","),
            })
          );
        }
      }

      if (values.newPassword) {
        toast.success(
          "Your profile and password has been updated successfully. Please login again"
        );
        const { status } = await logOutApi();
        if (status === 200) {
          dispatch(logoutAction({}));
          dispatch(showUpdateProfileModal(false));
        }
      } else {
        toast.success("Your profile has been updated successfuly!!!");
        dispatch(showUpdateProfileModal(false));
      }
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: createValidationSchema("updateProfile"),
      enableReinitialize: true,
      initialValues: {
        firstName: userDetails?.firstName || "",
        lastName: userDetails?.lastName || "",
        email: userDetails?.email || "",
        phoneNumber: userDetails?.phoneNumber
          ? userDetails?.phoneNumber.replace(/[^+\d]+/g, "").replace("+", "")
          : "",
        suburb: memberDetail?.LoyaltyMember?.Suburb || "",
        gender: userDetails?.gender || "",
        genres:
          typeof memberDetail?.LoyaltyMember?.PreferredGenres === "string" ||
          memberDetail?.LoyaltyMember?.PreferredGenres === null ||
          memberDetail?.LoyaltyMember?.PreferredGenres === undefined
            ? []
            : memberDetail?.LoyaltyMember?.PreferredGenres,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        postCode: userDetails?.zipCode || "",
        day: userDetails?.dateOfBirth
          ? moment(userDetails.dateOfBirth).format("DD")
          : "",
        month: userDetails?.dateOfBirth
          ? moment(userDetails.dateOfBirth).format("MM")
          : "",
        year: userDetails?.dateOfBirth
          ? moment(userDetails.dateOfBirth).format("YYYY")
          : "",
        weeklyNews: memberDetail?.LoyaltyMember?.SendNewsletter ? 1 : 0,
        countryId: countryId,
        isNotRequired: (countryId === COUNTRY.STA || countryId === COUNTRY.ANG)?true:false
      },
      onSubmit: async (values) => {
        const changePasswordData = {
          email: values.email,
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
        };
        const updateProfilePayload = {
          PreferredGenres: values.genres.join(","),
          countryId: countryId,
          dateOfBirth: `${values.year}-${values.month}-${values.day}`,
          email: values.email,
          firstName: values.firstName,
          gender: values.gender,
          id: userDetails?.id,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          suburb: values.suburb,
          updatedEmail: values.email,
          weeklyNews: values.weeklyNews,
          zipCode: values.postCode,
        };

        if (values.currentPassword && values.newPassword) {
          const {
            data: {
              data: { status },
              statusCode,
            },
          } = await changePasswordApi(changePasswordData);
          if (status && statusCode === 200) {
            updateProfile(updateProfilePayload);
          }
        } else {
          updateProfile(updateProfilePayload);
        }
      },
    });

  const onChangeWeeklyLetter = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("weeklyNews", checked ? 1 : 0);
  };

  const isManditory = useMemo(()=>{
    return countryId === COUNTRY.STA || countryId === COUNTRY.ANG? false: true
  },[countryId])

  const showField = useMemo(()=>{
    return countryId !== COUNTRY.STA && countryId !== COUNTRY.ANG? true: false
  },[countryId])

  return (
    <Modal
      visible={openUpdateProfileModal}
      className="update-profile-popup"
      showCloseBtn={false}
    >
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content">
            <button
              type="button"
              className="close-modal"
              onClick={onCloseModal}
            >
              <img
                className="close_icon"
                src={URLS.CLOSE_ICON}
                alt="close_icon"
                title="close_icon"
              />
            </button>
            <div className="modal-header">
              <div className="title title_append">UPDATE USER DETAILS</div>
            </div>
            <div className="modal-body">
              <div className="account_details_form form_page">
                <div className="row">
                  <div className="col-12 col-md-6 ">
                    <label className="form-label">First Name*</label>
                    <div className="form-group">
                      <TextInput
                        value={values.firstName}
                        field={"firstName"}
                        handleChange={handleChange}
                        touched={touched.firstName!}
                        error={errors.firstName}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="form-label">Last Name*</label>
                    <div className="form-group">
                      <TextInput
                        value={values.lastName}
                        field={"lastName"}
                        handleChange={handleChange}
                        touched={touched.lastName!}
                        error={errors.lastName}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="form-label">Gender{isManditory?'*':''}</label>
                    <div className="form-group">
                      <SelectDropdown
                        field={"gender"}
                        options={genderList}
                        setFieldValue={setFieldValue}
                        touched={touched.gender!}
                        error={errors.gender}
                        value={values.gender}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="form-label">Date of Birth*</label>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-4 col-md-4">
                          <SelectDropdown
                            field={"day"}
                            options={dateArray().map((v) => {
                              v.label = Math.round(+v.label).toString();
                              return v;
                            })}
                            setFieldValue={setFieldValue}
                            touched={touched.day!}
                            error={errors.day}
                            value={values.day}
                            isDisabled={!isManditory}
                          />
                        </div>
                        <div className="col-4 col-md-4">
                          <SelectDropdown
                            field={"month"}
                            options={MONTH_LIST}
                            setFieldValue={setFieldValue}
                            touched={touched.month!}
                            error={errors.month}
                            value={values.month}
                            isDisabled={!isManditory}
                          />
                        </div>
                        <div className="col-4 col-md-4">
                          <SelectDropdown
                            field={"year"}
                            options={yearArray()}
                            setFieldValue={setFieldValue}
                            touched={touched.year!}
                            error={errors.year}
                            value={values.year}
                            isDisabled={!isManditory}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="form-label">Mobile Phone*</label>
                    <div className="form-group position-relative">
                      <TextInput
                        value={values.phoneNumber}
                        field={"phoneNumber"}
                        handleChange={handleChange}
                        touched={touched.phoneNumber!}
                        error={errors.phoneNumber}
                      />
                    </div>
                  </div>
                  {showField?<div className="col-12 col-md-6">
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <label className="form-label">Post Code*</label>
                        <div className="form-group">
                          <TextInput
                            value={values.postCode}
                            field={"postCode"}
                            handleChange={handleChange}
                            touched={touched.postCode!}
                            error={errors.postCode}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-8">
                        <label className="form-label">Suburb*</label>
                        <div className="form-group">
                          <TextInput
                            value={values.suburb}
                            field={"suburb"}
                            handleChange={handleChange}
                            touched={touched.suburb!}
                            error={errors.suburb}
                          />
                        </div>
                      </div>
                    </div>
                  </div>:null}
                  <div className="col-12 col-md-6">
                    <label className="form-label">Preferred Genres{isManditory?'*':''}</label>
                    <div className="form-group">
                      <SelectDropdown
                        field={"genres"}
                        options={GENRE_LIST}
                        setFieldValue={setFieldValue}
                        touched={touched.genres!}
                        error={errors.genres}
                        value={values.genres}
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-md-12">
                <h3>Account Details</h3>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="form-label">Email Address*</label>
                  <div className="form-group">
                    <TextInput
                      value={values.email}
                      field={"email"}
                      handleChange={handleChange}
                      touched={touched.email!}
                      error={errors.email}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Current Password</label>
                  <div className="form-group">
                    <TextInput
                      type="password"
                      value={values.currentPassword}
                      field={"currentPassword"}
                      handleChange={handleChange}
                      touched={touched.currentPassword!}
                      error={errors.currentPassword}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">New Password</label>
                  <div className="form-group">
                    <TextInput
                      type="password"
                      value={values.newPassword}
                      field={"newPassword"}
                      handleChange={handleChange}
                      touched={touched.newPassword!}
                      error={errors.newPassword}
                    />
                    <span className="hint">Ex:Johnpeter@123</span>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Confirm Password</label>
                  <div className="form-group">
                    <TextInput
                      type="password"
                      value={values.confirmPassword}
                      field={"confirmPassword"}
                      handleChange={handleChange}
                      touched={touched.confirmPassword!}
                      error={errors.confirmPassword}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="custom_checkbox p-default form-group">
                    <input
                      type="checkbox"
                      value="1"
                      onClick={onChangeWeeklyLetter}
                      checked={values.weeklyNews === 1 ? true : false}
                    />
                    <div className="state p-primary">
                      <label className="form-label">
                        {countryId === COUNTRY.ANG
                          ? "Keep me updated with Angelika offers, special events and weekly news"
                          : countryId === COUNTRY.STA
                          ? `Keep me updated with Loyalty weekly news`
                          : `Keep me updated with Reel Club weekly news`}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="err-message">{errorMessage}</div>
              <div className="col-md-12 movie_footer_btn_el accounts_btn">
                <div className="movie_button_wrap">
                  <button
                    className="btn black_btn"
                    type="button"
                    onClick={() => handleSubmit()}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateProfile;
