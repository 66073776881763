import React, { FC, useEffect, useState } from "react";
import { luxuryCinemasApi } from "../../../../services/sideMenus";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import './luxuryCinemas.scss'
import { getSlidesWatcher } from "../../../../store/application/action";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import Slider from "../../../../components/UI/Slider";
import { IScreenCategory } from "../../../../models/sidemenu";
import { useNavigate } from "react-router";

interface ILuxuryCinemas { }

const LuxuryCinemas: FC<ILuxuryCinemas> = () => {

    const { countryId, currentCinema, slides } = useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId,
        currentCinema: state.applicationReducer.currentCinema,
        slides: state.applicationReducer.slides
    }))

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [screenCategories, setScreenCategories] = useState<IScreenCategory[]>([]);

    const getData = async () => {
        const { data } = await luxuryCinemasApi({
            countryId,
            isCafeBar: 0,
        });
        setScreenCategories(data);
    }

    useEffect(() => {
        if (countryId) {
            getData();
        }

    }, [countryId])

    useEffect(() => {
        if (countryId && currentCinema) {
            dispatch(getSlidesWatcher({
                countryId,
                pageName: API_REQUEST_TYPE.LUXURY_CINEMAS,
                location: currentCinema.slug
            }))
        }
    }, [countryId, currentCinema])

    const learnbtn = (category: string) => {
        navigate(`/luxury-cinemas/${category}`);
    }


    return <div className="luxury-cinemas-wrapper ">
        <div className="row">
            <div className="col-12 mobile_header_bg">
                <div className="mobile_header">
                    Luxury Cinemas
                </div>
            </div>
            <div className="col">
                <Slider data={slides} />
            </div>
            <div className="thumb_wrap">
                {screenCategories.map(screencat => <div className="list_thumb">
                    <div className="thumb_content_wrap">
                        <div className="thumb_content">
                            <div className="thumb_title">
                                {screencat.title}
                            </div>
                            <div className="description" dangerouslySetInnerHTML={{ __html: screencat.description1 ? (screencat.description1.slice(0,250)+(screencat.description1.length > 250?"...":'')) : "<p></p>" }}></div>
                            <div className="thumb_btn_wrap">
                                <a className="white_btn" onClick={() => learnbtn(screencat.screenType)}>
                                    LEARN MORE
                                </a>
                            </div>
                        </div>
                    </div> <div className="thumb_image">
                        <img
                            src={screencat.imageUrl}
                            alt={screencat.title}
                            title={screencat.title}
                        />
                        <div className="img_info">
                            <div className="thumb_title">
                                {screencat.title}
                            </div>
                            <div className="thumb_btn_wrap">
                                <a className="white_btn" onClick={() => learnbtn(screencat.screenType)}
                                > LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div >
        </div >
    </div >
}

export default LuxuryCinemas; 