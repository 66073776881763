import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { brandsApi } from "../../../../services/sideMenus";
import "./privacy.scss";
import HtmlRender from "../../../../components/UI/HtmlRender";
import HelmetTitle from "../../../../components/Helmet";

interface IPrivacyPolicyProps {
  type: string;
}

const PrivacyPolicy: FC<IPrivacyPolicyProps> = ({ type }) => {
  const { countryId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
  }));

  const [policy, setPolicy] = useState("");

  const title = useMemo(() => {
    switch (type) {
      case "Privacy Policy":
        return "Privacy Policy";
      case "Privacy Policy - Angelika":
        return "Privacy Policy";
      case "Privacy Policy US State":
        return "Your US State Privacy Rights";
      case "Privacy Policy Children":
        return "Children's Online Privacy Policy";
      case "Investors":
        return "Investors";
      case "Terms of Use":
        return "Terms of Use";
      case "Terms":
        return "Terms";
      case "Interest-Based Ads":
        return "Interest-Based Ads";
      case "Personal Information":
        return "Do Not Sell or Share My Personal Information";
      case "Etix Terms":
        return "Etix Terms";
      case "Reel Club Terms - Angelika":
        return "Angelika Rewards terms and conditions";
      case "Site Disclaimer":
        return "Site Disclaimer";
      case "Site Disclaimer - Angelika":
        return "Site Disclaimer";
      case "Gift Shop Terms":
        return "Gift Shop Terms"
      case "Reel Club Terms":
        return "Angelika Rewards terms and conditions"
      case "Group Booking Terms":
        return "Terms"
      default:
        return "Privacy Policy";
    }
  }, [type]);

  useEffect(() => {
    if (countryId) {
      const getPolicy = async () => {
        const { data } = await brandsApi({ countryId: countryId });
        if (data && data.length > 0) {
          const datas = data[0];
          if (datas && datas.data) {
            const { content } = datas.data.find((v: any) => v.title.toLowerCase() === type.toLowerCase());
            setPolicy(content ? content : "");
          }
        }
      };
      getPolicy();
    }
  }, [countryId]);

  return (
    <div className="privacy-policy">
      <HelmetTitle title={title} description={title} />
      <div className="modal-header">
        <div className="title title_append">{title}</div>
      </div>
      <div className="modal-body">
        <div className="modal-content-wrapper">
          <HtmlRender content={policy} />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
