import { FC, useEffect, useState } from "react";
import './editRenewal.scss'
import TextInput from "../../../../../components/UI/TextInput";
import SelectDropdown from "../../../../../components/UI/DropDown/SelectDropDown";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../../utils/validatorSchema";
import { IMAGE_URL_CONFIG } from "../../../../../constants/url";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../../../constants/path";
import { useDispatch, useSelector } from "react-redux";
import { getRenewalInfo, getSavedCardWatcher, memberValidationWatcher } from "../../../../../store/auth/action";
import { RootState } from "../../../../../store";
import { saveCardApi, updateRenewalTypeApi } from "../../../../../services/auth";
import { toast } from "react-toastify";
import { formatMembershipExpireDate, formatMembershipExpireDays } from "../../../../../utils/rewards";
import { ANG_MEMBERSHIP_EXPIRY_DAYS, MONTH_LIST } from "../../../../../constants/app";

interface IEditRenewalProps {
    onCloseModal: any;
}

const renewalOption = [
    { label: 'Automatic renewal', value: 'Automatic' },
    { label: 'Manual renewal', value: 'Manual' },
]

const EditRenewal: FC<IEditRenewalProps> = ({ onCloseModal }) => {


    const { countryId, userDetails, savedCards, memberDetail, renewalInfo } = useSelector(
        (state: RootState) => ({
            countryId: state.applicationReducer.countryId,
            userDetails: state.authReducer.userDetails,
            savedCards: state.authReducer.savedCards,
            memberDetail: state.authReducer.memberDetail,
            renewalInfo: state.authReducer.renewalInfo
        })
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState('renewal');
    const [renewalType, setRenewalType] = useState(renewalInfo?.subscriptionRenewalType ? renewalInfo?.subscriptionRenewalType : 'Manual');
    const [itemList, setItemList] = useState<
        Array<{
            value: string;
            label: string;
        }>
    >(MONTH_LIST);

    const yearList = new Array(21).fill(1).map((v: number, i: number) => {
        const year = new Date().getFullYear();
        return {
            value: year + i,
            label: year + i,
        };
    });


    const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
        useFormik({
            validationSchema: renewalType === 'Automatic' && !savedCards.length ? createValidationSchema("editRenewalWithCard") : createValidationSchema("editRenewal"),
            enableReinitialize: true,
            initialValues: {
                renewal: renewalInfo?.subscriptionRenewalType ? renewalInfo?.subscriptionRenewalType : 'Manual',
                cardNumber: "",
                cardHolderName: "",
                cvc: "",
                month: "",
                year: "",
            },
            onSubmit: async (values) => {

                if (values.renewal === 'Automatic' && !savedCards.length) {
                    let payload: any = {
                        countryId: countryId,
                        emailId: userDetails?.email!,
                        expirymonth: values.month,
                        expiryyear: values.year,
                        name: values.cardHolderName,
                        memberRefId: userDetails?.result?.memberRefId!,
                        number: values.cardNumber,
                        cvcnumber: values.cvc,
                    };
                    const {
                        data: { statusCode, data },
                    } = (await saveCardApi(payload)) as any;
                    if (!statusCode) {
                        toast.error(Array.isArray(data) && data.length > 0 ? data[0] : (data ? data : "Error in adding card."));
                        return;
                    }
                }

                const payload = {
                    memberRefId: userDetails?.id,
                    countryId,
                    emailId: userDetails?.email,
                    renewal: values.renewal
                }

                const { data: { statusCode, data } } = await updateRenewalTypeApi(payload);
                if (statusCode === 200 && data?.affectedRows > 0) {
                    dispatch(getRenewalInfo({
                        memberRefId: userDetails?.id,
                        countryId,
                        emailId: userDetails?.email,
                    }))
                    
                    if(formatMembershipExpireDays(memberDetail!) <= ANG_MEMBERSHIP_EXPIRY_DAYS && values.renewal === 'Automatic'){
                        toast.success("Renewal process has been initiated and will be reflected within the next 24 hours.")
                    }

                    if (values.renewal === 'Manual') {
                        onCloseModal();
                        return;
                    }
                    const payload = {
                        MemberRefId: userDetails?.vista_memberid,
                        UserSessionId: userDetails?.result?.cardNumber,
                        cardNumber: userDetails?.result?.cardNumber,
                        countryId: countryId,
                      };
                    dispatch(memberValidationWatcher(payload));
                    setPage('success');

                } else {
                    toast.error('An error occurred while updating. Please try again later.')
                }
            },
        });

    const getCards = () => {
        dispatch(
            getSavedCardWatcher({
                memberRefId: userDetails?.result?.memberRefId!,
                countryId: countryId,
                emailId: userDetails?.email!,
            })
        );
    }

    useEffect(() => {
        if (userDetails) {
            getCards();
        }
    }, [userDetails]);

    const onChangeRenewal = (type: string) => {
        setRenewalType(type);
    }

    const accountSetting = () => {
        onCloseModal()
        navigate(`/${ROUTES.REWARDS}/${ROUTES.PROFILE}`)
    }

    const backToSummary = () => {
        onCloseModal()
        navigate(`/${ROUTES.REWARDS}/${ROUTES.SUMMARY}`)
    }

    const goToSetting = () => {
        onCloseModal()
        navigate(`/${ROUTES.REWARDS}/${ROUTES.PROFILE}`)
    }

    return (
        <div className="edit-renewal-wrapper">
            <div className="col-12">
                <div className="content">
                    <div className="header">
                        <h2>Edit membership renewal process</h2>
                    </div>
                    {page === 'renewal' ? <div className="body">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <label className="form-label">Renewal Process</label>
                                <div className="form-group">
                                    <SelectDropdown
                                        field={"renewal"}
                                        options={renewalOption}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        onChange={onChangeRenewal}
                                        touched={touched.renewal!}
                                        error={errors.renewal}
                                        value={values.renewal}
                                    />
                                </div>
                            </div>
                        </div>
                        {values.renewal === 'Automatic' && !savedCards.length ? <div className="row">
                            <div className="card-details">
                                <div className="info-banner">
                                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'bell_icon.svg'} />
                                    Please add a credit card for automatic payment.
                                </div>
                                <div className="divider"></div>
                                <div className="row">
                                    <div className="col-12 col-md-6">

                                        <div className="col-12 col-md-12 mt-4">
                                            <label className="form-label">CARD HOLDER NAME</label>
                                            <div className="form-group">
                                                <TextInput
                                                    type="text"
                                                    value={values.cardHolderName}
                                                    field={"cardHolderName"}
                                                    handleChange={handleChange}
                                                    touched={touched.cardHolderName!}
                                                    error={errors.cardHolderName}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-8">
                                                <label className="form-label">CARD NUMBER</label>
                                                <div className="form-group">
                                                    <TextInput
                                                        type="text"
                                                        value={values.cardNumber}
                                                        field={"cardNumber"}
                                                        handleChange={handleChange}
                                                        touched={touched.cardNumber!}
                                                        error={errors.cardNumber}
                                                        maxLength={19}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4 col-md-4">
                                                <label className="form-label">CVC</label>
                                                <div className="form-group cvc_input">
                                                    <TextInput
                                                        type="password"
                                                        value={values.cvc}
                                                        field={"cvc"}
                                                        handleChange={handleChange}
                                                        touched={touched.cvc!}
                                                        error={errors.cvc}
                                                        maxLength={3}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-8 col-md-8">
                                                <label className="form-label">EXPIRY</label>
                                                <div className=" form-group">
                                                    <SelectDropdown
                                                        placeholder="Month"
                                                        field={"month"}
                                                        options={itemList}
                                                        setFieldValue={setFieldValue}
                                                        touched={touched.month!}
                                                        error={errors.month}
                                                        value={values.month}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4 col-md-4">
                                                <label className="form-label empty"></label>
                                                <SelectDropdown
                                                    placeholder="Year"
                                                    field={"year"}
                                                    options={yearList}
                                                    setFieldValue={setFieldValue}
                                                    touched={touched.year!}
                                                    error={errors.year}
                                                    value={values.year}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="notification">
                                            <p><span>Note:</span> This credit card will be saved as your default credit card for payment.</p>
                                            <p>Your can edit your saved credit card details in your <span>account settings</span> at any time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                        <div className={`movie_footer_btn_el col-md-12 ml-auto px-5`}>
                            <div className="movie_button_wrap justify-content-end">
                                <button
                                    type="button"
                                    className="btn gray_btn"
                                    onClick={onCloseModal}
                                > CANCEL
                                </button>
                                <button
                                    type="button"
                                    className="btn green_btn "
                                    onClick={() => handleSubmit()}
                                ><img src={
                                    IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                    "green-tick.svg"
                                } alt="" />
                                    <span className="ml-2">SAVE CHANGES</span>
                                </button>
                            </div>
                        </div>
                    </div> : page === 'info' ?
                        <div className="body">
                            <div className="renewal-info">
                                <img className="card-img" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'card-red.svg'} />
                                <p className="text-red">Please set up a credit card for automatic payment.</p>
                                <p className="desc">You will need to go to your <span className="bold">account settings</span> to add a default credit card for automatic payment.</p>
                                <p className="desc">Once you have a default credit card set up, please return to your <span className="bold">Rewards Summary</span> page to change your renewal process to automatic renewal.</p>
                            </div>
                            <div className={`movie_footer_btn_el col-md-12 ml-auto px-5`}>
                                <div className="movie_button_wrap justify-content-end">
                                    <button
                                        type="button"
                                        className="btn black_btn"
                                        onClick={accountSetting}>
                                        GO TO ACCOUNT SETTINGS
                                    </button>
                                </div>
                            </div>
                        </div> : page === 'success' ?
                            <div className="body">
                                <div className="divider"></div>
                                <div className="renewal-success">
                                    <img className="card-img" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'tick_circle.svg'} />
                                    <p className="success-info">Success!</p>
                                    <p className="desc">Your membership will automatically renew 15 days prior to expiry.</p>
                                    <p className="desc">The renewal fee will be charged to your default credit card.</p>
                                </div>
                                <div className="divider"></div>
                                <div className="member-info">
                                    <div className="label">Member #</div>
                                    <div className="info"> {memberDetail?.LoyaltyMember?.CardNumber}</div>
                                    <div className="label mt-2">Expires On</div>
                                    <div className="info">{formatMembershipExpireDate(memberDetail!)}</div>
                                </div>
                                <div className="renewal-success-desc">
                                    <p>Your can change your default credit card in your account settings at any time.</p>
                                    <button className="btn gray_btn" onClick={goToSetting}>Go To Account Settings</button>
                                </div>
                                <div className={`movie_footer_btn_el col-md-12 ml-auto px-5 mt-3`}>
                                    <div className="movie_button_wrap justify-content-center justify-content-md-end">
                                        <button
                                            type="button"
                                            className="btn black_btn"
                                            onClick={backToSummary}>
                                            RETURN TO REWARDS SUMMARY
                                        </button>
                                    </div>
                                </div>
                            </div> : null}
                </div>
            </div>
        </div>
    );
};

export default EditRenewal;
