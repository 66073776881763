import React, { FC, useEffect, useState } from "react";
import { luxuryCinemasApi } from "../../../../services/sideMenus";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import './screenCategory.scss'
import { getSlidesWatcher, setContentModal, setDateObject, toggleContentModal } from "../../../../store/application/action";
import { API_REQUEST_TYPE } from "../../../../constants/url";
import { useNavigate, useParams } from "react-router";
import { SCREEN_CATEGORY } from "../../../../constants/app";
import Slider from "../../../../components/UI/Slider";
import { IScreenCategory } from "../../../../models/sidemenu";
import { sortBy } from "lodash";
import { ROUTES } from "../../../../constants/path";
import { Link } from "react-router-dom";

interface IScreenCategoryProps { }

const ScreenCategory: FC<IScreenCategoryProps> = () => {

    const { countryId, currentCinema, slides, cinemas } = useSelector((state: RootState) => ({
        countryId: state.applicationReducer.countryId,
        currentCinema: state.applicationReducer.currentCinema,
        slides: state.applicationReducer.slides,
        cinemas: state.applicationReducer.cinemas
    }))

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { category } = useParams();
    const [screenData, setScreenData] = useState<any>([]);
    const [screenCategories, setScreenCategories] = useState<IScreenCategory | null>(null);

    const getData = async () => {
        const { data } = await luxuryCinemasApi({
            countryId,
            isCafeBar: 0,
        });
        data.forEach((sortData: IScreenCategory) => {
            if (category === sortData.screenType) {
                setScreenCategories(sortData);
            }
        });
    }

    useEffect(() => {
        if (countryId && category) {
            getData();
        }
    }, [countryId, category])

    useEffect(() => {
        if (countryId && currentCinema && category) {
            dispatch(setDateObject(null));
            dispatch(getSlidesWatcher({
                countryId,
                pageName: category,
                location: currentCinema.slug
            }))
            const allCinemaLocation: any = [];
            Object.keys(cinemas).forEach(function (key) {
                const arrList = cinemas[key];

                arrList.forEach((obj: any) => {
                    if (
                        (obj.goldFlag && category === 'gold-lounge') ||
                        (obj.premiumFlag && category === 'premium') ||
                        (obj.titanluxeFlag && category === 'titan-luxe') ||
                        (obj.titanxcFlag && category === 'titan-xc')
                    ) {
                        allCinemaLocation.push({
                            Name: obj.name,
                            Address: obj.address,
                            city: obj.city,
                            Zip: obj.zip,
                            StateCode: obj.stateCode,
                            Phone: obj.phone,
                            PdfUrl: obj.pdfUrl,
                            cinemaId: obj.cinemaId,
                            cinemaname: obj.cinemaName,
                            dolbyAtmosImageUrl: obj.dolbyAtmosImageUrl
                        });
                    }
                });
            });
            setScreenData(sortBy(allCinemaLocation, row => row['Name']));

        }
    }, [countryId, currentCinema, category])

    const sessionPage = (screenDetails: any) => {
        navigate(`/cinemas/${category}/${screenDetails.cinemaId}`)
    }

    const onOpenModal = () => {
        dispatch(setContentModal({ type: ROUTES.GROUP_SALE }));
        dispatch(toggleContentModal(true));
    }

    const downloadMyFile = (url: string) => {
        const link = document.createElement('a');
        link.setAttribute('target', '_self');
        link.setAttribute('href', url);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const openOffer = () => {
        navigate(`/${ROUTES.OFFERS_EVENTS}`)
    }


    return <section className="screen-categories-wrapper">
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <Slider data={slides} />
                </div>
            </div>
        </div>
        <div className="header_bg">
            <div className="title">
                <Link className="back_to_all_movies" to={`/${ROUTES.LUXURY_CINEMAS}`}>ABOUT</Link>
            </div>
        </div>
        <div className="list-content">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="description">
                            <p dangerouslySetInnerHTML={{ __html: screenCategories?.description1 ? screenCategories.description1 : '<p></p>' }}></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="description">
                            <p dangerouslySetInnerHTML={{ __html: screenCategories?.description2 ? screenCategories.description2 : '<p></p>' }}></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="description">
                            <p dangerouslySetInnerHTML={{ __html: screenCategories?.description3 ? screenCategories.description3 : '<p></p>' }}></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="header_bg">
            <div className="title" role="heading">
                LOCATIONS
            </div>
        </div>
        <div className="location-area">
            {screenData.map((screenDetails: any) => <div className="list-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">
                            <h5 className="mt-0 title">
                                {screenDetails.Name}
                            </h5>
                            {screenDetails.dolbyAtmosImageUrl ? <img className="dolby-atmos" alt="" title="Dolby Atmos" src={screenDetails.dolbyAtmosImageUrl} /> : null}
                        </div>
                        <div className="col-md-3">
                            <p className="description">
                                {screenDetails.Address}<br />
                                {screenDetails.city} {screenDetails.Zip}
                                {screenDetails.StateCode}<br />
                                Phone: {screenDetails.Phone}<br />
                            </p>
                        </div>
                        <div className="col-md-7">
                            <ul className="button_menu">
                                <li>
                                    <button onClick={() => sessionPage(screenDetails)}>SESSIONS</button>
                                </li>
                                <li>
                                    {screenDetails.PdfUrl ? <a onClick={() => downloadMyFile(screenDetails.PdfUrl)} className="btn location-btn">MENU</a> : null}
                                </li>
                                <li><button onClick={openOffer}>OFFERS & EVENTS</button></li>
                                <li>
                                    <button onClick={onOpenModal}>
                                        BOOK GROUP
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    </section>
}

export default ScreenCategory; 