import React, { FC, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TICKET_FLOW } from "../../../../../../../constants/ticket";
import {
  MODAL_TYPE,
  TICKET_FLOW_TYPES,
} from "../../../../../../../models/tickets";
import { RootState } from "../../../../../../../store";
import {
  addSelectedTickets,
  clearFoodItemsAction,
  getBookedTickets,
} from "../../../../../../../store/foodAndBeverage/action";
import { futureFbAction, preOrderConcession,setModal, toggleTicketModal } from "../../../../../../../store/ticketBooking/action";
import "./movieSessions.scss";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../../../../../constants/path";
import { BRANDS } from "../../../../../../../constants/app";

interface IMovieSessionsProps {}

const MovieSessions: FC<IMovieSessionsProps> = () => {
  const {
    modalType,
    countryId,
    modalData,
    bookedTickets,
    prevModal,
    nextModal,
    currentCinema,
    brandId
  } = useSelector((state: RootState) => ({
    modalType: state.ticketBookingReducer.modal.type,
    countryId: state.applicationReducer.countryId,
    modalData: state.ticketBookingReducer.modal.data,
    bookedTickets: state.foodBeverageReducer.bookedTickets,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    currentCinema: state.applicationReducer.currentCinema,
    brandId: state.applicationReducer.brandId
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [movieList, setMovieList] = useState<any>({});

  useEffect(() => {
    if (modalData) {
      dispatch(
        getBookedTickets({
          requestType: "getBookedTickets",
          phoneNumber: modalData.phoneNumber,
          countryId: countryId,
        })
      );
    }
  }, [countryId, modalData]);

  useEffect(() => {
    if (bookedTickets) {
      setMovieList(bookedTickets);
    }
  }, [bookedTickets]);

  const back = () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const prev: MODAL_TYPE = prevModal;
    const modal = TICKET_FLOW[ticketType][prev];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
  };

  const onSelectMovie = (data: any) => {
    dispatch(addSelectedTickets(data));
    dispatch(preOrderConcession(data));
    dispatch(futureFbAction(true));
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][next];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
  };

  const addMovieSession = () => {
    dispatch(toggleTicketModal(false));
    navigate(brandId === BRANDS.US?`/${currentCinema.alias}/${ROUTES.MOVIES}`:`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
  };

  return (
    <div className="row m-3 future-fb-sessions">
      <div className="col-12 col-md-12 pl-lg-0">
        <a
          className="chevron_left_black"
          onClick={back}
        >
          Back
        </a>
      </div>
      <div className="col-12 col-md-12 pl-lg-0">
        <div malihu-scrollbar className="custom_scroll_content vscroll_600">
          <>
            {Object.keys(movieList).length > 0 ? (
              Object.keys(movieList).map((key) => {
                return (
                  <ul className="list_wrap">
                    <p className="heading">{movieList[key].sessionTime}</p>
                    {movieList[key].data.map((data: any) => {
                      return (
                        <li className="movie_list">
                          <div
                            className="media_wrap"
                            onClick={() => onSelectMovie(data)}
                          >
                            <div className="media_content">
                              <div className="thumb_img">
                                <img src={data.movieImage} alt="" />
                              </div>
                              <div className="list_details">
                                <p className="title">{data.movieName}</p>
                                <p className="item_list">
                                  {data.time} - {data.date}
                                </p>
                                <p className="item_list">
                                  {data.theatreId} - {data.selectedSeats} Seats
                                </p>
                              </div>
                            </div>
                            <div className="edit_btn">&nbsp;</div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                );
              })
            ) : (
              <div className="no-ticket-container">
                <p className="m-auto p-3">No tickets found</p>
              </div>
            )}
          </>
        </div>
      </div>
      <div className="col-12 col-md-12 d-flex justify-content-center">
        <a
          className="add_movie_btn"
          onClick={addMovieSession}
        >
          <span className="add_btn_plus mr-1"></span>
          <span>ADD MOVIE SESSION</span>
        </a>
      </div>
    </div>
  );
};

export default MovieSessions;
