import React,{ FC, useState, useRef } from "react";
import { URLS } from "../../../../../../constants/url";
import Member from "../../../../../../components/Auth/Member";
import './futureFb.scss';
import { useDispatch, useSelector } from "react-redux";
import { clearFoodItemsAction } from "../../../../../../store/foodAndBeverage/action";
import { TICKET_FLOW } from "../../../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../../../models/tickets";
import { setModal } from "../../../../../../store/ticketBooking/action";
import { RootState } from "../../../../../../store";
import { sentOtp, verifyOTP } from "../../../../../../services/concessions";

interface IFutureFbProps {}

const FutureFb: FC<IFutureFbProps> = () => {
  const {modalType, countryId, nextModal} = useSelector((state: RootState)=>({
      modalType: state.ticketBookingReducer.modal.type,
      countryId: state.applicationReducer.countryId,
      nextModal: state.ticketBookingReducer.modal.nextModal
  }))

  const dispatch = useDispatch();
  const [pageType, setPageType] = useState("home");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState("");
  const mobileNumberRef: React.RefObject<HTMLInputElement> = useRef(null);
  const otpNumberRef: React.RefObject<HTMLInputElement> = useRef(null);

  const back = () => {
    dispatch(clearFoodItemsAction());
      const modal =
        TICKET_FLOW[TICKET_FLOW_TYPES.FOOD_AND_BEVERAGE][
          MODAL_TYPE.FOOD_AND_BEVERAGE_MAIN
        ];
      dispatch(
        setModal({
          ...modal,
          type: modalType,
        })
      );
  };

  const showLogin = () => {
    setPageType("login");
  };

  const changePage = (type: string)=>{
    setPageType(type)
  }

  const verifyOtp = async() => {
    const otp = otpNumberRef.current?.value;
    if (!otp) {
      setErrorMessage("Please enter the code");
      return;
    }
    await verifyOTP({ 
      countryId: countryId,
      phoneNumber: mobileNumber,
      otp: otp
    }).then(async(res: any)=> {
      if (res.response.data.data) {
        const ticketType: TICKET_FLOW_TYPES = modalType;
        const next: MODAL_TYPE = nextModal;
        const modal = TICKET_FLOW[ticketType][next];
        dispatch(
          setModal({
            ...modal,
            type: modalType,
            data: {
              phoneNumber: mobileNumber
            }
          })
        );
      }
      else {
        setErrorMessage("Invalid OTP");
      }
    })    
  };

  const sendOtp = async() => {
    const mobileNumber = mobileNumberRef.current?.value;
    if (!mobileNumber) {
      setErrorMessage("Please enter your mobile number");
      return;
    }
    else if (countryId == "2" && mobileNumber.length < 8) {
      setErrorMessage("Mobile Number should be minimum of 8 digits");
      return;
    } else if (mobileNumber.length < 9) {
      setErrorMessage("Mobile Number should be minimum of 9 digits");
      return;
    }    
    await sentOtp({ 
      countryId: countryId,
      phoneNumber: mobileNumber
    }).then(()=> {
      setPageType("otp");
      setMobileNumber(mobileNumber);
    })    
  };

  return (
    <div className="future-fb-wrapper">
      <div className="col-12 col-md-12 px-lg-0 px-3">
        <a
          href="javascript:void(0)"
          className="chevron_left_black"
          onClick={back}
        >
          Back
        </a>
      </div>
      <div className="my-5">
        {pageType === "home" ? (
          <div className="d-flex align-items-center flex-column my-4">
            <div className="text-center">
              <h4>You're not logged in.</h4>
              <button
                type="button"
                className="btn black_btn my-4"
                onClick={()=>changePage('login')}
              >
                LOG IN
              </button>
            </div>
            <div>
              <h4>OR</h4>
            </div>
            <h6>Retrieve your booking using your mobile number</h6>
            <div>
              <div className="search_common">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter mobile number"
                  ref={mobileNumberRef}
                />
                <button type="button" onClick={sendOtp}>
                  <img src={URLS.RIGHT_ARROW} />
                </button>
              </div>
              {errorMessage ? (
                <div className="error_message text-center mt-2">
                  {errorMessage}
                </div>
              ) : null}
              <p className="mt-2">
                <em>A validation code will be sent to your mobile number.</em>
              </p>
            </div>
          </div>
        ) : null}

        {pageType === "login" ? (
          <div className="row justify-content-center flex-column align-items-center">
            <div className="col-md-6 col-12">
              <div className="tab">
                <h6 className="text-center">Sign-in</h6>
              </div>

              <Member fromPage="futureFb" backHandler={()=>changePage('home')} />
            </div>
          </div>
        ) : null}

        {pageType === "otp" ? (
          <div className="d-flex align-items-center flex-column my-4">
            <div className="text-center">
              <h6>Your validation code has been sent to:</h6>
              <h3 className="my-4">{mobileNumber}</h3>
            </div>
            <div>
              <h6>Enter the code below:</h6>
            </div>
            <div>
              <div className="search_common">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter validation code"
                  maxLength={4}
                  ref={otpNumberRef}
                />
                <button type="button" onClick={verifyOtp}>
                  <img src={URLS.RIGHT_ARROW} />
                </button>
              </div>
              {errorMessage ? (
                <div className="error_message text-center mt-2">
                  {errorMessage}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FutureFb;
