import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { RootState } from "../../../store";
import {
  CAPTCHA_SITE_KEY,
  COUNTRY,
  GENDER_LIST,
  GENDER_LIST_STA,
  GENRE_LIST,
} from "../../../constants/app";
import TextInput from "../../UI/TextInput";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../utils/validatorSchema";
import {
  showLogin,
  signUpAsGuest,
  updateMembbershipPurchase,
} from "../../../store/auth/action";
import SelectDropdown from "../../UI/DropDown/SelectDropDown";
import { dateArray, monthArray, yearArray } from "../../../utils/helper";
import { toast } from "react-toastify";
import { setModal, toggleTicketModal } from "../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";

interface ISignUpProps {
  fromPage?: string;
}

const SignUp: FC<ISignUpProps> = ({ fromPage }) => {
  const {
    countryId,
    cinemas,
    currentCinema,
    modalType,
    prevModal,
    nextModal,
    membershipPurchase,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    cinemas: state.applicationReducer.cinemas,
    currentCinema: state.applicationReducer.currentCinema,
    modalType: state.ticketBookingReducer.modal.type,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    membershipPurchase: state.authReducer.membershipPurchase,
  }));

  const dispatch = useDispatch();
  const captchaRef = useRef<any>();
  const [errorMessage, setErrorMessage] = useState("");
  const [genderList, setGenderList] = useState<{ label: string; value: any }[]>(
    []
  );

  useEffect(() => {
    if ([COUNTRY.AUS, COUNTRY.NZ].includes(countryId)) {
      setGenderList(GENDER_LIST);
    } else if ([COUNTRY.STA, COUNTRY.ANG].includes(countryId)) {
      setGenderList(GENDER_LIST_STA);
    }
  }, [countryId]);

  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: createValidationSchema("signup"),
      enableReinitialize: true,
      initialValues: {
        countryId: countryId,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        gender: "",
        genres: [],
        password: "",
        confirmPassword: "",
        cardNumber: "",
        day: "",
        month: "",
        year: "",
        cinemaId: currentCinema?.cinemaId || "",
        cardnumber: "",
        weeklyNews: 1,
        captcha: false,
        response_value: "",
        isNotRequired: (countryId === COUNTRY.STA || countryId === COUNTRY.ANG)?true:false
      },
      onSubmit: (values) => {
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          gender,
          genres,
          password,
          confirmPassword,
          cardNumber,
          day,
          month,
          year,
          cinemaId,
          weeklyNews,
          response_value
        } = values;

        const payload = {
          cardNumber,
          countryId: countryId,
          dateOfBirth: `${year}-${month}-${day}`,
          email,
          firstName,
          gender,
          lastName,
          password,
          phoneNumber,
          preferredGenres: genres,
          theatreId: cinemaId,
          weeklyNews,
          isMemberPackage: fromPage == "membership" ? true : false,
          itemId:
            fromPage == "membership" &&
              membershipPurchase &&
              membershipPurchase.loyaltyPackage &&
              membershipPurchase.loyaltyPackage.id
              ? membershipPurchase.loyaltyPackage.id
              : undefined,
          clubId:
            fromPage == "membership" &&
              membershipPurchase &&
              membershipPurchase.clubId
              ? membershipPurchase.clubId
              : undefined,
          response_value
        };
        dispatch(
          signUpAsGuest(
            payload,
            (response: any) => {
              if (response.statusCode === 200) {
                dispatch(showLogin(false));
                toast.success(
                  `You have successfully registered. Validation link has sent to your registered email`
                );
                if (fromPage === "membership") {
                  const ticketType: TICKET_FLOW_TYPES = modalType;
                  const next: MODAL_TYPE = nextModal;
                  const modal = TICKET_FLOW[ticketType][next];

                  dispatch(
                    setModal({
                      ...modal,
                      type: modalType,
                    })
                  );
                } else {
                  dispatch(toggleTicketModal(false));
                }
              } else {
                if (captchaRef && captchaRef.current) {
                  captchaRef.current.reset();
                }
              }
            },
            (error: any) => {
              if (captchaRef && captchaRef.current) {
                captchaRef.current.reset();
              }
              setErrorMessage(error);
            }
          )
        );
      },
    });

  const onCancel = () => {
    dispatch(showLogin(false));
    dispatch(toggleTicketModal(false))
  };

  const cinemaList = useMemo(() => {
    const allCinemaLocation: { label: string; value: string }[] = [];
    Object.keys(cinemas).forEach(function (key) {
      const arrList = cinemas[key];
      arrList.forEach((obj: any) => {
        allCinemaLocation.push({
          value: obj.slug,
          label:
            countryId === COUNTRY.AUS || countryId === COUNTRY.NZ
              ? obj.cinemaName + ", " + key
              : obj.cinemaName,
        });
      });
    });
    return allCinemaLocation;
  }, [cinemas]);

  const onChangeWeeklyLetter = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("weeklyNews", checked ? 1 : 0);
  };

  const onChangeCaptcha = (event: any) => {
    setFieldValue("captcha", event ? true : false);
    setFieldValue("response_value", event);
  };

  const isManditory = useMemo(()=>{
    return countryId === COUNTRY.STA || countryId === COUNTRY.ANG? false: true
  },[countryId])

  return (
    <div className="login-form form_page">
      <div className="row">
        <div className="col-12 col-md-6 ">
          <label className="form-label">First Name*</label>
          <div className="form-group pass_show">
            <TextInput
              value={values.firstName}
              field={"firstName"}
              handleChange={handleChange}
              touched={touched.firstName!}
              error={errors.firstName}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Last Name</label>
          <div className="form-group pass_show">
            <TextInput
              value={values.lastName}
              field={"lastName"}
              handleChange={handleChange}
              touched={touched.lastName!}
              error={errors.lastName}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Email*</label>
          <div className="form-group pass_show">
            <TextInput
              value={values.email}
              field={"email"}
              handleChange={handleChange}
              touched={touched.email!}
              error={errors.email}
            />
            <span className="hint">Ex:Johnxxxx99@gmail.com</span>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Phone Number*</label>
          <div className="form-group pass_show position-relative">
            <TextInput
              value={values.phoneNumber}
              field={"phoneNumber"}
              handleChange={handleChange}
              touched={touched.phoneNumber!}
              error={errors.phoneNumber}
            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <label className="form-label">Gender{isManditory?'*':''}</label>
          <div className="form-group">
            <SelectDropdown
              field={"gender"}
              options={genderList}
              setFieldValue={setFieldValue}
              touched={touched.gender!}
              error={errors.gender}
              value={values.gender}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Preferred Genres{isManditory?'*':''}</label>
          <div className="form-group">
            <SelectDropdown
              field={"genres"}
              options={GENRE_LIST}
              setFieldValue={setFieldValue}
              touched={touched.genres!}
              error={errors.genres}
              value={values.genres}
              isMulti={true}
            />
          </div>
        </div>
        <div className="col-12 col-md-12">
          <label className="form-label">Date of Birth*</label>
          <div className="form-group">
            <div className="row">
              <div className="col-4 col-md-4">
                <SelectDropdown
                  field={"day"}
                  options={dateArray()}
                  setFieldValue={setFieldValue}
                  touched={touched.day!}
                  error={errors.day}
                  value={values.day}
                />
              </div>
              <div className="col-4 col-md-4">
                <SelectDropdown
                  field={"month"}
                  options={monthArray()}
                  setFieldValue={setFieldValue}
                  touched={touched.month!}
                  error={errors.month}
                  value={values.month}
                />
              </div>
              <div className="col-4 col-md-4">
                <SelectDropdown
                  field={"year"}
                  options={yearArray()}
                  setFieldValue={setFieldValue}
                  touched={touched.year!}
                  error={errors.year}
                  value={values.year}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div>
            <label className="form-label">Password*</label>
            <div className="form-group pass_show">
              <TextInput
                type="password"
                value={values.password}
                field={"password"}
                handleChange={handleChange}
                touched={touched.password!}
                error={errors.password}
              />
              <span className="hint">Ex:Johnpeter@123</span>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div>
            <label className="form-label">Confirm Password*</label>
            <div className="form-group pass_show">
              <TextInput
                type="password"
                value={values.confirmPassword}
                field={"confirmPassword"}
                handleChange={handleChange}
                touched={touched.confirmPassword!}
                error={errors.confirmPassword}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Cinema*</label>
          <div className=" form-group">
            <SelectDropdown
              field={"cinemaId"}
              options={cinemaList}
              setFieldValue={setFieldValue}
              touched={touched.cinemaId!}
              error={errors.cinemaId}
              value={values.cinemaId}
            />
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div className="custom_checkbox p-default form-group">
            <input
              onClick={onChangeWeeklyLetter}
              type="checkbox"
              checked={values.weeklyNews === 1 ? true : false}
              value="1"
            />
            <div className="state p-primary">
              <label className="form-label">
                {countryId === COUNTRY.ANG
                  ? "Keep me updated with Angelika offers, special events and weekly news"
                  : countryId === COUNTRY.STA
                    ? `Keep me updated with State Cinema offers, special events and weekly news`
                    : `Keep me updated with Reel Club weekly news`}
              </label>
            </div>
          </div>
        </div>
        <div className="col-12  col-md-12">
          <label className="form-label">Verification Code* </label>
          <div className="form-group">
            <ReCAPTCHA
              sitekey={`${CAPTCHA_SITE_KEY}`}
              onChange={onChangeCaptcha}
              ref={captchaRef}
            />
            {errors.captcha && touched.captcha ? (
              <div className="error_message">
                <span>Verification Code is required</span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="err-message">{errorMessage}</div>
        <div className="movie_button_wrap">
          <button
            type="button"
            className="btn black_btn m-r-20"
            onClick={() => handleSubmit()}
          >
            SIGN UP
          </button>
          <button type="button" className="btn gray_btn" onClick={onCancel}>
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
