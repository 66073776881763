import { FC, useState, useEffect } from "react";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { getSlidesWatcher } from "../../../../store/application/action";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import Slider from "../../../../components/UI/Slider";
import "./sohoLounge.scss";
import NowShowing from "../../../AU/Movies/NowShowing";
import { downloadFile } from "../../../../utils/helper";
import { getNowShowingWatcher } from "../../../../store/films/action";
import { screenCategoryApi } from "../../../../services/sideMenus";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { ISohoLounge } from "../../../../models/sidemenu";
import HelmetTitle from "../../../../components/Helmet";


interface ISohoLoungeProps {}

const SohoLounge: FC<ISohoLoungeProps> = () => {
  const { countryId, slides, currentCinema, nowShowing } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      slides: state.applicationReducer.slides,
      nowShowing: state.filmsReducer.nowShowing,
    })
  );

  const [data, setData] = useState<ISohoLounge[]>([]);
  const dispatch = useDispatch();

  const getData = async () => {
    const response = await screenCategoryApi({
      countryId,
      screenType: "soho-lounge",
    });

    setData(
      response && response.length > 0
        ? response[0].content.map((v: any) => {
            v.file = v.buttons.find((v: any) => v.type === "file");
            v.images = v.buttons
              .filter((v: any) => v.type === "image")
              .map((v: any) => ({
                imageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.Id}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
                mobileImageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.Id}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
                showBannerInfo: 0,
              }));
            return v;
          })
        : []
    );
  };

  useEffect(() => {
    if (countryId && currentCinema) {
      getData();
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.SOHO_LOUNGE,
        })
      );
    }
  }, [countryId, currentCinema]);

  useEffect(() => {
    if (currentCinema) {
      const payload = {
        countryId: countryId,
        cinemaId: currentCinema?.slug,
        status: "nowShowing",
        flag: "Soho",
      };
      dispatch(getNowShowingWatcher(payload));
    }
  }, [currentCinema]);

  return (
    <div className="soholongue-wrapper">
      <HelmetTitle title="Soho Longue" description="Soho Longue"/>
      <div className="slider-filter">
        <Slider className="banner-slider-us" data={slides} />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="thumb_wrap p-0 px-0">
            {data.map((list: any) => (
              <div className="list_thumb">
                <div className="thumb_image d-md-none d-block">
                  <Slider data={list.images} />
                </div>
                <div className="thumb_content_wrap">
                  <div className="thumb_content">
                    <div className="thumb_title">{list.title}</div>
                    <div className="description">
                      {list.description ? (
                        <HtmlRender content={list.description} />
                      ) : null}
                      {list.description1 ? (
                        <HtmlRender content={list.description1} />
                      ) : null}
                      {list.description2 ? (
                        <HtmlRender content={list.description2} />
                      ) : null}
                      {list.description3 ? (
                        <HtmlRender content={list.description3} />
                      ) : null}
                    </div>
                    {list.showButton === 1 ? (
                      <div className="cust_desc_thumb_btn_wrap">
                        {list.buttonType === "file" && list.file ? (
                          <div className="thumb_btn_wrap">
                            <a
                              href="javascript:void(0)"
                              className="white_btn"
                              onClick={() =>
                                downloadFile(
                                  `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.FILE_PATH}/${list.file.Id}.${list.file.extension}`
                                )
                              }
                            >
                              {list.buttonName}
                            </a>
                          </div>
                        ) : list.buttonType === "link" && list.buttonLinkUrl ? (
                          <div className="thumb_btn_wrap">
                            <a
                              href={list.buttonLinkUrl}
                              className="white_btn"
                              target="blank"
                            >
                              {list.buttonName}
                            </a>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="thumb_image d-md-block d-none">
                  <Slider data={list.images} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="soho_longue_section ">
        <h2 className="sessions">
          <span>UPCOMING SoHo SESSIONS</span>
        </h2>
        <div>
        <NowShowing page="soholongue" />
        </div>
      </div>
    </div>
  );
};

export default SohoLounge;
