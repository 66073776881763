import { FC, useEffect, useMemo, useState } from "react";
import "./benefits.scss";
import { IMAGE_URL_CONFIG } from "../../../../constants/url";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { formatMembershipType } from "../../../../utils/rewards";
import { COUNTRY, MEMBERSHIP_TYPE } from "../../../../constants/app";
import { setContentModal, toggleContentModal } from "../../../../store/application/action";
import { ROUTES } from "../../../../constants/path";
import { upgrademembership } from "../../../../services/rewards";
import { pageContentApi } from "../../../../services/sideMenus";
import { MEMBERSHIP_CONTENT } from "../../../../constants/membership";


interface IBenefitsProps { }

const Benefits: FC<IBenefitsProps> = () => {

    const { memberDetail, currentCinema, userDetails, countryId } = useSelector(
        (state: RootState) => ({
            memberDetail: state.authReducer.memberDetail,
            currentCinema: state.applicationReducer.currentCinema,
            userDetails: state.authReducer.userDetails,
            countryId: state.applicationReducer.countryId
        })
    );

    const dispatch = useDispatch();
    const [ clubContent, setClubContent] = useState();
    const [ goldContent, setGoldContent] = useState();

    const getPageContent = async () => {
        const {
          data: { data, status },
        } = await pageContentApi({
          countryId,
          cinemaId: currentCinema?.slug,
          pageName: countryId === COUNTRY.STA? `state-rewards` : `angelika-rewards`,
          flag: "customPage",
        });
        if (status && data && data.length > 0) {
            const [contentData] = data;
            if(contentData && contentData.content){
                const clubData = contentData.content.find((c:any)=>c.buttons.find((v:any)=>(v.Button_Text).toLowerCase().includes('club')));
                const goldData = contentData.content.find((c:any)=>c.buttons.find((v:any)=>(v.Button_Text).toLowerCase().includes('gold')));
                if(clubData && clubData.Tile_Description){
                    setClubContent(clubData.Tile_Description)
                }
                if(goldData && goldData.Tile_Description){
                    setGoldContent(goldData.Tile_Description)
                }
            }
        }
      };

      useEffect(() => {
        if (countryId && currentCinema) {
          getPageContent();
        }
      }, [countryId, currentCinema]);
    

    const membershipType = useMemo(() => {
        return formatMembershipType(memberDetail!)
    }, [memberDetail])

    const cancelSubscrption = () => {
        dispatch(setContentModal({ type: ROUTES.CANCEL_MEMBERSHIP }));
        dispatch(toggleContentModal(true));
    }

    const onUpgrade = async() => {
        await upgrademembership(countryId, currentCinema, userDetails, dispatch);
    };

    const logo = useMemo(() => {
        if(countryId){
        if (countryId === COUNTRY.STA) {
          return {
            free: MEMBERSHIP_CONTENT[COUNTRY.STA]['BASIC']['logo'],
            paid: MEMBERSHIP_CONTENT[COUNTRY.STA]['BOOST']['logo']
          }
        } else if (countryId === COUNTRY.ANG) {
          return {
            free: MEMBERSHIP_CONTENT[COUNTRY.ANG]['CLUB']['logo'],
            paid: MEMBERSHIP_CONTENT[COUNTRY.ANG]['GOLD']['logo']
          }
        } else {
          return {
            free: MEMBERSHIP_CONTENT[COUNTRY.AUS]['FREE']['logo'],
            paid: MEMBERSHIP_CONTENT[COUNTRY.AUS]['PAID']['logo']
          }
        }
      }else{
        return {
          free:'',
          paid:''
        };
      }
      }, [countryId])

      
    return (
        <div className="benefits_category">
            <div className="benefits_item">
                <div className={`benefit_gold brand_${countryId}`}>
                    <div className="logo">
                        <img
                            className="w-100"
                            src={
                                `${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}` + logo.paid
                            }
                        />
                    </div>
                    <div className="details_gold">
                        <div className="gold_heading">$20 Annual Membership</div>
                        <div className="gold_body">
                            <div className="description_gold underline_seperate">{countryId === COUNTRY.STA?15:20}% off tickets* <br /><span className="fn-14">All sessions, every day</span></div>
                            <div className="description_gold underline_seperate">10% off food & beverage <br /><span className="fn-14">All sessions, every day</span></div>
                            <div className="fn-14 underline_seperate">Collect 20 points per dollar spent</div>
                            <div className="fn-14 underline_seperate">Various redeemable rewards</div>
                            <div className="fn-14 underline_seperate">Redeem your points online or at the cinema</div>
                            <div className="fn-14 underline_seperate">Access to exclusive member only competitions and screenings</div>
                            <div className="fn-14 underline_seperate">Stay up to date with our weekly e-newsletter</div>
                            <div className="fn-12">*{countryId === COUNTRY.STA?'15% off one ticket per session':'20% off the Adult ticket price'}</div>
                        </div>

                    </div>
                    {membershipType === MEMBERSHIP_TYPE.GOLD ?
                        <h5 className="active-membership"><img src={
                            IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                            "tick_circle.svg"
                        } alt="" /><span>Current membership</span></h5> : null}
                </div>
                <div className={`benefit_club brand_${countryId}`}>
                    <div className="logo">
                        <img
                            className="w-100"
                            src={
                                IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                logo.free
                            }
                        />
                    </div>
                    <div className="details_club">
                        <div className="club_heading">Free Membership</div>
                        <div className="club_body">
                            <div className="fn-14 underline_seperate">Collect 10 points per dollar spent</div>
                            <div className="fn-14 underline_seperate">Various redeemable rewards</div>
                            <div className="fn-14 underline_seperate">Redeem your points online or at the cinema</div>
                            <div className="fn-14 underline_seperate">Access to exclusive member only competitions and screenings</div>
                            <div className="fn-14">Stay up to date with our weekly e-newsletter</div>
                        </div>

                    </div>
                    {membershipType === MEMBERSHIP_TYPE.CLUB ?
                        <h5 className="active-membership"><img src={
                            IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                            "tick_circle.svg"
                        } alt="" /><span>Current membership</span></h5> : null}
                </div>
            </div>
            <div className="mb-4 text-center">
                {membershipType === MEMBERSHIP_TYPE.GOLD ?
                    // <button className="btn btn_black" onClick={cancelSubscrption}>CANCEL ANGELIKA REWARD GOLD</button>
                    null
                    :
                    <>
                        <div className="upgrade_to_angelika">Upgrade to Angelika Rewards Gold</div>
                        <button className={`btn ${countryId === COUNTRY.STA?'btn-boost':'btn-gold'}`} onClick={onUpgrade}>UPGRADE NOW</button>
                    </>
                }
            </div>
        </div>
    );
};

export default Benefits;
