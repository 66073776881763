import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import "./cinemas.scss";
import { getSlidesWatcher, setCinemaAction, setDateObject } from '../../../store/application/action';
import { findCinemaObject } from '../../../utils/formatter';
import { COUNTRY, MOVIE_TYPES, SCREEN_CATEGORY } from '../../../constants/app';
import { IMOVIE_TYPES } from '../../../models/common';
import { ICinemas } from '../../../models/cinema';
import _ from 'lodash';
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG } from '../../../constants/url';
import { useNavigate } from 'react-router';
import NowShowing from '../Movies/NowShowing';

interface ICinemasProps {
  page?: string;
}

const Cinemas: FC<ICinemasProps> = ({ page }) => {

  const { cinemas, currentCinema, countryId, nowShowingData } = useSelector(
    (state: RootState) => ({
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
      countryId: state.applicationReducer.countryId,
      nowShowingData: state.filmsReducer.nowShowing,
    })
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState('');
  const [hide, setHide] = useState('');
  const [highlightCinema, setHighlightCinema] = useState<ICinemas | null>(null);
  const [movieTypes, setMovieTypes] = useState<IMOVIE_TYPES>(MOVIE_TYPES);

  useEffect(() => {
    if (countryId && currentCinema) {
      setHide('step');

      Object.keys(cinemas).forEach((v: any, i: number) => {
        if (v === currentCinema.stateCode) {  
          setOpen('step' + i);
        }
      })
      setHighlightCinema(currentCinema)
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.MOVIES,
        })
      );
    }
    dispatch(setDateObject(null));
  }, [countryId, currentCinema]);


  const cinemaListclick = (cinema: any, hilight: any) => {
    setHighlightCinema(cinema);
    setHide('step');
    setOpen('step' + hilight);
    dispatch(setCinemaAction(findCinemaObject(cinemas, cinema.slug)));
  }

  console.log('open', open)

  const stateListclick = (state: any, hilight: number) => {
    let cinema = cinemas[state][0];
    setHighlightCinema(cinema);
    setHide('step');
    setOpen('step' + hilight);
    dispatch(setCinemaAction(findCinemaObject(cinemas, cinema.slug)));
  }

  const viewAllSession = (cinema: ICinemas) => {
    navigate(`/cinemas/${SCREEN_CATEGORY.ALL}/${cinema.slug}`);
  }

  return (
    <>
      <section className="bg_black location-cinema">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="location_heading pt-5">Location</h3>
            </div>
          </div>
          <div className="row map_showing_wrap">
            <div className="col-12 col-md-4 col-lg-5">
              <div className="map-details">
                <ul className="map-list-order">
                  <li>
                    {Object.keys(cinemas).map((state, i) => <div className={`map_list ${countryId === COUNTRY.ANG ? 'map_list_ang' : ''}`}>
                      <h4 className={`pointer ${highlightCinema?.stateCode === state ? 'redhead' : ''}`} onClick={() => stateListclick(state, i)}>{state}</h4>
                      <div className="map_sub_list">
                        {cinemas[state].map((cinema: ICinemas) => <a className={`${highlightCinema?.slug == cinema.slug ? 'active' : ''}`} onClick={() => cinemaListclick(cinema, i)}
                          style={{ 'fontWeight': highlightCinema?.slug == cinema.slug ? 'bold' : 'lighter' }}>
                          {cinema.cinemaName}
                        </a>)}
                      </div>
                    </div>)}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-7">
              <div className="map_wrap">
                {countryId !== COUNTRY.NZ ? <div className="map nt_map hidemap">
                  <img alt="Western Australia" src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'nt_map.svg'} />
                </div> : null}
                {Object.keys(cinemas).map((state, i) => <div className={`map ${state.toLowerCase()}_map  ${open == 'step' + i ? 'openmap' : ''} ${hide == 'step' ? 'hidemap' : ''}`}>
                  <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}${state.toLowerCase()}_map.svg`} alt="Western Australia" />
                  {cinemas[state].map((cinema: ICinemas, i: number) => <span className={`arrow arrow-${i}`}></span>)}
                </div>)}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='location-cinema'>
        {highlightCinema ? <div className="location-section bg-gray">
          <div className="container py-3">
            <div className="row">
              <div className="col-6 col-lg-2">
                <h2>{highlightCinema?.cinemaName}</h2>
                <a onClick={() => viewAllSession(highlightCinema)} className="view_all_session">View All Sessions</a>
              </div>
              <div className="col-6 col-lg-3">
                <div className="sub-address">{highlightCinema.address}</div>
                <div className="sub-address">
                  {highlightCinema.city} {highlightCinema.zip}
                  {highlightCinema.stateCode}
                </div>
              </div>
              <div className="col-6 ml-auto ml-lg-0 col-lg-2">
                <div className="sub-address">
                  Phone: {highlightCinema.phone}
                </div>
              </div>
              <div className="col-12 ml-auto ml-lg-0 col-lg-5">
                <div className="print_session_wrap">
                  <div className="d-flex">
                    <div className="print_session">
                      <a
                        href="/printsession"
                        target="_blank"
                        className="text-nowrap"
                      >
                        Print Session Times
                      </a>

                    </div>
                  </div>
                  <div className="legend_text">
                    <ul>
                      {Object.values(movieTypes).map((v) => {
                        return v.active ? (
                          <li className={`${v.class} text-nowrap`}>
                            {v.title}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <NowShowing page={'cinemas'} data={{ cinema: highlightCinema?.slug, cinemaCategory: '' }} />
          </div>
        </div> : null}
      </div>
    </>)


}

export default Cinemas;