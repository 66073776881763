import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { BRANDS, COUNTRY } from "../../../../constants/app";
import { URLS } from "../../../../constants/url";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../constants/path";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { checkGiftCardBalanceApi } from "../../../../services/giftshop";
import moment from "moment";

interface IGiftShopFooterProps {
  page?: string
}

const GiftShopFooter: FC<IGiftShopFooterProps> = ({page}) => {
  const { countryId, brandId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    brandId: state.applicationReducer.brandId,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [facebookUrl, setFacebookUrl] = useState<string>("");
  const [instagramUrl, setInstagramUrl] = useState<string>("");
  const [giftCardError, setGiftCardError] = useState("");
  const [giftCardBalance, setGiftCardBalance] = useState({
    balance: "",
    expiryDate: "",
  });
  const [GiftShopImages, setGiftShopImages] = useState({
    imagePath: "",
    desktop_img: "",
    mobile_img: "",
    bannerClass: "",
    altText: "",
  });

  useEffect(() => {
    if (countryId) {
      switch (countryId) {
        case COUNTRY.STA:
          setGiftShopImages({
            imagePath:
              URLS.CLOUDFRONT_URL +
              "/assets/images/state_gift_card_img_new.png",
            desktop_img:
              URLS.CLOUDFRONT_URL + "/assets/images/LogoStateCinema.png",
            mobile_img:
              URLS.CLOUDFRONT_URL +
              "/assets/images/HeaderMobileLogoStateCinema.png",
            bannerClass: "stateCheck",
            altText: "State Cinema",
          });
          break;
        case COUNTRY.ANG:
          setGiftShopImages({
            imagePath:
              URLS.CLOUDFRONT_URL +
              "/assets/images/angelika_gift_card.png",
            desktop_img:
              URLS.CLOUDFRONT_URL + "/assets/images/angelika-logo-lg.svg",
            mobile_img:
              URLS.CLOUDFRONT_URL + "/assets/images/angelika-logo-md.svg",
            bannerClass: "angelikaCheck",
            altText: "Angelika",
          });
          break;
        case COUNTRY.US:
          setGiftShopImages({
            imagePath:
              URLS.CLOUDFRONT_URL + "/assets/images/gift_card_design.svg",
            desktop_img: "",
            mobile_img: "",
            bannerClass: "otherCheck",
            altText: "",
          });
          break;
        default:
          setGiftShopImages({
            imagePath:
              URLS.CLOUDFRONT_URL + "/assets/images/gift_card_img_new.png",
            desktop_img: "",
            mobile_img: "",
            bannerClass: "otherCheck",
            altText: "",
          });
      }
    }
  }, [countryId]);

  useEffect(() => {
    switch (countryId) {
      case COUNTRY.AUS:
        setFacebookUrl(URLS.FACEBOOK_AU);
        setInstagramUrl(URLS.INSTAGRAM_AU);
        break;
      case COUNTRY.NZ:
        setFacebookUrl(URLS.FACEBOOK_NZ);
        setInstagramUrl(URLS.INSTAGRAM_NZ);
        break;
      case COUNTRY.ANG:
        setFacebookUrl(URLS.FACEBOOK_ANG);
        setInstagramUrl(URLS.INSTAGRAM_ANG);
        break;
      case COUNTRY.STA:
        setFacebookUrl(URLS.FACEBOOK_STA);
        setInstagramUrl(URLS.INSTAGRAM_STA);
        break;
    }
  }, [countryId]);

  const { handleSubmit, values, errors, touched, setFieldValue, handleChange } =
    useFormik({
      validationSchema: createValidationSchema("giftCardShop"),
      initialValues: {
        cardNumber: "",
      },
      onSubmit: async (values) => {
        setGiftCardError("");
        const { cardNumber } = values;
        const response: any = await checkGiftCardBalanceApi({
          cardNumber: cardNumber,
          countryId: countryId,
          requestType: "giftcardBalanceCheck",
        });

        if (response.status) {
          setGiftCardBalance({
            balance: response.balance,
            expiryDate:
              response?.expiry !== null
                ? `Exp. Date: ${moment
                    .parseZone(response.expiry)
                    .format("DD, MMM YYYY")}`
                : "",
          });
        } else {
          setGiftCardError("Invalid Card Number");
        }
      },
    });

  const openModal = (type: string) => {
    dispatch(setContentModal({ type }));
    dispatch(toggleContentModal(true));
  };

  return (
    <>
      <div className={`check_e_card ${GiftShopImages.bannerClass}`}>
        <div className="check_e_card_inner">
          <div className="check_e_card_left">
            <img src={GiftShopImages.imagePath} alt="" />
          </div>
          <div className="check_e_card_right">
            <h2>Check Gift Card</h2>
            <p>
              Enter your card number below to check your card’s balance and
              expiry.
            </p>
            <div className="search_common">
              <input
              placeholder="Enter card number"
                type="text"
                value={values.cardNumber}
                maxLength={20}
                onChange={({ target: { value } }) =>
                  setFieldValue("cardNumber", value)
                }
                className="form-control"
              />
              <button
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <img src={URLS.RIGHT_ARROW} alt="" />
              </button>
            </div>
            {touched && errors && errors.cardNumber ? (
              <div className="err-message text-end mt-2">
                {errors.cardNumber}
              </div>
            ) : !giftCardError && giftCardBalance.balance !== "" ? (
              <>
                <h5>${giftCardBalance.balance}</h5>
                <h5>{giftCardBalance.expiryDate}</h5>
              </>
            ) : giftCardError ? (
              <div className="error_message text-end mt-2">{giftCardError}</div>
            ) : null}
          </div>
        </div>
      </div>
      {/* Footer */}

      <div className="footer-top">
        <div className="footer-top-inner">
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => openModal("Gift Shop Terms")}
              >
                TERMS
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => navigate(`/${ROUTES.FAQ}`)}
              >
                FAQs
              </a>
            </li>
           { countryId !== COUNTRY.ANG?<><li>
              <a
                href="javascript:void(0)"
                onClick={() => openModal(ROUTES.CONTACT_US)}
              >
                CONTACT US
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                onClick={() =>
                  navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`)
                }
              >
                CINEMA HOMEPAGE
              </a>
            </li></>: null}
          </ul>
        </div>
      </div>

      { countryId !== COUNTRY.ANG? <div className="sm">
          <Link to={facebookUrl} target="_blank">
            <img src={URLS.FACEBOOK_ICON_WHITE} />
          </Link>
          <Link to={instagramUrl} target="_blank">
            <img src={URLS.INSTAGRAM_ICON_WHITE} />
          </Link>
        </div>:null}
    </>
  );
};

export default GiftShopFooter;
