import React,{ FC, useState } from "react";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import { useFormik } from "formik";
import TextInput from "../../../UI/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  memberUpdateApi,
  memberValidationApi,
  updateMemberAttributeApi,
} from "../../../../services/auth";
import { toast } from "react-toastify";
import {
  memberValidationSuccess,
  setUserDetails,
} from "../../../../store/auth/action";

interface INewLoyaltyProps {}

const NewLoyalty: FC<INewLoyaltyProps> = () => {
  const { countryId, userDetails, memberDetail } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
    })
  );

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: createValidationSchema("addLoyalty"),
      initialValues: {
        loyaltyCardNumber: "",
      },
      onSubmit: async (values) => {
        setErrorMessage("");
        const {
          response: {
            data: {
              data: { result: memberDetails, status, message },
            },
          },
        } = (await memberValidationApi({
          countryId: countryId,
          cardNumber: values.loyaltyCardNumber,
          userSessionId: values.loyaltyCardNumber,
          guestCardUpdate: true,
        })) as any;
        if (memberDetails && memberDetails["ErrorDescription"]) {
          setErrorMessage(memberDetails["ErrorDescription"]);
          return false;
        }else if(status === "false" && message){
          setErrorMessage(message);
          return false;
        } else {
          if (
            memberDetails["LoyaltyMember"]["UserName"] !==
            values.loyaltyCardNumber
          ) {
            setErrorMessage("Card number already assigned to another user");
            return false;
          } else {
            const memberUpdatePayload = {
              requestType: "memberupdate",
              UserSessionId: values.loyaltyCardNumber,
              countryId: countryId,
              firstName: userDetails?.firstName,
              lastName: userDetails?.lastName,
              email: userDetails?.email,
              MemberRefId: memberDetails["LoyaltyMember"]["MemberId"],
            };

            const {
              response: {
                data: {
                  data: { status: memberUpdateStatus },
                },
              },
            } = (await memberUpdateApi(memberUpdatePayload)) as any;
            if (memberUpdateStatus === true) {
              const memberAttributePayload = {
                countryId: countryId,
                firstName: userDetails?.firstName,
                lastName: userDetails?.lastName,
                email: userDetails?.email,
                cardNumber: memberDetails["LoyaltyMember"]["CardNumber"],
                MemberRefId: memberDetails["LoyaltyMember"]["MemberId"],
                loyaltyCardUpdate: true,
                id: userDetails?.id,
              };

              await updateMemberAttributeApi(memberAttributePayload);
              dispatch(
                setUserDetails({
                  ...userDetails,
                  cardNumber: memberDetails["LoyaltyMember"]["CardNumber"],
                  vista_memberid: memberDetails["LoyaltyMember"]["MemberId"],
                })
              );

              const {
                status: memberValidateStatus,
                response: memberValidateRes,
              } = (await memberValidationApi({
                countryId: countryId,
                MemberRefId: memberDetails["LoyaltyMember"]["MemberId"],
                cardNumber: memberDetails["LoyaltyMember"]["CardNumber"],
                UserSessionId: memberDetails["LoyaltyMember"]["CardNumber"],
              })) as any;
              if (
                memberValidateStatus &&
                memberValidateRes &&
                memberValidateRes.data?.data
              ) {
                dispatch(memberValidationSuccess(memberValidateRes.data.data));
              }
              toast.success("Card number updated successfuly!!!");
            } else {
              toast.error("Card Number Update Failed");
            }
          }
        }
      },
    });

  return (
    <div className="redeem-card section_divided">
      <div className="cardLabel">LOYALTY CARD NUMBER</div>
      <div className="cardtextbox">
        <TextInput
          type="loyaltyCardNumber"
          value={values.loyaltyCardNumber}
          field={"loyaltyCardNumber"}
          handleChange={handleChange}
          touched={touched.loyaltyCardNumber!}
          error={errors.loyaltyCardNumber}
        />
      </div>
      <div className="err-message m-t-5  mb-3">{errorMessage}</div>
      <div className="cardtextBox text-center m-t-0">
        <button
          type="button"
          className="btn black_btn"
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default NewLoyalty;
