import { FC, useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./rewardItems.scss"
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import { getMemberItemsApi, getPremiumMemberItemsApi, purchaseHistoryApi, redeemPointsApi } from "../../../../services/auth";
import { formatMembershipType, formatRewardPoints } from "../../../../utils/rewards";
import { MEMBERSHIP_TYPE } from "../../../../constants/app";

interface IRewardItemProps { }

const RewardItems: FC<IRewardItemProps> = () => {

  const { countryId, userDetails, memberDetail, isLoggedIn } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
      isLoggedIn: state.authReducer.isLoggedIn,
    })
  );

  const txnPoints = useMemo(() => {
    return formatRewardPoints(memberDetail!);
  }, [memberDetail]);

  const [itemsList, setItemsList] = useState<any>([]);
  const [itemsListPremium, setItemsListPremium] = useState<any>([]);

  const getMemberItems = async () => {
    const {
      data: {
        data: {
          data: { ConcessionList, TicketTypeList },
        },
      },
    } = await getMemberItemsApi({
      countryId,
      MemberRefId: userDetails?.vista_memberid!,
      cardNumber: userDetails?.cardNumber!,
      requestType: "rewardsItems",
    });
    let items = [];

    if (ConcessionList && ConcessionList.length > 0) {
      items.push(...ConcessionList);
    }
    if (TicketTypeList && TicketTypeList.length > 0) {
      items.push(...TicketTypeList);
    }
    items.sort((a, b) => a.PricingStructure.PointsCost - b.PricingStructure.PointsCost);
    setItemsList(items);
  };

  const getPremiumMemberItems = async () => {
    const {
      data: {
        data: {
          data: { ConcessionList, TicketTypeList },
        },
      },
    } = await getPremiumMemberItemsApi({
      countryId,
      MemberRefId: "",
      cardNumber: "",
      requestType: "rewardsItems",
    });
    let items = [];
    if (ConcessionList && ConcessionList.length > 0) {
      items.push(...ConcessionList);
    }
    if (TicketTypeList && TicketTypeList.length > 0) {
      items.push(...TicketTypeList);
    }
    items.sort((a, b) => a.PricingStructure.PointsCost - b.PricingStructure.PointsCost);
    setItemsListPremium(items);
  };


  const premiumList = useMemo(()=>{
    const list = itemsList.map((v:any)=>v.Name);
    return itemsListPremium.filter((v:any)=>!list.includes(v.Name));
  },[itemsList, itemsListPremium])

  useEffect(() => {
    if (countryId && isLoggedIn) {
      getMemberItems();
      getPremiumMemberItems();
    }
  }, [countryId, isLoggedIn && memberDetail]);

  const membershipType = useMemo(() => {
    return formatMembershipType(memberDetail!)
  }, [memberDetail])


  return (
    <div className="rewarditem_details">
      <div>
        <div className="point_section">
          <div className="desc">My current points</div>
          <div className="points">{txnPoints.toLocaleString()}</div>
        </div>
        {itemsList.length > 0 ? <div className="available_item">
          <div className="avail">Rewards available now</div>
          <div className={`avail-items brand_${countryId}`}>
            {
              itemsList.map((choice: any, j: any) => <div className={`item ${membershipType === MEMBERSHIP_TYPE.GOLD?'gold':'club'}`}>
                <div className="image_food">
                  <img
                    src={choice.image}
                    alt="image"
                  />
                </div>
                <div className="desc_food">
                  <div className="title">{choice.Name}</div>
                  {choice.PricingStructure?.PointsCost ? <div className="points">
                    {choice.PricingStructure?.PointsCost} points
                  </div> : null}
                </div>
              </div>
              )
            }
          </div>
        </div> : null}
      </div>
      {premiumList.length > 0 ? <div className="keep_earn">
        <div className="keep-desc">Keep earning</div>
        <div className="earn-items">
          {
            premiumList.map((choice: any, j: any) =>
                <div className="item">
                  <div className="image_food">
                  <img
                    src={choice.image}
                    alt="image"
                  />
                  </div>
                  <div className="desc_food">
                    <div className="title">{choice.Name}</div>
                    {choice.PricingStructure?.PointsCost ? <div className="points">
                    {choice.PricingStructure?.PointsCost} points
                  </div> : null}
                  </div>
                </div>
              )
          }
        </div>
      </div> : null}
    </div>
  );
};

export default RewardItems;
