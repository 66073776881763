import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "../../../components/UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setContentModal,
  toggleContentModal,
} from "../../../store/application/action";
import { RootState } from "../../../store";
import ContactUs from "./ContactUs";
import { URLS } from "../../../constants/url";
import PrivacyPolicy from "./PrivacyPolicy";
import RatingIndex from "./RatingIndex";
import { ROUTES } from "../../../constants/path";
import { useNavigate } from "react-router-dom";
import Employment from "./Employment";
import GroupBookings from "./GroupSale";
import CinemaHire from "./CinemaHire";
import VipExperienceForm from "./VipCinemas";
import ResetPassword from "./ResetPassword";
import CompetitionModal from "./Competition";
import { BRANDS, COUNTRY } from "../../../constants/app";
import CorporateCinemony from "./CorporateCinemony";
import EmailUpdate from "./EmailUpdate";
import GamingHiresForm from "./GamingHiresForm";
import CancelMembership from "./Membership/CancelMembership";
import EditRenewal from "./Membership/EditRenewal";
import ActivateAccountInfo from "./Membership/ActivateAccountInfo";
import AccountVerifyInfo from "./Membership/AccountVerifyInfo";

interface IContentModalProps { }

const ContentModal: FC<IContentModalProps> = () => {
  const { brandId, openContentModal, contentModal, currentCinema, countryId } =
    useSelector((state: RootState) => ({
      brandId: state.applicationReducer.brandId,
      openContentModal: state.applicationReducer.openContentModal,
      contentModal: state.applicationReducer.contentModal,
      currentCinema: state.applicationReducer.currentCinema,
      countryId: state.applicationReducer.countryId
    }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCloseModal = () => {
    dispatch(
      setContentModal({
        type: contentModal.prevModal ? contentModal.prevModal : "",
        values: contentModal.values ? contentModal.values : null,
      })
    );
    if (!contentModal.prevModal) {
      dispatch(toggleContentModal(false));
    }

    if (brandId === BRANDS.AU && contentModal.type === ROUTES.RESET_PASSWORD) {
      navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    }
  };

  const Content = useMemo(() => {
    switch (contentModal.type) {
      case ROUTES.CONTACT_US:
        return <ContactUs onCloseModal={onCloseModal} />;
      case ROUTES.PRIVACY_POLICY:
        return <PrivacyPolicy type={countryId === COUNTRY.ANG?"Privacy Policy - Angelika":"Privacy Policy"} />;
      case "Etix Terms":
        return <PrivacyPolicy type="Etix Terms" />;
      case "Rewards Etix Terms":
        return <PrivacyPolicy type="Rewards Terms & Condition - Angelika" />;
      case ROUTES.TERMS:
        return (
          <PrivacyPolicy
            type={`${brandId === BRANDS.US ? "Terms of Use" : "Terms"}`}
          />
        );
      case ROUTES.SITE_DISCLAIMER:
        return <PrivacyPolicy type={"Site Disclaimer - Angelika"} />;
      case ROUTES.REELCLUB_TERMS:
        return <PrivacyPolicy type={countryId === COUNTRY.ANG?"Reel Club Terms - Angelika":"Reel Club Terms"} />;
      case "Gift Shop Terms":
        return <PrivacyPolicy type="Gift Shop Terms" />;
      case "Group Booking Terms":
        return <PrivacyPolicy type="Group Booking Terms" />;
      case ROUTES.RATING:
        return <RatingIndex />;
      case ROUTES.CAREER:
        return <Employment onCloseModal={onCloseModal} />;
      case ROUTES.GROUP_SALE:
        return <GroupBookings onCloseModal={onCloseModal} />;
      case ROUTES.CINEMA_HIRE:
        return <CinemaHire onCloseModal={onCloseModal} />;
      case ROUTES.VIP_EXPERIENCE:
        return <VipExperienceForm onCloseModal={onCloseModal} />;
      case ROUTES.COMPETITIONS:
        return <CompetitionModal />;
      case ROUTES.CINEMONY:
        return <CorporateCinemony />;
      case ROUTES.MAILINGLIST:
        return <EmailUpdate onCloseModal={onCloseModal} />;
      case ROUTES.RESET_PASSWORD:
        return (
          <ResetPassword code={contentModal.code} onCloseModal={onCloseModal} />
        );
      case ROUTES.GAMING_HIRE:
        return <GamingHiresForm onCloseModal={onCloseModal} />;
      case ROUTES.CANCEL_MEMBERSHIP:
        return <CancelMembership onCloseModal={onCloseModal} />;
      case ROUTES.EDIT_RENEWAL:
        return <EditRenewal onCloseModal={onCloseModal} />;
      case ROUTES.MEMBERSHIP_ACTIVATE_INFO:
        return <ActivateAccountInfo onCloseModal={onCloseModal} />
      case ROUTES.MEMBERSHIP_ACCOUNT_VERIFY_INFO:
        return <AccountVerifyInfo onCloseModal={onCloseModal} />  
      default:
        return null;
    }
  }, [contentModal?.type, countryId]);


  return (
    <Modal
      className={`ticket-booking-modal content-modal`}
      visible={openContentModal}
      showCloseBtn={false}
    >
      <div className="modal fade show" style={{ display: "block" }}>
        <div
          className={`modal-dialog modal-dialog-centered ${contentModal?.type === ROUTES.RESET_PASSWORD || contentModal?.type === ROUTES.MAILINGLIST
            ? "modal-md"
            : "modal-lg"
            }`}
        >
          <div className={`modal-content ${contentModal?.type}`}>
            <div className="modal-header-image">
              <button
                type="button"
                className="close-modal"
                onClick={onCloseModal}
              >
                <img
                  className="close_icon"
                  src={URLS.CLOSE_ICON}
                  alt="close_icon"
                  title="close_icon"
                />
              </button>
            </div>
            {Content}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContentModal;
