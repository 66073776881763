import * as Yup from "yup";
import { COUNTRY } from "../constants/app";

export const phoneRegExp = new RegExp(
  /^\+?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
);

const cardRegExp = new RegExp(
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
);

const passwordRegex = new RegExp(
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
);

const passwordError = "Password must be at least eight characters long and includes uppercase letters, lowercase letters, numbers, and special characters (!@#$%^&*)."


const rules: any = {
  guest: {
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required email"),
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/[^0-9]/, "Number are not allowed in first name"),
    lastName: Yup.string().matches(
      /[^0-9]/,
      "Number are not allowed in last name"
    ),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid"),
    password: Yup.string().when("isChecked", (isChecked) => {
      if (isChecked.includes(true)) {
        return Yup.string()
          .required("Password is required")
          .matches(passwordRegex, passwordError);
      } else {
        return Yup.string();
      }
    }),
  },
  login: {
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  },
  forgotPassword: {
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Registered email is required"),
  },
  signup: {
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/[^0-9]/, "Number are not allowed in first name"),
    lastName: Yup.string().matches(
      /[^0-9]/,
      "Number are not allowed in last name"
    ),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .min(8, "Phone Number is not valid")
      .matches(phoneRegExp, "Phone Number is not valid"),
    gender: Yup.string().when("isNotRequired",(id)=>{
      if (id.includes(true)) {
        return Yup.string();
      } else {
        return Yup.string().required("Gender is required");
      }
     }),
    day: Yup.string().required("Day is required"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
    genres: Yup.array().when("isNotRequired",(id)=>{
      if (id.includes(true)) {
        return Yup.array();
      } else {
        return Yup.array().min(3, "Minimum 3 genre should be selected")
      }
     }),
    password: Yup.string()
      .required("Password is required")
      .matches(passwordRegex, passwordError),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Confirm Password is too short")
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
    // cardNumber: Yup.string().when("countryId", (id) => {
    //   if (id.includes(COUNTRY.STA) || id.includes(COUNTRY.ANG)) {
    //     return Yup.string().min(14, "Loyalty Card Number should be min 14 digits")
    //   } else {
    //     return Yup.string();
    //   }
    // }),
    cinemaId: Yup.string().required("Please Select cinema"),
    captcha: Yup.boolean().required("Please verify the captcha").oneOf([true], "Please verify the captcha"),
  },
  signupLoyalty: {
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/[^0-9]/, "Number are not allowed in first name"),
    lastName: Yup.string().required("Last Name is required").matches(
      /[^0-9]/,
      "Number are not allowed in last name"
    ),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .min(8, "Phone Number is not valid")
      .matches(phoneRegExp, "Phone Number is not valid"),
    gender: Yup.string().required("Gender is required"),
    day: Yup.string().required("Day is required"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
    // genres: Yup.array().min(3, "Minimum 3 genre should be selected"),
    password: Yup.string()
      .required("Password is required")
      .matches(passwordRegex, passwordError),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Confirm Password is too short")
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
    cardNumber: Yup.string().when("countryId", (id) => {
      if (id.includes(COUNTRY.STA) || id.includes(COUNTRY.ANG)) {
        return Yup.string().min(14, "Loyalty Card Number should be min 14 digits")
      } else {
        return Yup.string();
      }
    }),
    cinemaId: Yup.string().required("Please Select cinema"),
    captcha: Yup.boolean().required("Please verify the captcha").oneOf([true], "Please verify the captcha"),
    city: Yup.string().required("City is required"),
    zipCode: Yup.string().required("zipCode is required"),
  },
  signupMilitary: {
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/[^0-9]/, "Number are not allowed in first name"),
    lastName: Yup.string().required("Last Name is required").matches(
      /[^0-9]/,
      "Number are not allowed in last name"
    ),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .min(8, "Phone Number is not valid")
      .matches(phoneRegExp, "Phone Number is not valid"),
    gender: Yup.string().required("Gender is required"),
    day: Yup.string().required("Day is required"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
    // genres: Yup.array().min(3, "Minimum 3 genre should be selected"),
    password: Yup.string()
      .required("Password is required")
      .matches(passwordRegex, passwordError),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Confirm Password is too short")
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
    cardNumber: Yup.string().when("countryId", (id) => {
      if (id.includes(COUNTRY.STA) || id.includes(COUNTRY.ANG)) {
        return Yup.string().min(14, "Loyalty Card Number should be min 14 digits")
      } else {
        return Yup.string();
      }
    }),
    cinemaId: Yup.string().required("Please Select cinema"),
    captcha: Yup.boolean().required("Please verify the captcha").oneOf([true], "Please verify the captcha"),
    city: Yup.string().required("City is required"),
    zipCode: Yup.string().required("zipCode is required"),
    militaryTerms: Yup.boolean().required("Please accept the terms and condition").oneOf([true], "Please accept the terms and condition"),
    militaryMemberId: Yup.string().required("Military member ID is required"),
    relationship: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("Relationship is required")
      } else {
        return Yup.string();
      }
    }),
    relationshipFirstName: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("First Name is required")
      } else {
        return Yup.string();
      }
    }),
    relationshipEmail: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("Email is required")
      } else {
        return Yup.string();
      }
    }),
    relationshipContactNumber: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("Contact Number is required")
      } else {
        return Yup.string();
      }
    }),
    relationshipPreferredLocation: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("Preferred Location is required")
      } else {
        return Yup.string();
      }
    }),
    relationshipDay: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("Day is required")
      } else {
        return Yup.string();
      }
    }),
    relationshipMonth: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("Month is required")
      } else {
        return Yup.string();
      }
    }),
    relationshipYear: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("Year is required")
      } else {
        return Yup.string();
      }
    }),
    relationshipCity: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("City is required")
      } else {
        return Yup.string();
      }
    }),
    relationshipZipCode: Yup.string().when("hasAdditionalMember", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string().required("Zip code is required")
      } else {
        return Yup.string();
      }
    }),
  },
  addLoyalty: {
    loyaltyCardNumber: Yup.string()
      .required("Loyalty Card Number is required")
      .min(10, "Loyalty Card Number should be 10 digits"),
  },
  updateProfile: {
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/[^0-9]/, "Number are not allowed in first name"),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(/[^0-9]/, "Number are not allowed in first name"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Mobile number is not valid"),
    gender: Yup.string().when("isNotRequired",(id)=>{
      if (id.includes(true)) {
        return Yup.string();
      } else {
        return Yup.string().required("Gender is required");
       }
     }),
    genres: Yup.array().when("isNotRequired",(id)=>{
      if (id.includes(true)) {
        return Yup.array();
      } else {
        return Yup.array().min(3, "Minimum 3 genre should be selected");
       }
     }),
    currentPassword: Yup.string(),
    newPassword: Yup.string().when("currentPassword", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string()
          .required("New password is required ")
          .matches(passwordRegex, passwordError)
      } else {
        return Yup.string();
      }
    }),
    confirmPassword: Yup.string().when("currentPassword", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string()
          .min(8, "Password is too short")
          .oneOf([Yup.ref("newPassword"), ''], "Passwords must match")
          .required("Confirm password is required ")
      } else {
        return Yup.string();
      }
    }),
    day: Yup.string().required("Day is required"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
    postCode: Yup.string().when("isNotRequired",(id)=>{
      if (id.includes(true)) {
        return Yup.string();
      } else {
        return Yup.string()
        .required("Post code is required")
        .matches(/^[0-9]*$/, "Invalid Post Code")
        .min(4, "Post code Minimum 4 characters long")
       }
     }),
    suburb: Yup.string().when("isNotRequired",(id)=>{
      if (id.includes(true)) {
        return Yup.string();
      } else {
        return Yup.string()
        .required("Suburb is required")
        .min(4, "Suburb Minimum 4 characters long. ");
       }
     }),
  },
  updateProfileMembership: {
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/[^0-9]/, "Number are not allowed in first name"),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(/[^0-9]/, "Number are not allowed in first name"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Mobile number is not valid"),
    gender: Yup.string(),
    genres: Yup.array(),
    day: Yup.string().required("Day is required"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
    // postCode: Yup.string()
    //   .matches(/^[0-9]*$/, "Invalid Post Code")
    //   .min(4, "Post code Minimum 4 characters long"),
    // suburb: Yup.string()
    //   .min(4, "Suburb Minimum 4 characters long. "),
  },
  accountPasswordUpdate:{
    currentPassword: Yup.string().required(),
    newPassword: Yup.string().when("currentPassword", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string()
         
          .required("New password is required ")
          .matches(passwordRegex, passwordError)
      } else {
        return Yup.string();
      }
    }),
    confirmPassword: Yup.string().when("currentPassword", (isChecked) => {
      if (isChecked.length > 0 && isChecked[0]) {
        return Yup.string()
        .min(8, "Password is too short")
          .oneOf([Yup.ref("newPassword"), ''], "Passwords must match")
          .required("Confirm password is required ")
      } else {
        return Yup.string();
      }
    }),
  },
  lostCard: {
    lostCardNumber: Yup.string()
      .min(10, "Card number length should be 10")
      .required("New card number is required")
      .matches(/^[0-9]*$/, "Invalid card number"),
  },
  editPaymentMethod: {
    cardHolderName: Yup.string().trim()
      .required("Card Holder Name is required")
      .matches(/[^0-9]/, "Number are not allowed in card holder name"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
    cvc: Yup.string()
      .required("CVC is required")
      .matches(/[0-9]/, "Invalid CVC")
      .min(3, "Invalid CVC")
      .max(4)
      .matches(/^(\w+\s)*\w+$/, "Invalid CVC"),
  },
  paymentMethod: {
    cardHolderName: Yup.string().trim()
      .required("Card Holder Name is required")
      .matches(/[^0-9]/, "Number are not allowed in card holder name"),
    cardNumber: Yup.string()
      .required("Card Number is required")
      .matches(cardRegExp, "Invalid card number")
      .min(15, "Invalid card number"),
    cvc: Yup.string()
      .required("CVC is required")
      .matches(/[0-9]/, "Invalid CVC")
      .min(3, "Invalid CVC")
      .max(4)
      .matches(/^(\w+\s)*\w+$/, "Invalid CVC"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
  },
  saveGiftCardMethod: {
    cardNumber: Yup.string()
      .required("Gift Card Number is required")
      .min(10, "Invalid gift card number")
  },
  editSaveGiftCardMethod: {
    cardNumber: Yup.string()
      .required("Gift Card Number is required")
      .min(10, "Invalid gift card number")
  },
  card: {
    cardHolderName: Yup.string().trim()
      .required("Card Holder Name is required")
      .matches(/[^0-9]/, "Number are not allowed in card holder name"),
    cardNumber: Yup.string()
      .required("Card Number is required")
      .matches(cardRegExp, "Invalid card number")
      .min(15, "Invalid card number"),
    cvc: Yup.string()
      .required("CVC is required")
      .matches(/[0-9]/, "Invalid CVC")
      .min(3, "Invalid CVC")
      .max(4)
      .matches(/^(\w+\s)*\w+$/, "Invalid CVC"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
  },
  giftshopCard: {
    cardHolderName: Yup.string().trim()
      .required("Card Holder Name is required")
      .matches(/[^0-9]/, "Number are not allowed in card holder name"),
    cardNumber: Yup.string()
      .required("Card Number is required")
      .matches(cardRegExp, "Invalid card number")
      .min(15, "Invalid card number"),
    cvc: Yup.string()
      .required("CVC is required")
      .matches(/[0-9]/, "Invalid CVC")
      .min(3, "Invalid CVC")
      .max(4)
      .matches(/^(\w+\s)*\w+$/, "Invalid CVC"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
  },
  employment: {
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Your Name is invalid"),
    email: Yup.string()
      .required(" Email is required ")
      .email("Email must be a valid email address"),
    contactNumber: Yup.string()
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid"),
    location: Yup.string().required("Please Select Location"),
    cv: Yup.string().required("Please upload a file"),
    comments: Yup.string(),
    captcha: Yup.boolean().required("Verification Code is required").oneOf([true], "Verification Code is required")
  },
  contactUs: {
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Your Name is invalid"),
    email: Yup.string()
      .required(" Email is required ")
      .email("Email must be a valid email address"),
    contactNumber: Yup.string()
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid"),
    sendemailto: Yup.string().required("Please Select Send Email To"),
    etixerror: Yup.string().when("sendemailto", (isChecked) => {
      if (isChecked.includes('eTixSupport')) {
        return Yup.string()
          .required("Please select E-Tix Step Error")
      } else {
        return Yup.string();
      }
    }),
    etixreelclub: Yup.string().when("sendemailto", (isChecked) => {
      if (isChecked.includes('eTixSupport')) {
        return Yup.string()
          .required("Please select Reel Club")
      } else {
        return Yup.string();
      }
    }),
    reelclubcard: Yup.string().when("sendemailto", (isChecked) => {
      if (isChecked.includes('readingReelClub')) {
        return Yup.string()
          .required("Reel Club Card No is required")
      } else {
        return Yup.string();
      }
    }),
    device: Yup.string().when("sendemailto", (isChecked) => {
      if (isChecked.includes('eTixSupport')) {
        return Yup.string()
          .required("Please select Device & Browser")
      } else {
        return Yup.string();
      }
    }),

    cinema: Yup.string().required("Please select cinema"),
    comments: Yup.string(),
    captcha: Yup.boolean().required("Verification Code is required").oneOf([true], "Verification Code is required"),
  },
  groupBooking: {
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Enter valid name"),
    email: Yup.string()
      .required("Email is required ")
      .email("Email must be a valid email address"),
    cinema: Yup.string().required("Please select Cinema"),
    movie: Yup.string().required("Please Select Movie"),
    otherMovie: Yup.string().when("movie", (value) => {
      if (value.includes('Other Movie')) {
        return Yup.string().required("Please enter the Movie Title")
      } else {
        return Yup.string();
      }
    }),

    date: Yup.string().required("Date is required"),
    companyName: Yup.string().matches(
      /^[a-zA-Z][a-zA-Z\\s ]+$/,
      "Enter valid company name"
    ),
    contactNumber: Yup.string()
      .min(8, "Contact number is not valid")
      .matches(phoneRegExp, "Contact number is not valid"),
    contactAltNumber: Yup.string()
      .min(8, "Contact number is not valid")
      .matches(phoneRegExp, "Contact number is not valid"),
    isTermsChecked: Yup.bool().oneOf([true], 'Please agree Terms and conditions'),
    captcha: Yup.boolean().required("Verification Code is required").oneOf([true], "Verification Code is required"),

  },
  cinemaHire: {
    cinema: Yup.string().required("Please Select cinema"),
    comments: Yup.string(),
    companyName: Yup.string().matches(
      /^[a-zA-Z][a-zA-Z\\s ]+$/,
      "Company Name allow only character"
    ),
    contactNumber: Yup.string()
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string()
      .required("Email is required ")
      .email("Email must be a valid email address"),
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Your Name is invalid"),
    date: Yup.string().required("Date is required"),
    timeOfDay: Yup.string().required("Please select time of day"),
    captcha: Yup.boolean().required("Verification Code is required").oneOf([true], "Verification Code is required"),
  },
  giftCardShop: {
    cardNumber: Yup.string().required("Gift Card Number is required"),
  },
  membershipCard: {
    cardNumber: Yup.string().required("Card Number is required"),
  },
  eGiftCard: {
    deliveryDate: Yup.string().required("Delivery Date is required"),
    recipientName: Yup.string()
      .required(" Recipient Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Enter a valid name"),
    recipientemail: Yup.string()
      .required("Email is required ")
      .email("Email must be a valid email address"),
    confirmRecipientEmail: Yup.string()
      .required("Confirmation Mail is required")
      .email("Email must be a valid email address")
      .oneOf([Yup.ref("recipientemail"), ""], "Email must match"),
    senderName: Yup.string()
      .required("Your Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Enter a valid name"),
    phone: Yup.string()
      .required("Phone number is required")
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid"),
  },
  physicalGiftCard: {
    recipientName: Yup.string()
      .required("Recipient Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Enter a valid name"),
    senderName: Yup.string()
      .required("Your Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Enter a valid name"),
    address1: Yup.string().required("Delivery Address is required"),
    address2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postCode: Yup.string()
      .required("Postcode is required")
      .min(3, "Postscode is not valid")
      .matches(/^[0-9]+$/, "Postcode is not valid"),
    giftMessage: Yup.string(),
    phone: Yup.string()
      .required("Phone number is required")
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid"),
  },
  experiences: {
    recipientName: Yup.string()
      .required(" Recipient Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Enter a valid name"),

    senderName: Yup.string()
      .required(" Your Name is required ")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Enter a valid name"),
    address1: Yup.string().required("Delivery Address is required"),
    address2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required "),
    postCode: Yup.string().required("Postcode is required"),
    giftMessage: Yup.string(),
  },
  sendReceipt: {
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
  },
  forgot: {
    password: Yup.string()
      .required("Password is required")
      .matches(passwordRegex, passwordError),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Confirm Password is too short")
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
  },
  vipBooking: {
    cinema: Yup.string().required("Please Select cinema"),
    movie: Yup.string().required("Please Select Movie"),
    comments: Yup.string(),
    companyName: Yup.string().matches(
      /^[a-zA-Z][a-zA-Z\\s ]+$/,
      "Company Name allow only character"
    ),
    contactNumber: Yup.string()
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string()
      .required("Email is required ")
      .email("Email must be a valid email address"),
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Your Name is invalid"),
    timeOfDay: Yup.string(),
    date: Yup.string()
    .required("Date is required"),
    movieTitle: Yup.string().when("movie", (isChecked) => {
      if (isChecked.includes("Request a movie")) {
        return Yup.string().required("Please enter the Movie Title");
      } else {
        return Yup.string();
      }
    }),
    captcha: Yup.boolean().required("Please verify the captcha").oneOf([true], "Please verify the captcha"),
  },
  bigScreeenGaming: {
    cinema: Yup.string().required("Please Select cinema"),
    comments: Yup.string(),
    companyName: Yup.string().matches(
      /^[a-zA-Z][a-zA-Z\\s ]+$/,
      "Company Name allow only character"
    ),
    contactNumber: Yup.string()
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string()
      .required("Email is required ")
      .email("Email must be a valid email address"),
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z][a-zA-Z\\s ]+$/, "Your Name is invalid"),
    timeOfDay: Yup.string(),
    date:  Yup.string().required("Date is required"),
    bookingLength: Yup.string().required("Please Select Booking Length"),
    captcha: Yup.boolean().required("Please verify the captcha").oneOf([true], "Please verify the captcha"),
  },
  competitionModal: {
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required ")
      .email("Email must be a valid email address"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .min(8, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid"),
    receiptNumber: Yup.string().when("hasReceiptNumber", (hasReceiptNumber) => {
      if (hasReceiptNumber.includes(true)) {
        return Yup.string()
          .required("Reciept number is required")
          .matches(/^[0-9]+$/, "Reciept number is not valid");
      } else {
        return Yup.string();
      }
    }),
    cinemaLocation: Yup.string().when("hasCinemaLocation", (hasCinemaLocation) => {
      if (!hasCinemaLocation.includes(0)) {
        return Yup.string()
          .required("Please Select Cinema location");
      } else {
        return Yup.string();
      }
    }),
    weeklyCodeWord: Yup.string().when("hasWeeklyCode", (hasWeeklyCode) => {
      if (hasWeeklyCode.includes(true)) {
        return Yup.string()
          .required("Weekly Code Word is required");
      } else {
        return Yup.string();
      }
    }),
    ticketNumber: Yup.string().when("hasTicketNumber", (hasTicketNumber) => {
      if (hasTicketNumber.length > 0 &&  hasTicketNumber[0] > 0) {
        return Yup.array().of(
          Yup.string().required("Ticket number is required")
        );
      } else {
        return Yup.array()
      }
    }),
    giftCardNumber: Yup.string().when("hasGiftCard", (hasGiftCard) => {
      if (hasGiftCard.length > 0 && hasGiftCard[0] > 0) {
        return Yup.array().of(
          Yup.string().required("Gift card number is required")
        );
      } else {
        return Yup.array()
      }
    }),
    answer: Yup.string().required("Answer is required"),
    captcha: Yup.boolean().required("Please verify the captcha").oneOf([true], "Please verify the captcha"),
  },
  editRenewal:{
    renewal: Yup.string().required("Please select the renewal process"),
  },
  editRenewalWithCard:{
    renewal: Yup.string().required("Please select the renewal process"),
    cardHolderName: Yup.string().trim()
      .required("Card Holder Name is required")
      .matches(/[^0-9]/, "Number are not allowed in card holder name"),
    cardNumber: Yup.string()
      .required("Card Number is required")
      .matches(cardRegExp, "Invalid card number")
      .min(15, "Invalid card number"),
    cvc: Yup.string()
      .required("CVC is required")
      .matches(/[0-9]/, "Invalid CVC")
      .min(3, "Invalid CVC")
      .max(4)
      .matches(/^(\w+\s)*\w+$/, "Invalid CVC"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
  },
  emailUpdate: {
      givenName: Yup.string()
        .required("Name is required"),
      email: Yup.string()
        .email("Email must be a valid email address")
      .required("Email is required email"),
      captcha: Yup.boolean().required("Verification Code is required").oneOf([true], "Verification Code is required")
  }
};

export const createValidationSchema = (entity: any) => {
  const fields: any = rules[entity];
  return Yup.object().shape(fields);
};
