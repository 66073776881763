import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../../../../components/UI/Slider";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import { getHistroyApi, pageContentApi } from "../../../../services/sideMenus";
import { RootState } from "../../../../store";
import { getSlidesWatcher, setFooterType } from "../../../../store/application/action";
import "./about.scss";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { downloadFile } from "../../../../utils/helper";
import { useNavigate } from "react-router";
import { ICustomPage } from "../../../../models/sidemenu";
import { ROUTES } from "../../../../constants/path";
import HelmetTitle from "../../../../components/Helmet";

interface IAboutProps {}

const About: FC<IAboutProps> = () => {
  const { countryId, slides, currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      slides: state.applicationReducer.slides,
      currentCinema: state.applicationReducer.currentCinema,
    })
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [content, setContent] = useState<ICustomPage | null>(null);

  useEffect(() => {
    if (countryId && currentCinema) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.ABOUT,
        })
      );
    }
  }, [countryId, currentCinema]);

  const getPageContent = async () => {
    const {
      data: { data, status },
    } = await pageContentApi({
      countryId,
      cinemaId: currentCinema?.slug,
      pageName: `about`,
      flag: "customPage",
    });
    if (status && data && data.length > 0) {
      setContent(data[0]);
      dispatch(setFooterType(5))
    }else{
      dispatch(setFooterType(0))
    }
  };

  useEffect(() => {
    if (countryId && currentCinema) {
      getPageContent();
    }
  }, [countryId, currentCinema]);

  const openLink = (link: string) => {
    if (link.includes("https://")) {
      window.open(link, "_blank");
    } else {
      navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    }
  };

  const download = (data: any) => {
    if(data.File_Id){
      downloadFile(`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.FILE_PATH}/${data.File_Id}.${data.extension}`);
    }
  };

  const RegularContainer: FC<any> = ({ data, index }) => {
    return (
      <div
        className={`py-3`}
        style={{
          backgroundImage: `url(${
            URLS.CLOUDFRONT_URL +
            "/" +
            IMAGE_URL_CONFIG.IMAGE_PATH +
            "/" +
            data.Tile_Image +
            IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT
          })`,
        }}
      >
        <HtmlRender content={data.Tile_Title} {...{ className: "heading" }} />
        <HtmlRender
          content={data.Tile_Description}
          {...{ className: "mt-1 mb-0 px-2" }}
        />
        {data.Show_Buttons === 1 ? (
          <div className="d-flex justify-content-center flex-wrap">
            {data.buttons.map((v: any) => {
              return v.Button_Type === "file" && v.File_Id ? (
                <a
                  href="javascript:void(0)"
                  className="btn white-btn my-3 mx-3"
                  onClick={() =>download(v)}
                >
                  {v.Button_Text}
                </a>
              ) : v.Button_Type === "link" && v.Button_Link_URL?(
                <button
                  className="btn white-btn my-3 mx-3"
                  onClick={() =>  openLink(v.Button_Link_URL)}
                >
                  {v.Button_Text}
                </button>
              ):null;
            })}
          </div>
        ) : null}
      </div>
    );
  };


  return (
    <>
      <section>
      <HelmetTitle title="About" description="About"/>
        <Slider data={slides} />
        {/* <div className="split_section_header">
          <div className="label">{content?.headerTitle}</div>
        </div> */}
      </section>
      <section className="about_content_wrap min_screen_height">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <HtmlRender
                {...{ className: "description" }}
                content={content?.description}
              />
              <hr className="divider"/>
              {content?.content && content.content.length > 0 ? (
                <div className="custom-page-section">
                  {content.content.map((v: any, i: number) => (
                    <RegularContainer data={v} index={i} key={i} />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
