import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import "./header.scss";
import { URLS } from "../../../../constants/url";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  HEADER_TYPE,
  MODAL_TYPE,
  TICKET_FLOW_TYPES,
} from "../../../../models/tickets";
import TicketBookingHeader from "../../../../components/TicketBooking/Headers/TicketBookingHeader";
import FoodAndBevarageHeader from "../../../../components/TicketBooking/Headers/FoodAndBevarageHeader";
import OrderSummaryHeader from "../../../../components/TicketBooking/Headers/OrderSummaryHeader";
import AuthHeaders from "../../../../components/TicketBooking/Headers/AuthHeader";
import PaymentHeader from "../../../../components/TicketBooking/Headers/PaymentHeader";
import TicketConfirmationHeader from "../../../../components/TicketBooking/Headers/TicketConfirmationHeader";
import TicketErrorHeader from "../../../../components/TicketBooking/Headers/TicketErrorHeader";
import SessionTimer, {
  ISessionRef,
} from "../../../../components/UI/SessionTimer";
import { TIMER_DURATION, TIMER_DURATION_FB } from "../../../../constants/app";
import RefundHeader from "../../../../components/TicketBooking/Headers/RefundHeader";
import RefundConfirmationHeader from "../../../../components/TicketBooking/Headers/RefundConfirmationHeader";
import GiftshopConfirmationHeader from "../../../../components/TicketBooking/Headers/GiftShop";
import MilitartConfirmationHeader from "../../../../components/TicketBooking/Headers/MilitaryConfirmationHeader";
import RewardsHeader from "../../../../components/TicketBooking/Headers/RewardsHeader";
import MembershipHeader from "../../../../components/TicketBooking/Headers/MembershipHeader";

interface IHeaderProps {
  onCloseModal: () => void;
  children?: React.ReactNode;
  startTimer?: boolean;
  stopTimer?: boolean;
}

const Header: FC<IHeaderProps> = ({
  onCloseModal,
  children,
  startTimer,
  stopTimer,
}) => {
  const { openModal, modal, ticketDetails, type, orderCartData, ticketLessFb } =
    useSelector((state: RootState) => ({
      openModal: state.ticketBookingReducer.openModal,
      modal: state.ticketBookingReducer.modal,
      ticketDetails: state.ticketBookingReducer.ticketDetails,
      type: state.ticketBookingReducer.modal?.type,
      orderCartData: state.foodBeverageReducer.orderCartData,
      ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    }));

  const timerRef = useRef<ISessionRef>(null);
  const [showClosebtn, setShowClosebtn] = useState<boolean>(true);
  const [className, setClassName] = useState("");

  useEffect(() => {
    if (openModal) {
      timerRef.current?.reset();
    } else {
      timerRef.current?.stop();
    }
  }, [openModal]);

  useEffect(() => {
    if (startTimer) {
      timerRef.current?.start();
    }
  }, [startTimer]);

  useEffect(() => {
    if (stopTimer) {
      timerRef.current?.stop();
    }
  }, [stopTimer]);

  const MainHeader = useMemo(() => {
    switch (modal.header) {
      case HEADER_TYPE.TICKET_BOOKING_HEADER:
        return <TicketBookingHeader />;
      case HEADER_TYPE.FOOD_AND_BEVERAGE_HEADER:
        setClassName("offset");
        return <FoodAndBevarageHeader />;
      case HEADER_TYPE.ORDER_SUMMARY_HEADER:
        setClassName(orderCartData.length > 0 ? "offset" : "");
        return <OrderSummaryHeader />;
      case HEADER_TYPE.AUTH_HEADER:
        return <AuthHeaders />;
      case HEADER_TYPE.PAYMENT_HEADER:
        return <PaymentHeader />;
      case HEADER_TYPE.TICKET_CONFIRMATION_HEADER:
        return <TicketConfirmationHeader />;
      case HEADER_TYPE.TICKET_ERROR_HEADER:
        return <TicketErrorHeader />;
      case HEADER_TYPE.SOCIAL_SHARE:
        return null;
      case HEADER_TYPE.REFUND:
        return <RefundHeader />;
      case HEADER_TYPE.REFUND_CONFIRMATION:
        return <RefundConfirmationHeader />;
      case HEADER_TYPE.GIFTSHOP_CONFIRMATION_HEADER:
        return <GiftshopConfirmationHeader />;
      case HEADER_TYPE.MILITARY_MEMBER_HEADER:
        return <MilitartConfirmationHeader />;
      case HEADER_TYPE.MEMBERSHIP_AUTH_HEADER:
        return <MembershipHeader/>;
      case HEADER_TYPE.MEMBERSHIP_PAYMENT_HEADER:
        return <RewardsHeader/>;
      default:
        return null;
    }
  }, [modal.header]);

  const showTimer = useMemo(() => {
    return modal.type !== TICKET_FLOW_TYPES.SOCIAL_SHARE &&
      modal.type !== TICKET_FLOW_TYPES.REFUND &&
      modal.type !== TICKET_FLOW_TYPES.GIFTSHOP &&
      modal.type !== TICKET_FLOW_TYPES.MILITARY_MEMBER &&
      modal.type !== TICKET_FLOW_TYPES.KITCHEN_CLOSED &&
      modal.type !== TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE
      ? true
      : false;
  }, [modal.header]);

  return (
    <>
      {showClosebtn ? (
        <button type="button" className="close-modal" onClick={onCloseModal}>
          <img
            className="close_icon"
            src={URLS.CLOSE_ICON}
            alt="close_icon"
            title="close_icon"
          />
        </button>
      ) : null}
      <div className="modal-header">
        <div className="modal-title">
          {MainHeader}
          {children ? children : null}
          {showTimer ? (
            <SessionTimer
              class={className}
              startTime={ticketLessFb ? TIMER_DURATION_FB : TIMER_DURATION}
              paused={true}
              ref={timerRef}
              onEnd={() => onCloseModal()}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Header;
